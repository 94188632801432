import {Component, OnInit, ViewChild} from '@angular/core';
import {Magazine} from '../magazine-list/magazine-list.component';
import {EditorjsComponent} from '../../base/editorjs/editorjs.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {UploadService} from '../../upload.service';
import {MagazineService} from '../../magazine.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ArticleFormData, MagazineFormData} from '../../base/interfaces';

@Component({
  selector: 'app-magazine-edit',
  templateUrl: './magazine-edit.component.html',
  styleUrls: ['./magazine-edit.component.scss']
})
export class MagazineEditComponent implements OnInit {
  magazine: Magazine;

  @ViewChild(EditorjsComponent) editor: EditorjsComponent;

  magazineForm = new FormGroup({
    title: new FormControl('', [
      Validators.required
    ]),
    slug: new FormControl('', [
      Validators.required
    ]),
  });

  private sub: any;

  constructor(
    private notifier: NotifierService,
    private uploadService: UploadService,
    private magazineService: MagazineService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      if (params.id === undefined) {
        this.notifier.notify('error', 'Revista não encontrado');

        this.router.navigate(['/magazines']);
      }

      const id = params.id;

      this.magazineService.getMagazine(id).subscribe(
        response => {
          this.magazine = response['data'];
          this.magazineForm.patchValue({
            title: this.magazine.title,
            slug: this.magazine.slug
          });

        },
        error => {
          this.notifier.notify('error', error.message);
        });

    });
  }

  public uploadImage(file: File): void {
    this.uploadService.uploadImage(file).subscribe(
      response => {
        const fileUrl = response['file'].url;
        const splitFileUrl = fileUrl.split('/');
        const fileName = splitFileUrl[splitFileUrl.length - 1];

        this.magazine.image = fileName;

        this.notifier.notify('success', 'Imagem enviada com sucesso');
        // TODO implement upload to firestore
      },
      error => {
        this.notifier.notify('error', 'Erro no upload da imagem');
      }
    );
  }

  public deleteImage(): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    this.magazine.image = '';
  }

  public submit() {
    this.editor.save().then(data => {
      let magazineData: MagazineFormData = {
        ...this.magazineForm.value,
        image: this.magazine.image,
        description: JSON.stringify(data)
      };


      this.magazineService.update(this.magazine.id, magazineData).subscribe(
        response => {
          this.notifier.notify('success', 'Revista atualizada com sucesso');

          this.router.navigate(['/magazines']);
        },
        error => {
          this.notifier.notify('error', 'Erro na hora de criar os dados');
        }
      );
    });
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarEvent } from '../calendar-list/calendar-list.component';
import { EditorjsComponent } from '../../base/editorjs/editorjs.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { CalendarService } from '../../calendar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarEventFormData } from '../../base/interfaces';
import { DatePipe } from '@angular/common';
import { take } from 'rxjs/operators';
import { EventCalendar } from '../../shared/intefaces/event';
import * as moment from 'moment';
import slugify from 'slugify';

@Component({
  selector: 'app-calendar-edit',
  templateUrl: './calendar-edit.component.html',
  styleUrls: ['./calendar-edit.component.scss'],
})
export class CalendarEditComponent implements OnInit {
  calendarEvent: EventCalendar;
  @ViewChild(EditorjsComponent) editor: EditorjsComponent;
  calendarForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    slug: new FormControl('', [Validators.required]),
    location: new FormControl(''),
    link: new FormControl(''),
    start_date: new FormControl('', [Validators.required]),
    end_date: new FormControl('', [Validators.required]),
    start_time: new FormControl(''),
    end_time: new FormControl(''),
  });
  private sub: any;

  constructor(
    private notifier: NotifierService,
    private calendarService: CalendarService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.calendarForm.get('title').valueChanges.subscribe((value) => {
      this.calendarForm.get('slug').setValue(
        slugify(value, {
          lower: true,
          strict: true,
        })
      );
    });

    this.sub = this.route.params.subscribe((params) => {
      if (params.id === undefined) {
        this.notifier.notify('error', 'Evento não encontrado');

        this.router.navigate(['/calendar']);
      }

      const id = params.id;

      this.calendarService
        .getEvent(id)
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.calendarEvent = response.data();
            const datepipe: DatePipe = new DatePipe('en-US');

            this.calendarForm.patchValue({
              title: this.calendarEvent.title,
              slug: this.calendarEvent.slug,
              link: this.calendarEvent.link,
              location: this.calendarEvent.location,
              start_date: datepipe.transform(
                this.calendarEvent.start_date.toDate(),
                'yyyy-MM-dd'
              ),
              end_date: datepipe.transform(
                this.calendarEvent.end_date.toDate(),
                'yyyy-MM-dd'
              ),
              start_time: this.calendarEvent.start_time,
              end_time: this.calendarEvent.end_time,
            });
          },
          (error) => {
            this.notifier.notify('error', error.message);
          }
        );
    });
  }

  public submit() {
    this.editor.save().then((data) => {
      let calendarData: any = {
        ...this.calendarForm.value,
        start_date: moment(this.calendarForm.value.start_date).toDate(),
        end_date: moment(this.calendarForm.value.end_date).toDate(),
        description: JSON.stringify(data),
      };

      this.calendarService
        .update(this.calendarEvent.id, calendarData)
        .then(() => {
          this.notifier.notify('success', 'Evento atualizado com sucesso.');

          this.router.navigate(['/calendar']);
        })
        .catch(() => {
          this.notifier.notify('error', 'Erro ao atualizar o evento.');
        });
    });
  }
}

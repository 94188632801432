import { Component, OnInit } from '@angular/core';
import {Ebook} from '../ebook-list/ebook-list.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {EbookService} from '../../ebook.service';
import {UploadService} from '../../upload.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EbookFormData} from '../../base/interfaces';

@Component({
  selector: 'app-ebook-edit',
  templateUrl: './ebook-edit.component.html',
  styleUrls: ['./ebook-edit.component.scss']
})
export class EbookEditComponent implements OnInit {
  ebook: Ebook;
  image = '';
  file = '';

  ebookForm = new FormGroup({
    title: new FormControl('', [
      Validators.required
    ]),
    call: new FormControl(''),
  });

  private sub: any;

  constructor(
    private notifier: NotifierService,
    private ebookService: EbookService,
    private uploadService: UploadService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      if (params.id === undefined) {
        this.notifier.notify('error', 'Ebook não encontrado');

        this.router.navigate(['/ebooks']);
      }

      const id = params.id;

      this.ebookService.getEbook(id).subscribe(
        response => {
          this.ebook = response['data'];
          this.ebookForm.patchValue({
            title: this.ebook.title,
            call: this.ebook.call,
          });

          this.image = this.ebook.image;
          this.file = this.ebook.file;

        },
        error => {
          this.notifier.notify('error', error.message);
        });

    });
  }

  public deleteImage(): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    this.image = '';
  }

  public deleteFile(): void {
    this.notifier.notify('success', 'Arquivo removido com sucesso.');

    this.file = '';
  }

  public uploadImage(file: File): void {
    this.uploadService.uploadImage(file).subscribe(
      response => {
        const fileUrl = response['file'].url;
        const splitFileUrl = fileUrl.split('/');
        const fileName = splitFileUrl[splitFileUrl.length - 1];

        this.image = fileName;

        this.notifier.notify('success', 'Imagem enviada com sucesso');
        // TODO implement upload to firestore
      },
      error => {
        this.notifier.notify('error', 'Erro no upload da imagem');
      }
    );
  }

  public uploadFile(file: File): void {
    this.uploadService.uploadFile(file).subscribe(
      response => {
        const fileUrl = response['file'];
        const splitFileUrl = fileUrl.split('/');
        const fileName = splitFileUrl[splitFileUrl.length - 1];

        this.file = fileName;

        this.notifier.notify('success', 'Arquivo enviado com sucesso');

        // TODO implement upload to firestore
      },
      error => {
        this.notifier.notify('error', 'Erro no upload do arquivo');
      }
    );
  }

  public submit() {
    let ebookData: EbookFormData = {
      ...this.ebookForm.value,
      image: this.image,
      file: this.file,
    };


    this.ebookService.update(this.ebook.id, ebookData).subscribe(
      response => {
        this.notifier.notify('success', 'Ebook atualizado com sucesso');

        this.router.navigate(['/ebooks']);
      },
      error => {
        this.notifier.notify('error', 'Erro na hora de atualizar os dados');
      }
    );
  }

}

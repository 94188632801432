import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MagazineRoutingModule } from './magazine-routing.module';
import { MagazineListComponent } from './magazine-list/magazine-list.component';
import {BaseModule} from '../base/base.module';
import { MagazineCreateComponent } from './magazine-create/magazine-create.component';
import {ReactiveFormsModule} from '@angular/forms';
import { MagazineEditComponent } from './magazine-edit/magazine-edit.component';


@NgModule({
  declarations: [MagazineListComponent, MagazineCreateComponent, MagazineEditComponent],
    imports: [
        CommonModule,
        MagazineRoutingModule,
        BaseModule,
        ReactiveFormsModule
    ]
})
export class MagazineModule { }

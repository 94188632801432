import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/firestore';
import { Group } from '../shared/intefaces/group';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private firestore: AngularFirestore) { }

  getGroups(entry: any, status: boolean, direction: string) {
    return this.firestore.collection<Group>('groups', ref => {
      let query: CollectionReference | Query = ref;

      query = query.where('published', '==', status)
        .orderBy('name', 'asc');

      if (entry) {
        if (direction === 'after') {
          query = query.startAfter(entry).limit(10);
        } else {
          query = query.endBefore(entry).limitToLast(10).limit(10);
        }
      } else {
        query = query.limit(10);
      }

      return query;
    }).get();
  }

  getActiveGroups() {
    return this.firestore.collection<Group>('groups', ref => {
      let query: CollectionReference | Query = ref;

      query = query.where('published', '==', true)
      .orderBy('name', 'asc');

      return query;
    }).get();
  }

  getGroup(id: string) {
    return this.firestore.collection<Group>('groups').doc(id).get();
  }

  togglePublish(id: string, status: boolean) {
    return this.firestore.collection('groups').doc(id).update({
      published: !status
    });
  }

  saveGroup(groupData: any) {
    return this.firestore.collection('groups').doc(groupData.id).set(groupData);
  }

  update(groupId: string, groupData: any) {
    return this.firestore.collection('groups').doc(groupId).update(groupData);
  }

  delete(groupId: string) {
    return this.firestore.collection('groups').doc(groupId).delete();
  }


}

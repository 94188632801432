import { Component, OnInit } from '@angular/core';
import { User } from '../user-list/user-list.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PackageFormData, UserFormData } from '../../base/interfaces';
import { NotifierService } from 'angular-notifier';
import { UserService } from '../../user.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
  user: User;
  permissions = [
    'Notícias', 'Agenda', 'Artigos', 'Banners', 'Pedidos', 'Pacotes', 'Períodos', 'Revistas',
    'Ebooks', 'Wallpapers'
  ];

  userForm = new FormGroup({
    name: new FormControl('', [
      Validators.required
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
  });

  constructor(
    private notifier: NotifierService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public submit() {
    let userData: any = {
      ...this.userForm.value,
      id: this.userForm.value.email,
      manual: true,
      admin: false
    };

    this.userService.getByEmail(userData.email).pipe(take(1)).subscribe(user => {
      if (user.docs.length > 0) {
        this.notifier.notify('error', 'Um usuário com esse email já existe');
        return
      }

      this.userService.create(userData).then(() => {
        this.notifier.notify('success', 'Usuário criado com sucesso');

        this.router.navigate(['/users']);
      }).catch(error => {
        this.notifier.notify('error', 'Erro na hora de criar o usuário');
      })
    });

  }

}

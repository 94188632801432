import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EditorjsComponent } from '../../base/editorjs/editorjs.component';
import { NotifierService } from 'angular-notifier';
import { NewsService } from '../../news.service';
import { UploadService } from '../../upload.service';
import { NewsEditFormData } from '../news-edit/news-edit.component';
import { Router } from '@angular/router';
import { News } from 'src/app/shared/intefaces/news';
import { finalize, take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import slugify from 'slugify';

@Component({
  selector: 'app-news-form',
  templateUrl: './news-form.component.html',
  styleUrls: ['./news-form.component.scss'],
})
export class NewsFormComponent implements OnInit {
  image: string = '';
  file: string = '';

  @ViewChild(EditorjsComponent) editor: EditorjsComponent;

  newsForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    slug: new FormControl('', [Validators.required]),
    author: new FormControl(''),
    call: new FormControl('', [Validators.required]),
    tags: new FormControl(''),
  });

  constructor(
    private notifier: NotifierService,
    private newsService: NewsService,
    private uploadService: UploadService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.newsForm.get('title').valueChanges.subscribe((value) => {
      this.newsForm
        .get('slug')
        .setValue(slugify(value, { lower: true, strict: true }));
    });
  }

  public submit(): void {
    this.editor.save().then((data) => {
      this.newsService
        .getBySlug(this.newsForm.value.slug)
        .pipe(take(1))
        .subscribe((response) => {
          let entries = response.docs.map((doc) => doc.data());

          if (entries.length > 0) {
            this.notifier.notify('error', 'Slug já existe');
          } else {
            let rawTags = this.newsForm.value.tags;

            if (rawTags == undefined || rawTags == '') {
              rawTags = [];
            }

            let tags = rawTags.map((tag) => {
              return {
                title: tag,
                slug: slugify(tag, { lower: true, strict: true }),
              };
            });

            let tagSlugs = rawTags.map((tag) => {
              return slugify(tag, { lower: true, strict: true });
            });

            const id = uuidv4();

            let newsData: any = {
              ...this.newsForm.value,
              date: new Date(),
              id: id,
              published: true,
              highlight: false,
              highlight_home: false,
              highlight_news: false,
              image: this.image,
              has_image: this.image != '',
              pdf: this.file,
              description: JSON.stringify(data),
              tags: tags,
              tag_slugs: tagSlugs,
            };

            if (newsData.tags === '') {
              newsData.tags = null;
            }

            this.newsService
              .create(id, newsData)
              .then(() => {
                this.notifier.notify('success', 'Notícia criada com sucesso.');

                this.router.navigate(['/news']);
              })
              .catch(() => {
                this.notifier.notify('error', 'Erro ao criar notícia.');
              });
          }
        });
    });
  }

  public deleteImage(): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    this.image = '';
  }

  public deleteFile(): void {
    this.notifier.notify('success', 'Arquivo removido com sucesso.');

    this.file = '';
  }

  public uploadImage(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService
      .uploadFirebase(file, filePath)
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.image = filePath;

          this.notifier.notify('success', 'Imagem enviada com sucesso');
        })
      )
      .subscribe();
  }

  public uploadFile(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService
      .uploadFirebase(file, filePath)
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.file = filePath;

          this.notifier.notify('success', 'Arquivo enviado com sucesso');
        })
      )
      .subscribe();
  }
}

<app-static-content-header (addEvent)="selectAdd()" [showSort]="false" [showActive]="false"></app-static-content-header>

<ng-container *ngFor="let item of newsletter">
  <app-entry-item
    (deleteEvent)="delete($event)"
    [date]="getStatus(item)"
    [id]="item.id"
    editRoute="newsletter"
    [showEdit]="false"
    [showDelete]="false"
    [title]="item.subject">

  </app-entry-item>
</ng-container>

<div class="pagination" *ngIf="newsletter">
  <a class="pagination__link pagination__link--previous" (click)="prevPage()">Anterior</a>
  <a class="pagination__link pagination__link--next" (click)="nextPage()" *ngIf="newsletter.length == 10">Próximo</a>
</div>


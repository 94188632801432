<label [ngClass]="{'form__label--row': type === 'checkbox'}" class="form__label" for="">
  <span [ngClass]="{'form__title--error': showErrors()}" class="form__title">{{label}}</span>
  <input *ngIf="type === 'checkbox' " [formControl]="control" type="checkbox" type="checkbox" value="1">
  <span *ngIf="type !== 'checkbox'" [ngClass]="{'form__input-wrapper--textarea': type === 'textarea', 'form__input-wrapper--error': showErrors()}" class="form__input-wrapper">
    <input *ngIf="type === 'input'" type="text" class="form__input" [formControl]="control">

    <textarea *ngIf="type === 'textarea'" cols="30" rows="5" class="form__input" [formControl]="control"></textarea>

    <input *ngIf="type === 'date'" [formControl]="control" class="form__input" type="date">

    <input *ngIf="type === 'month'" [formControl]="control" class="form__input" type="month">

    <input *ngIf="type === 'time'" [formControl]="control" class="form__input" type="time">

    <input *ngIf="type === 'password'" [formControl]="control" class="form__input" type="password">

    <select (change)="changeOption($event)" *ngIf="type === 'select'" [formControl]="control" class="form__input">
      <option  *ngFor="let option of options" [ngValue]="option.value">{{option.title}}</option>
    </select>



     <input *ngIf="type === 'currency'" [formControl]="control" [options]="{align: 'left', prefix: 'R$ ', thousands: '.', decimal: ','}" class="form__input" currencyMask type="text">
  </span>
  <span *ngIf="showErrors()" class="form__errors">
    O campo é obrigatório <span *ngIf="type === 'password'">e precisa de pelo menos 6 caracteres.</span> </span>

</label>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { PackageService } from 'src/app/package.service';
import {PublishEvent} from '../../base/interfaces';


export interface Package {
  id: string;
  title: string;
  slug: string;
  version: number;
  image: string;
  magazines: string[];
  published: boolean;
}

@Component({
  selector: 'app-package-list',
  templateUrl: './package-list.component.html',
  styleUrls: ['./package-list.component.scss']
})
export class PackageListComponent implements OnInit {
  entry: any;
  status: boolean = true;
  sort: string = 'title';
  direction: string = 'after';
  packages: Package[];

  private sub: any;

  constructor(
    private router: Router,
    private packageService: PackageService,
    private notifier: NotifierService
  ) { }

  ngOnInit(): void {
    this.getList();
  }

  public getList(): void {
    if (!this.entry) {
      this.packageService.list(null, this.status, this.sort, this.direction).subscribe(response => {
        this.packages = response.docs.map(doc => doc.data() as any);
      });
    } else {
      this.packageService.list(this.entry, this.status, this.sort, this.direction).subscribe(response => {
        this.packages = response.docs.map(doc => doc.data() as any);
      });
    }
  }

  public delete(data: any) {
    const confirmDelete = confirm("Tem certeza que deseja deletar o pacote?");

    if (confirmDelete) {
      this.packageService.delete(data).then(() => {
        this.notifier.notify('success', 'Pacote deletado com sucesso');

        this.getList();
      }).catch(error => {
        this.notifier.notify('error', 'Não foi possível deletar o pacote');
      });
    }
  }

  public togglePublish(data: PublishEvent): void {
    this.packageService.togglePublish(data.id, data.published).then(
      response => {
        const message = (data.published) ? 'Pacote despublicado com sucesso!' : 'Pacote publicado com sucesso!';

        this.notifier.notify('success', message);

        this.getList();
      }
    );
  }

  selectAdd(): void {
    this.router.navigateByUrl('/packages/add');
  }


  selectActive(): void {
    this.status = true;
    this.getList();
  }

  selectDisabled(): void {
    this.status = false;
    this.getList();
  }

  prevPage(): void {
    this.entry = this.packages[0].id;
    this.direction = 'before';
    this.getList();
  }

  nextPage(): void {
    this.entry = this.packages[this.packages.length - 1].id;
    this.direction = 'after';
    this.getList();
  }

}

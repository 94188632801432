<form (ngSubmit)="submit()" [formGroup]="calendarForm" action="" class="form">
  <div class="form__left">
    <app-button [enabled]="calendarForm.valid" text="Adicionar" type="submit"></app-button>
    <app-input [control]="calendarForm.get('title')" label="Título*"></app-input>
    <app-input [control]="calendarForm.get('slug')" label="Slug*"></app-input>
    <app-input [control]="calendarForm.get('location')" label="Local"></app-input>
    <app-input [control]="calendarForm.get('link')" label="Link"></app-input>

  </div>

  <div class="form__right">
    <app-input [control]="calendarForm.get('start_date')" label="Data Início*" type="date"></app-input>
    <app-input [control]="calendarForm.get('end_date')" label="Data Fim*" type="date"></app-input>
    <app-input [control]="calendarForm.get('start_time')" label="Hora Início" type="time"></app-input>
    <app-input [control]="calendarForm.get('end_time')" label="Hora Fim" type="time"></app-input>

  </div>

</form>
<div class="form__content-title">Conteúdo</div>
<app-editorjs></app-editorjs>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth.guard';
import { GroupCreateComponent } from './group-create/group-create.component';
import { GroupEditComponent } from './group-edit/group-edit.component';
import { GroupListComponent } from './group-list/group-list.component';
import { UserListComponent } from './user-list/user-list.component';

const routes: Routes = [
  {path: 'groups', component: GroupListComponent, canActivate: [AuthGuard]},

  {path: 'groups/add', component: GroupCreateComponent, canActivate: [AuthGuard]},
  {path: 'groups/edit/:id', component: GroupEditComponent, canActivate: [AuthGuard]},
  {path: 'groups/:id/users', component: UserListComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GroupRoutingModule { }

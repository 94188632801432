<div *ngIf="filePdf != null && filePdf != ''" class="form__file-upload">
  <div (click)="deleteFile()" class="form__delete">
    <i class="form__delete-icon icon ion-ios-close"></i>
  </div>
  <i class="form__file-icon icon ion-ios-document"></i>
  <span class="form__file-title">{{filePdf}}</span>
</div>

<div *ngIf="filePdf == null || filePdf == ''" class="form__file-upload">
  <i class="form__file-icon icon ion-ios-document"></i>
  <input #input (change)="handleFileUpload($event.target.files)" class="form__file-input" type="file">
  <app-button (click)="input.click()" text="Enviar Arquivo"></app-button>
</div>

<form (ngSubmit)="submit()" [formGroup]="userForm" action="" class="form">
  <div class="form__left">
    <app-button [enabled]="userForm.valid" text="Atualizar" type="submit"></app-button>
    <app-input [control]="userForm.get('name')" label="Nome"></app-input>
    <app-input [control]="userForm.get('email')" label="E-mail"></app-input>

  </div>

  <div class="form__right">
    &nbsp;
  </div>

</form>



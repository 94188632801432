import {Injectable} from '@angular/core';
import {API_URL, ArticleFormData, CalendarEventFormData} from './base/interfaces';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {AngularFirestore, CollectionReference, Query} from '@angular/fire/firestore';
import {EventCalendar} from './shared/intefaces/event';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  url = `${API_URL}calendar`;

  constructor(private http: HttpClient, private auth: AuthService, private firestore: AngularFirestore) {
  }

  get options(): any {
    return {
      'headers': {
        // 'Authorization': `Bearer${this.auth.token}`
      }
    };
  }

  public list(entry: any, status: boolean, direction: string) {
    return this.firestore.collection<EventCalendar>('calendar', ref => {
      let query: CollectionReference | Query = ref;
      query = query.where('published', '==', status)
        .orderBy('start_date', 'desc');

      if (entry) {
        if (direction === 'after') {
          query = query.startAfter(entry).limit(10);
        } else {
          query = query.endBefore(entry).limitToLast(10).limit(10);
        }
      } else {
        query = query.limit(10);
      }

      return query;
    }).get();
  }

  public getEvent(id: string) {
    return this.firestore.collection<EventCalendar>('calendar').doc(id).get();
  }

  public create(id: string, data: any) {
    return this.firestore.collection('calendar').doc(id).set(data);
  }

  public update(id: string, data: any) {
    return this.firestore.collection('calendar').doc(id).update(data);
  }

  public delete(id: string) {
    return this.firestore.collection('calendar').doc(id).delete();
  }

  public togglePublish(id: string, published: boolean) {
    const data = {
      published: !published
    };

    return this.firestore.collection('calendar').doc(id).update(data);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EditionArticleRoutingModule } from './edition-article-routing.module';
import { EditionArticleListComponent } from './edition-article-list/edition-article-list.component';
import {BaseModule} from '../base/base.module';
import { EditionArticleCreateComponent } from './edition-article-create/edition-article-create.component';
import {ReactiveFormsModule} from '@angular/forms';
import { EditionArticleEditComponent } from './edition-article-edit/edition-article-edit.component';


@NgModule({
  declarations: [EditionArticleListComponent, EditionArticleCreateComponent, EditionArticleEditComponent],
  imports: [
    CommonModule,
    EditionArticleRoutingModule,
    BaseModule,
    ReactiveFormsModule
  ]
})
export class EditionArticleModule { }

import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UserService } from '../../user.service';
import { NotifierService } from 'angular-notifier';
import { AngularFireAuth } from '@angular/fire/auth';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

import algoliasearch from "algoliasearch";

const APP_ID = "YK4T9FI4H5";
const ADMIN_KEY = "7825c838f024805a18dde0793169e861";

const client = algoliasearch(APP_ID, ADMIN_KEY);
const index = client.initIndex("users");

export interface User {
  id: string;
  name: string;
  email: string;
  permissions: string[];
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  users: any[];
  entry: any;
  direction = 'after';
  searchTerm: string;

  constructor(
    private userService: UserService,
    private notifier: NotifierService,
    private afAuth: AngularFireAuth,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.pipe(take(1)).subscribe(
      user => {
        this.getList();
      }
    )
  }

  public getList(): void {
    this.userService.list(this.entry?.id, this.direction).subscribe(response => {
      this.users = response.docs.map(doc => {
        let data = doc.data();

        return data;
      })

    });
  }

  nextPage() {
    this.entry = this.users[this.users.length - 1];
    this.direction = 'after';
    this.getList();
    window.scrollTo(0, 0);
  }

  prevPage() {
    this.entry = this.users[0];
    this.direction = 'before';
    this.getList();
    window.scrollTo(0, 0);
  }

  public search(term) {
    this.searchTerm = term;
    client.clearCache();

    if (this.searchTerm == "") {
      return this.getList();
    }

    index.search(term).then(({hits}) => {
      this.users = hits.map(entry => {
        const data = entry as any;

        return {
          email: data.email,
          id: data.objectID,
          hasFirebaseUser: data.hasFirebaseUser,
          admin: data.admin,
          name: data.name
        } as any;
      })
    });;
  }

  selectAdd() {
    this.router.navigateByUrl('/users/add');
  }

  public changePassword(user): void {

    this.afAuth.sendPasswordResetEmail(user.email).then(() => {
      this.notifier.notify('success', 'E-mail de trocar senha enviado com sucesso.');
    }).catch(error => {
      this.notifier.notify('error', 'Não foi possível enviar o email de troca de senha.');
    });
  }

  public updateAdminAccess(user): void {
    const data = {
      admin: (user.admin == undefined || !user.admin) ? true : false
    }

    this.userService.updateAdminAccess(user.id, data).then(() => {
      this.notifier.notify('success', 'Acesso de administrador alterado com sucesso.');
      client.clearCache();
    }).catch(error => {
      this.notifier.notify('error', 'Não foi possível alterar o acesso de administrador.');
    });


  }

  public delete(data: any) {
    const confirmDelete = confirm("Tem certeza que deseja excluir o usuário?");

    if (confirmDelete) {
      this.userService.delete(data.toString()).then(() => {
          this.notifier.notify('success', 'Usuário excluído com sucesso');
          client.clearCache();
          this.getList();
      }).catch(error => {
          this.notifier.notify('error', 'Não foi possível excluir o usuário');
      });

    }
  }

}

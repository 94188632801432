import {Component, OnInit, ViewChild} from '@angular/core';
import {EditorjsComponent} from '../../base/editorjs/editorjs.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {UploadService} from '../../upload.service';
import {MagazineService} from '../../magazine.service';
import {Router} from '@angular/router';
import {Magazine} from '../magazine-list/magazine-list.component';
import {ArticleFormData, MagazineFormData} from '../../base/interfaces';

@Component({
  selector: 'app-magazine-create',
  templateUrl: './magazine-create.component.html',
  styleUrls: ['./magazine-create.component.scss']
})
export class MagazineCreateComponent implements OnInit {
  magazine: Magazine;

  @ViewChild(EditorjsComponent) editor: EditorjsComponent;

  magazineForm = new FormGroup({
    title: new FormControl('', [
        Validators.required
    ]),
    slug: new FormControl('', [
        Validators.required
    ]),
  });
  constructor(
    private notifier: NotifierService,
    private uploadService: UploadService,
    private magazineService: MagazineService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.magazine = {
      id: '',
      title: '',
      slug: '',
      description: '',
      image: '',
      published: false
    };
  }

  public deleteImage(): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    this.magazine.image = '';
  }

  public uploadImage(file: File): void {
    this.uploadService.uploadImage(file).subscribe(
      response => {
        const fileUrl = response['file'].url;
        const splitFileUrl = fileUrl.split('/');
        const fileName = splitFileUrl[splitFileUrl.length - 1];

        this.magazine.image = fileName;

        this.notifier.notify('success', 'Imagem enviada com sucesso');
        // TODO implement upload to firestore
      },
      error => {
        this.notifier.notify('error', 'Erro no upload da imagem');
      }
    );
  }

  public submit() {
    this.editor.save().then(data => {
      let magazineData: MagazineFormData = {
        ...this.magazineForm.value,
        image: this.magazine.image,
        description: JSON.stringify(data)
      };


      this.magazineService.create(magazineData).subscribe(
        response => {
          this.notifier.notify('success', 'Revista criada com sucesso');

          this.router.navigate(['/magazines/disabled/date/1']);
        },
        error => {
          this.notifier.notify('error', 'Erro na hora de criar os dados');
        }
      );
    });
  }

}

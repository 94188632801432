import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewsletterRoutingModule } from './newsletter-routing.module';
import { NewsletterListComponent } from './pages/newsletter-list/newsletter-list.component';
import { BaseModule } from '../base/base.module';
import { NewsletterCreateComponent } from './pages/newsletter-create/newsletter-create.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NewsletterCustomCreateComponent } from './newsletter-custom-create/newsletter-custom-create.component';
import { EmailEditorModule } from 'angular-email-editor';


@NgModule({
  declarations: [NewsletterListComponent, NewsletterCreateComponent, NewsletterCustomCreateComponent],
  imports: [
    CommonModule,
    NewsletterRoutingModule,
    BaseModule,
    ReactiveFormsModule,
    EmailEditorModule
  ]
})
export class NewsletterModule { }

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MagazineService} from '../../magazine.service';
import {NotifierService} from 'angular-notifier';
import {DatePipe} from '@angular/common';
import {PublishEvent} from '../../base/interfaces';

export interface Magazine {
  id: string;
  title: string;
  slug: string;
  image: string;
  description: string;
  published: boolean;
}

@Component({
  selector: 'app-magazine-list',
  templateUrl: './magazine-list.component.html',
  styleUrls: ['./magazine-list.component.scss']
})
export class MagazineListComponent implements OnInit {
  page: number;
  status: string = 'active';
  sort: string = 'date';

  magazines: Magazine[];

  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private magazineService: MagazineService,
    private notifier: NotifierService
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {

      this.page = (params['page'] === undefined) ? 1 : +params['page'];

      if (params['status'] !== undefined) {
        this.status = params['status'];
      }

      if (params['sort'] !== undefined) {
        this.sort = params['sort'];
      }


      this.getList(this.page, this.status, this.sort);
    });
  }

  public getList(page: number, status: string, sort: string): void {
    this.magazineService.list(page, status, sort).subscribe(response => {


      this.magazines = response['data'];
    });
  }

  public delete(data: any) {
    const confirmDelete = confirm("Tem certeza que deseja deletar a revista?");

    if (confirmDelete) {
      this.magazineService.delete(data).subscribe(
        response => {
          this.notifier.notify('success', 'Revista deletada com sucesso');

          this.getList(this.page, this.status, this.sort);
        },
        error => {
          this.notifier.notify('error', 'Não foi possível deletar a revista');
        }
      );
    }
  }

  public togglePublish(data: PublishEvent): void {
    this.magazineService.togglePublish(data.id, data.published).subscribe(
      response => {
        const message = (data.published) ? 'Revista despublicada com sucesso!' : 'Revista publicada com sucesso!';

        this.notifier.notify('success', message);

        this.getList(this.page, this.status, this.sort);
      }
    );
  }

}

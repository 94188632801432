import { Component, OnInit, ViewChild } from '@angular/core';
import { EditorjsComponent } from '../../base/editorjs/editorjs.component';
import { NotifierService } from 'angular-notifier';
import { CalendarService } from '../../calendar.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ArticleFormData, CalendarEventFormData } from '../../base/interfaces';
import { EventCalendar } from '../../shared/intefaces/event';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import slugify from 'slugify';

@Component({
  selector: 'app-calendar-create',
  templateUrl: './calendar-create.component.html',
  styleUrls: ['./calendar-create.component.scss'],
})
export class CalendarCreateComponent implements OnInit {
  calendarEvent: EventCalendar;

  @ViewChild(EditorjsComponent) editor: EditorjsComponent;

  calendarForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    slug: new FormControl('', [Validators.required]),
    location: new FormControl(''),
    link: new FormControl(''),
    start_date: new FormControl('', [Validators.required]),
    end_date: new FormControl('', [Validators.required]),
    start_time: new FormControl(''),
    end_time: new FormControl(''),
  });

  constructor(
    private notifier: NotifierService,
    private calendarService: CalendarService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.calendarForm.get('title').valueChanges.subscribe((value) => {
      this.calendarForm.get('slug').setValue(
        slugify(value, {
          lower: true,
          strict: true,
        })
      );
    });
  }

  public submit() {
    this.editor.save().then((data) => {
      const id = uuidv4();

      let calendarData: any = {
        ...this.calendarForm.value,
        id,
        start_date: moment(this.calendarForm.value.start_date).toDate(),
        end_date: moment(this.calendarForm.value.end_date).toDate(),
        description: JSON.stringify(data),
        published: true,
      };

      this.calendarService
        .create(id, calendarData)
        .then(() => {
          this.notifier.notify('success', 'Evento criado com sucesso');

          this.router.navigate(['/calendar']);
        })
        .catch(() => {
          this.notifier.notify('error', 'Não foi possível criar o evento');
        });
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { take } from 'rxjs/operators';
import { GroupService } from 'src/app/services/group.service';
import { Group } from 'src/app/shared/intefaces/group';
import { GroupUser } from 'src/app/shared/intefaces/group-user';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  group: Group;
  users: GroupUser[];
  userForm = new FormGroup({
    email: new FormControl('', [
      Validators.required
    ])
  });

  constructor(private groupService: GroupService,
    private route: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private userService: UserService,
    private afAuth: AngularFireAuth,
    private loader: NgxUiLoaderService) { }

  ngOnInit(): void {
    this.loader.start();
    this.route.params.pipe(take(1)).subscribe(params => {
      this.afAuth.authState.pipe(take(2)).subscribe(user => {
        this.groupService.getGroup(params.id).pipe(take(1)).subscribe(group => {

          this.loader.stop();
          this.group = group.data();
          this.users = this.group['users'];
        }, error => {
          this.notifier.notify('error', 'Não foi possível encontrar o grupo.');
          this.loader.stop();
          this.router.navigateByUrl('/groups');
        });
      });


    });
  }

  submit() {
    this.loader.start();
    let user = this.users.find(user => user.email === this.userForm.value.email);

    if (user !== undefined) {
      this.notifier.notify('error', 'Usuário já está no grupo.');
      this.userForm.reset();
      this.loader.stop();
    } else {
      this.userService.getByEmail(this.userForm.value.email).pipe(take(1)).subscribe(
        user => {
          if (user.docs.length < 1) {
            this.notifier.notify('error', 'Usuário não encontrado.');
            this.userForm.reset();
            this.loader.stop();
          } else {
            let data = user.docs[0].data();

            if (data['newsletter'] == false) {
              this.notifier.notify('error', 'Esse usuário não aceita receber newsletters.');
              this.userForm.reset();
              this.loader.stop();
            } else {
              this.users.push({ email: data['email'] });

              this.groupService.update(this.group.id, { users: this.users }).then(() => {
                this.notifier.notify('success', 'Usuário adicionado com sucesso.');
                this.userForm.reset();
                this.loader.stop();
              }).catch(error => {
                this.notifier.notify('error', 'Não foi possível adicionar o usuário.');
                this.userForm.reset();
                this.loader.stop();
              });
            }

          }
        },
        error => {
          this.notifier.notify('error', 'Usuário não encontrado.');
          this.userForm.reset();
          this.loader.stop();
        }
      );
    }

  }

  delete(email: string) {
    this.loader.start();

    this.users = this.users.filter(user => user.email !== email);

    this.groupService.update(this.group.id, { users: this.users }).then(() => {
      this.notifier.notify('success', 'Usuário excluído com sucesso.');
      this.loader.stop();
    }).catch(error => {
      this.notifier.notify('error', 'Não foi possível excluir o usuário.');
      this.loader.stop();
    });

  }

}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL, EditionFormData, MagazineFormData} from './base/interfaces';
import {AuthService} from './auth.service';
import {AngularFirestore, CollectionReference, Query} from '@angular/fire/firestore';
import {Edition} from './shared/intefaces/edition';

@Injectable({
  providedIn: 'root'
})
export class EditionService {
  url = `${API_URL}edition`;

  constructor(private http: HttpClient, private auth: AuthService, private firestore: AngularFirestore) { }

  get options(): any {
    return {
      'headers': {
        // 'Authorization': `Bearer${this.auth.token}`
      }
    };
  }

  public list(edition: any, magazine: string, status: boolean, sort: string, direction: string) {
    return this.firestore.collection<Edition>('editions', ref => {
      let query: CollectionReference | Query = ref;

      query = query.where('published', '==', status)
        .where('magazine', '==', magazine)
        .orderBy(sort, 'desc');

      if (edition) {
        if (direction === 'after') {
          query = query.startAfter(edition).limit(20);
        } else {
          query = query.endBefore(edition).limitToLast(20).limit(20);
        }
      } else {
        query = query.limit(20);
      }

      return query;
    }).get();
  }

  public getEdition(id: string) {
    return this.firestore.collection<EditionService>('editions').doc(id).get();
  }

  public create(id: string, data: any) {
    return this.firestore.collection('editions').doc(id).set(data);
  }

  public update(id:string, data: EditionFormData) {
    return this.firestore.collection('editions').doc(id).update(data);
  }

  public delete(id: string) {
    return this.firestore.collection('editions').doc(id).delete();
  }

  public togglePublish(id: string, published: boolean) {
    const data = {
      published: !published
    };

    return this.firestore.collection('editions').doc(id).update(data);
  }

  public toggleHighlight(id: string, currentStatus: boolean) {
    const data = {
      highlight: !currentStatus
    };


    return this.firestore.collection('editions').doc(id).update(data);
  }
}

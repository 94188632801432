<app-static-content-header (addEvent)="selectAdd()" (activeEvent)="selectActive()" (disabledEvent)="selectDisabled()" (searchEvent)="search($event)" (sortEvent)="selectSort($event)"></app-static-content-header>

<ng-container *ngFor="let newsItem of news">
  <app-entry-item
    [id]="newsItem.id"
    [title]="newsItem.title"
    [date]="newsItem.date | date:'dd/MM/YYYY'"
    editRoute="news"
    (deleteEvent)="delete($event)">
    <app-publish-action  (publishEvent)="togglePublish($event)" [id]="newsItem.id" [published]="newsItem.published"></app-publish-action>

    <app-news-actions
      (highlightHomeEvent)="toggleHighlight($event)"
      (highlightNewsEvent)="toggleHighlight($event)"
      [highlightHome]="newsItem.highlight_home"
      [highlightNews]="newsItem.highlight_news"
      [id]="newsItem.id"
      [published]="newsItem.published"
    ></app-news-actions>
    <app-item-action
        (click)="toggleNewsletter(newsItem)"
        [checkCondition]="newsItem.newsletter"
        disabledText="Enviar para Newsletter"
        enabledText="Tirar da Newsletter" ></app-item-action>
  </app-entry-item>
</ng-container>

<div class="pagination">
  <a class="pagination__link pagination__link--previous" (click)="prevPage()">Anterior</a>
  <a class="pagination__link pagination__link--next" (click)="nextPage()">Próximo</a>
</div>

<div class="list-item">
  <h2 class="list-item__title">{{title}}</h2>
  <div class="list-item__details">
    <div class="list-item__details-wrapper">
      <span class="list-item__date">{{date}}</span>
      <div class="list-item__actions--main">
        <a [routerLink]="['/', editRoute, 'edit', id]" class="list-item__action list-item__action--default" *ngIf="showEdit">Editar</a>
        <a (click)="delete()" class="list-item__action list-item__action--delete" *ngIf="showDelete">Excluir</a>
      </div>
    </div>

    <div class="list-item__actions--secondary">
      <ng-content></ng-content>
    </div>
  </div>
</div>

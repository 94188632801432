<form (ngSubmit)="submit()" [formGroup]="newsletterForm" action="" class="form">
  <div class="form__left">
    <app-button [enabled]="newsletterForm.valid" text="Adicionar" type="submit"></app-button>
    <app-input [control]="newsletterForm.get('subject')" label="Assunto"></app-input>
  </div>

</form>
<div class="form">
  <div class="form__left">
    <div class="form__content-title">Notícias</div>
    <div *ngFor="let item of news" (click)="selectHighlight(item.id)" class="item-selector" [ngClass]="{'item-selector--selected': selectedHighlight === item.id}">{{item.title}}</div>
  </div>

  <div class="form__right">
    <div class="form__content-title">Artigos</div>
    <div *ngFor="let item of articles" (click)="selectHighlight(item.id)" class="item-selector" [ngClass]="{'item-selector--selected': selectedHighlight === item.id}">{{item.title}}</div>


  </div>

</div>
<div class="form">
  <div class="form__left">

    <div class="form__content-title">Grupos</div>
    <div *ngFor="let item of groups" (click)="selectGroup(item)" class="item-selector" [ngClass]="{'item-selector--selected': groupIsSelected(item)}">{{item.name}}</div>
  </div>


</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { API_URL, UserEditFormData, UserFormData, UserPasswordFormData } from './base/interfaces';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/firestore';
import { User } from './shared/intefaces/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  url = `${API_URL}user`;

  constructor(private http: HttpClient,
    private firestore: AngularFirestore) { }

  get options(): any {
    return {
      'headers': {
      }
    };
  }

  public getByEmail(email: string) {
    return this.firestore.collection('users', ref => ref.where('email', '==', email).limit(1)).get();
  }

  public get(id: string) {
    return this.firestore.collection<User>('users').doc(id).get();
  }

  public list(entry: any, direction: string) {
    return this.firestore.collection('users', ref => {
      let query: CollectionReference | Query = ref;

      query = query.orderBy('id', 'desc').limit(20);

      if (entry) {
        if (direction == 'before') {
          query = query.endBefore(entry).limitToLast(20);
        } else {
          query = query.startAfter(entry).limit(20);
        }
      }

      return query;
    }).get();
  }

  public create(data: any) {
    const doc = this.firestore.collection('users').doc(data.email)

    return doc.ref.set(data);
  }

  public update(id: string, data: any) {
    return this.firestore.collection('users').doc(id).update(data);
  }

  public updateAdminAccess(id: string, newAdminAccess: any) {
    return this.firestore.collection('users').doc(id).update(newAdminAccess);
  }

  public delete(id: string) {
    return this.firestore.collection('users').doc(id).delete();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EditionRoutingModule } from './edition-routing.module';
import { EditionListComponent } from './edition-list/edition-list.component';
import {BaseModule} from '../base/base.module';
import { EditionCreateComponent } from './edition-create/edition-create.component';
import {ReactiveFormsModule} from '@angular/forms';
import { EditionEditComponent } from './edition-edit/edition-edit.component';


@NgModule({
  declarations: [EditionListComponent, EditionCreateComponent, EditionEditComponent],
    imports: [
        CommonModule,
        EditionRoutingModule,
        BaseModule,
        ReactiveFormsModule
    ]
})
export class EditionModule { }

<app-static-content-header (addEvent)="selectAdd()" (activeEvent)="selectActive()" (disabledEvent)="selectDisabled()" (searchEvent)="search($event)" [showSort]="false"></app-static-content-header>

<ng-container *ngFor="let tractor of tractors">
  <app-entry-item
    (deleteEvent)="delete($event)"
    [date]="tractor.date.toDate() | date:'dd/MM/YYYY'"
    [id]="tractor.id"
    editRoute="tractors"
    [title]="tractor.brand + ' ' + tractor.model">
    <app-publish-action (publishEvent)="togglePublish($event)" [id]="tractor.id" [published]="tractor.published"></app-publish-action>

  </app-entry-item>
</ng-container>

<div class="pagination">
  <a class="pagination__link pagination__link--previous" (click)="prevPage()">Anterior</a>
  <a class="pagination__link pagination__link--next" (click)="nextPage()">Próximo</a>
</div>

import { ThrowStmt } from '@angular/compiler';
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData: any; // Save logged in user data

  constructor(
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private userService: UserService,
    private notifier: NotifierService,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;

        this.userService.getByEmail(user.email).subscribe(user => {
          if (user.docs.length < 1) {
            localStorage.removeItem('user');
            this.SignOut()
            return;
          }

          let userData = user.docs[0].data();

          if (userData['admin'] === undefined || !userData['admin']) {
            localStorage.removeItem('user');
            this.SignOut();
            return;
          }

          localStorage.setItem('user', JSON.stringify(user));
        });
      } else {
        localStorage.removeItem('user');
      }
    });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }

  get token(): string {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.stsTokenManager.accessToken;
  }

  // Sign in with email/password
  signIn(email, password) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(email, password)
        .then((result) => {

          this.userService.getByEmail(email).subscribe(user => {
            if (user.docs.length < 1) {
              reject('Usuário não encontrado');
              return;
            }

            let userData = user.docs[0].data();

            if (userData['admin'] === undefined || !userData['admin']) {
              reject('Você não é admnistrador.')
              return;
            }

            localStorage.setItem('user', JSON.stringify(result.user));

            resolve(result);
          });

        }).catch((error) => {
          reject(error.message);
        });
    });

  }

  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['login']);
    });
  }
}

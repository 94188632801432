import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WallpaperRoutingModule } from './wallpaper-routing.module';
import { WallpaperListComponent } from './wallpaper-list/wallpaper-list.component';
import {BaseModule} from '../base/base.module';
import { WallpaperCreateComponent } from './wallpaper-create/wallpaper-create.component';
import {ReactiveFormsModule} from '@angular/forms';
import { WallpaperEditComponent } from './wallpaper-edit/wallpaper-edit.component';


@NgModule({
  declarations: [WallpaperListComponent, WallpaperCreateComponent, WallpaperEditComponent],
  imports: [
    CommonModule,
    WallpaperRoutingModule,
    BaseModule,
    ReactiveFormsModule
  ]
})
export class WallpaperModule { }

<form (ngSubmit)="submit()" [formGroup]="editionForm" action="" class="form">
  <div class="form__left">
    <app-button [enabled]="editionForm.valid" text="Adicionar" type="submit"></app-button>
    <app-input [control]="editionForm.get('title')" label="Título"></app-input>
    <app-input [control]="editionForm.get('issuu')" label="ISSUU"></app-input>
    <app-input [control]="editionForm.get('number')" label="Número"></app-input>
    <app-input [control]="editionForm.get('month')" label="Mês" type="month"></app-input>
    <app-input [control]="editionForm.get('year')" label="Ano"></app-input>

    <app-input [control]="editionForm.get('restricted')" label="Restrito?" type="checkbox"></app-input>
  </div>
  <div class="form__right">
    <app-image-upload
      (deleteEvent)="deleteImage()"
      (uploadEvent)="uploadImage($event)"
      [image]="image"
    ></app-image-upload>

    <app-file-upload
      (deleteEvent)="deleteFile()"
      (uploadEvent)="uploadFile($event)"
      [filePdf]="file"
    ></app-file-upload>

  </div>

</form>
<div class="form__content-title">Conteúdo</div>
<app-editorjs></app-editorjs>

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EbookService} from '../../ebook.service';
import {NotifierService} from 'angular-notifier';
import {PublishEvent} from '../../base/interfaces';

export interface Ebook {
  id: string;
  title: string;
  call: string;
  image: string;
  file: string;
  published: boolean;
}

@Component({
  selector: 'app-ebook-list',
  templateUrl: './ebook-list.component.html',
  styleUrls: ['./ebook-list.component.scss']
})
export class EbookListComponent implements OnInit {
  page: number;
  status: string = 'active';
  sort: string = 'title';

  ebooks: Ebook[];

  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private ebookService: EbookService,
    private notifier: NotifierService
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {

      this.page = (params['page'] === undefined) ? 1 : +params['page'];

      if (params['status'] !== undefined) {
        this.status = params['status'];
      }

      if (params['sort'] !== undefined) {
        this.sort = params['sort'];
      }


      this.getList(this.page, this.status, this.sort);
    });
  }

  public getList(page: number, status: string, sort: string): void {
    this.ebookService.list(page, status, sort).subscribe(response => {


      this.ebooks = response['data'];
    });
  }

  public delete(data: any) {
    const confirmDelete = confirm("Tem certeza que deseja excluir o ebook?");

    if (confirmDelete) {
      this.ebookService.delete(data).subscribe(
        response => {
          this.notifier.notify('success', 'Ebook excluído com sucesso');

          this.getList(this.page, this.status, this.sort);
        },
        error => {
          this.notifier.notify('error', 'Não foi possível excluir o ebook');
        }
      );
    }
  }

  public togglePublish(data: PublishEvent): void {
    this.ebookService.togglePublish(data.id, data.published).subscribe(
      response => {
        const message = (data.published) ? 'Ebook despublicado com sucesso!' : 'Ebook publicado com sucesso!';

        this.notifier.notify('success', message);

        this.getList(this.page, this.status, this.sort);
      }
    );
  }

}

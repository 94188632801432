<app-static-content-header (addEvent)="selectAdd()" (activeEvent)="selectActive()" (disabledEvent)="selectDisabled()" [showSort]="false"></app-static-content-header>
<ng-container *ngFor="let banner of banners">
  <app-entry-item
    (deleteEvent)="delete($event)"
    [date]=""
    [id]="banner.id"
    [title]="banner.title"
    editRoute="banners">
    <app-publish-action (publishEvent)="togglePublish($event)" [id]="banner.id" [published]="banner.published"></app-publish-action>


  </app-entry-item>
</ng-container>


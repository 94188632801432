<app-content-header [sort]="sort" [status]="status" baseRoute="periods"></app-content-header>
<ng-container *ngFor="let period of periods">
  <app-entry-item
    (deleteEvent)="delete($event)"
    [date]=""
    [id]="period.id"
    [title]="period.title"
    editRoute="periods">
    <app-publish-action (publishEvent)="togglePublish($event)" [id]="period.id" [published]="period.published"></app-publish-action>


  </app-entry-item>
</ng-container>

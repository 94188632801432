import { Component, OnInit } from '@angular/core';
import {Package} from '../package-list/package-list.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {UploadService} from '../../upload.service';
import {PackageService} from '../../package.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MagazineFormData, Option, PackageFormData} from '../../base/interfaces';
import {Magazine} from '../../magazine/magazine-list/magazine-list.component';
import {MagazineService} from '../../magazine.service';
import { v4 as uuidv4 } from 'uuid';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-package-edit',
  templateUrl: './package-edit.component.html',
  styleUrls: ['./package-edit.component.scss']
})
export class PackageEditComponent implements OnInit {
  package: any;
  magazines: Magazine[];
  magazineTitles = [];
  options = [
    {value: 0, title: "Digital + Impressa"},
    {value: 1, title: "Digital"},
  ];

  packageForm = new FormGroup({
    title: new FormControl('', [
      Validators.required
    ]),
    slug: new FormControl('', [
      Validators.required
    ]),
    version: new FormControl(''),
    magazines: new FormControl('')
  });

  private sub: any;

  constructor(
    private notifier: NotifierService,
    private uploadService: UploadService,
    private packageService: PackageService,
    private magazineService: MagazineService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.magazineService.list(1, 'active', 'title').subscribe(
        response => {
          this.magazines = response['data']

          this.magazines.forEach(entry => {
            this.magazineTitles.push(entry.title);
          });

          if (params.id === undefined) {
            this.notifier.notify('error', 'Pacote não encontrado');

            this.router.navigate(['/packages']);
          }

          const id = params.id;

          this.packageService.getPackage(id).pipe(take(1)).subscribe(
            response => {
              this.package = response.data();

              let magazineList = [];

              this.magazines.forEach(magazine => {
                if (this.package.magazines.includes(magazine.id)) {
                  magazineList.push(magazine.title);
                }

              });


              this.packageForm.patchValue({
                title: this.package.title,
                slug: this.package.slug,
                version: this.package.version,
                magazines: magazineList
              });

            },
            error => {
              this.notifier.notify('error', error.message);
            });
        }
      );

    });
  }

  public uploadImage(file: File): void {
    this.uploadService.uploadImage(file).subscribe(
      response => {
        const fileUrl = response['file'].url;
        const splitFileUrl = fileUrl.split('/');
        const fileName = splitFileUrl[splitFileUrl.length - 1];

        this.package.image = fileName;

        this.notifier.notify('success', 'Imagem enviada com sucesso');
        // TODO implement upload to firestore
      },
      error => {
        this.notifier.notify('error', 'Erro no upload da imagem');
      }
    );
  }

  public deleteImage(): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    this.package.image = '';
  }

  public submit() {
    let magazineList = [];

    this.magazines.forEach(magazine => {
      if (this.packageForm.value.magazines.includes(magazine.title)) {
        magazineList.push(magazine.id);
      }
    });

    let packageData: any = {
      ...this.packageForm.value,
      magazines: magazineList,
      image: this.package.image
    };


    this.packageService.update(this.package.id, packageData).then(() => {
      this.notifier.notify('success', 'Pacote atualizado com sucesso');

      this.router.navigate(['/packages']);
    }).catch(error => {
      this.notifier.notify('error', 'Erro na hora de atualizar os dados');
    });
  }

  public changeVersion(entry: Option) {
    this.package.version = entry.value;

    this.packageForm.patchValue({
      version: entry.value
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { take } from 'rxjs/operators';
import { Subscription } from 'src/app/shared/intefaces/subscription';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';

import algoliasearch from "algoliasearch";

const APP_ID = "YK4T9FI4H5";
const ADMIN_KEY = "7825c838f024805a18dde0793169e861";

const client = algoliasearch(APP_ID, ADMIN_KEY);
const index = client.initIndex("subscriptions");

@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss']
})
export class SubscriptionListComponent implements OnInit {
  subscriptions: any[];
  entry: any;
  direction: string = 'after';
  searchTerm: string;
  statuses = {
    2: 'Em análise',
    3: 'Pago',
    4: 'Disponível',
    5: 'Em Disputa',
    6: 'Devolvida',
    7: 'Cancelada'
  }

  constructor(private subscriptionService: SubscriptionService,
    private notifier: NotifierService,
    private router: Router,
    private afAuth: AngularFireAuth) { }

  ngOnInit(): void {
    this.afAuth.authState.pipe(take(1)).subscribe(
      user => {
        this.getList(null, 'after');
      }
    );
  }

  getList(entry: string, direction: string) {
    if (entry) {
      this.subscriptionService.get(entry).pipe(take(1)).subscribe(
        subscription => {

          this.subscriptionService.list(subscription, direction).pipe(take(1)).subscribe(
            subscriptions => {
              this.subscriptions = subscriptions.docs.map(doc => {
                let data = doc.data()

                if (data.date.toDate !== undefined) {
                  data.date = data.date.toMillis();
                }

                return data;
              });
            },
            error => {
              this.notifier.notify('error', "Não foi possível carregar.");
            }
          );

        },
        error => {
          this.notifier.notify('error', "Não foi possível carregar.");
        }
      );

    } else {
      this.subscriptionService.list(null, direction).pipe(take(1)).subscribe(subscriptions => {
        this.subscriptions = subscriptions.docs.map(doc => {
          let data = doc.data()

          if (data.date.toDate !== undefined) {
            data.date = data.date.toMillis();
          }

          return data;
        });
      },
        error => {
          console.log(error);
          this.notifier.notify('error', "Não foi possível carregar.");
        }
      );
    }
  }

  nextPage() {
    this.entry = this.subscriptions[this.subscriptions.length - 1];
    this.direction = 'after';
    this.getList(this.subscriptions[this.subscriptions.length - 1].id, 'after');
    window.scrollTo(0, 0);
  }

  prevPage() {
    this.entry = this.subscriptions[0];
    this.direction = 'before';
    this.getList(this.subscriptions[0].id, 'before');
    window.scrollTo(0, 0);
  }

  selectAdd() {
    this.router.navigateByUrl('/subscriptions/add');
  }

  public delete(data: any) {
    const confirmDelete = confirm("Tem certeza que deseja excluir a assinatura?");

    if (confirmDelete) {
      this.subscriptionService.delete(data.toString()).then(response => {
        this.notifier.notify('success', 'Assinatura excluída com sucesso!');
        let id = (this.entry) ? this.entry.id : null;

        this.getList(id, this.direction);
      }).catch(error => {
        this.notifier.notify('error', 'Não foi possível excluir a assinatura.');
      });
    }
  }

  duplicate(id: string) {
    console.log(id);
    this.subscriptionService.get(id).pipe(take(1)).subscribe(entry => {
      const subscription = entry.data() as any;

      const newId = uuidv4();
      const today = new Date();

      const newsSubscription = {
        id: newId,
        date: today,
        payDate: today,
        expirationDate: moment().add(subscription.months, 'months').toDate(),
        address: subscription.address,
        cep: subscription.cep,
        city: subscription.city,
        complement: subscription.complement,
        email: subscription.email,
        months: subscription.months,
        neighborhood: subscription.neighborhood,
        number: subscription.number,
        origin: 'duplication',
        price: subscription.price,
        pagseguro: subscription.pagseguro,
        state: subscription.state,
        status: subscription.status,
        transaction: subscription.transaction,
        user: subscription.user
      };

      this.subscriptionService.save(newId, newsSubscription).then(() => {
        this.notifier.notify('success', 'Assinatura duplicada com sucesso');
      }).catch(() => {
        this.notifier.notify('error', 'Erro ao duplicar assinatura');
      });
    });

  }

  public search(term) {
    this.searchTerm = term;

    if (this.searchTerm == "") {
      return this.getList(null, this.direction);
    }


    index.search(term).then(({hits}) => {
      this.subscriptions = hits.map(entry => {
        const data = entry as any;

        return {
          email: data.email,
          id: data.objectID,
          date: new Date(data.date)
        } as any;
      })
    });;
  }
}

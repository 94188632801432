import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { take } from 'rxjs/operators';
import { Newsletter } from 'src/app/shared/intefaces/newsletter';
import { NewsletterService } from 'src/app/shared/services/newsletter.service';

@Component({
  selector: 'app-newsletter-list',
  templateUrl: './newsletter-list.component.html',
  styleUrls: ['./newsletter-list.component.scss']
})
export class NewsletterListComponent implements OnInit {
  newsletter: Newsletter[];
  entry: string;
  direction: string = 'after';

  constructor(private router: Router,
    private newsletterService: NewsletterService,
    private afAuth: AngularFireAuth,
    private notifier: NotifierService) { }

  ngOnInit(): void {
    this.afAuth.authState.pipe(take(1)).subscribe(
      response => {
        this.getList(null, 'after');
      }
    );

  }

  prevPage() {
    this.getList(this.newsletter[0].id.toString(), 'after');
    window.scrollTo(0, 0);
  }

  nextPage() {
    this.getList(this.newsletter[this.newsletter.length - 1].id.toString(), 'after');
    window.scrollTo(0, 0);
  }

  selectAdd() {
    this.router.navigateByUrl('/newsletter/add');
  }

  getStatus(newsletter: Newsletter) {
    if (newsletter.completed) {
      return 'Completa';
    }

    return newsletter.lastSent + ' e-mails enviados';
  }

  public getList(entry: string, direction: string): void {
    console.log(entry, direction);
    if (entry != null) {
      this.newsletterService.get(entry).pipe(take(1)).subscribe(
        response => {
          this.newsletterService.list(response, direction).subscribe(
            response => {
              this.newsletter = response.docs.map(item => item.data());
            },
            error => {
              this.notifier.notify('error', 'Ocorreu um erro ao carregar a lista de newsletter');
            }
          );

        }
      );
    } else {
      this.newsletterService.list(null, direction).subscribe(
        response => {
          this.newsletter = response.docs.map(item => item.data());
        },
        error => {
          console.log(error);
          this.notifier.notify('error', 'Ocorreu um erro ao carregar a lista de newsletter');
        }
      );
    }

  }

}

<form (ngSubmit)="submit()" [formGroup]="newsForm" action="" class="news-form">
  <div class="news-form__left">
    <app-button [enabled]="newsForm.valid" text="Adicionar" type="submit"></app-button>
    <app-input [control]="newsForm.get('title')" label="Título"></app-input>
    <app-input [control]="newsForm.get('slug')" label="Slug"></app-input>
    <app-input [control]="newsForm.get('author')" label="Autor"></app-input>
    <app-input [control]="newsForm.get('call')" label="Chamada" type="textarea"></app-input>

    <app-tag-input [control]="newsForm.get('tags')"></app-tag-input>

  </div>

  <div class="news-form__right">
    <app-image-upload
      (deleteEvent)="deleteImage()"
      (uploadEvent)="uploadImage($event)"
      [image]="image"
    ></app-image-upload>

    <app-file-upload
      (deleteEvent)="deleteFile()"
      (uploadEvent)="uploadFile($event)"
      [filePdf]="file"
    ></app-file-upload>
  </div>

</form>
<div class="news-form__content-title">Conteúdo</div>
<app-editorjs></app-editorjs>

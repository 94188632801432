import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { take } from 'rxjs/operators';
import { GroupService } from 'src/app/services/group.service';
import { Group } from 'src/app/shared/intefaces/group';


@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss']
})
export class GroupEditComponent implements OnInit {
  groupForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
    ]),
    description: new FormControl(''),
  });
  group: Group;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private groupService: GroupService,
    private notifier: NotifierService) { }

  ngOnInit(): void {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.groupService.getGroup(params.id).pipe(take(1)).subscribe(group => {
        this.group = group.data();


        this.groupForm.patchValue({
          name: this.group['name'],
          description: this.group['description']
        });

      }, error => {
        this.notifier.notify('error', 'Não foi possível encontrar o grupo.');
        this.router.navigateByUrl('/groups');
      });
    });
  }

  submit() {
    this.groupService.update(this.group.id, this.groupForm.value).then(() => {
      this.notifier.notify('success', 'Grupo atualizado com sucesso.');
      this.router.navigateByUrl('/groups');
    }).catch(error => {
      this.notifier.notify('error', 'Não foi possível atualizar o grupo.');
    });
  }

}

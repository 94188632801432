import { Component, OnInit } from '@angular/core';
import {Period} from '../period-list/period-list.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {Router} from '@angular/router';
import {PeriodService} from '../../period.service';
import {Option, PackageFormData, PeriodFormData} from '../../base/interfaces';
import {PackageService} from '../../package.service';
import {Package} from '../../package/package-list/package-list.component';

@Component({
  selector: 'app-period-create',
  templateUrl: './period-create.component.html',
  styleUrls: ['./period-create.component.scss']
})
export class PeriodCreateComponent implements OnInit {
  period: Period;
  packages: Package[];

  periodForm = new FormGroup({
    title: new FormControl(''),
    months: new FormControl('', [
        Validators.required
    ]),
    package: new FormControl('', [
        Validators.required
    ]),
    price: new FormControl('', [
        Validators.required
    ]),
  });

  periodOptions = [
    {value: 12, title: '1 ano'},
    {value: 24, title: '2 anos'},
    {value: 36, title: '3 anos'}
  ];

  packageOptions: Option[];

  constructor(
    private notifier: NotifierService,
    private periodService: PeriodService,
    private packageService: PackageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.period = {
      id: '',
      title: '',
      months: 0,
      price: 0,
      package: '',
    };

    this.packageService.list(null, true, 'date', 'after').subscribe(
      response => {
        this.packages = response['data'];

        this.packageOptions = this.packages.map(item => {
          return {value: item.id, title: item.title};
        });


      }
    );

    this.periodForm.patchValue({
      months: 12
    });
  }

  public submit(): void {
    let periodData: PeriodFormData = {
      ...this.periodForm.value,
    };

    this.periodService.create(periodData).subscribe(
      response => {
        this.notifier.notify('success', 'Período criado com sucesso');

        this.router.navigate(['/periods/disabled/date/1']);
      },
      error => {
        this.notifier.notify('error', 'Erro na hora de criar os dados');
      }
    );
  }

  public changePeriod(entry: Option) {
    console.log(entry);
    this.periodForm.patchValue({
      months: entry.value
    });
  }

  public changePackage(entry: Option) {
    this.periodForm.patchValue({
      package: entry.value,
      title: entry.title
    });

  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {EbookListComponent} from './ebook-list/ebook-list.component';
import {EbookCreateComponent} from './ebook-create/ebook-create.component';
import {EbookEditComponent} from './ebook-edit/ebook-edit.component';

const routes: Routes = [
  {path: 'ebooks', component: EbookListComponent},
  {path: 'ebooks/add', component: EbookCreateComponent},
  {path: 'ebooks/edit/:id', component: EbookEditComponent},

  {path: 'ebooks/:page', component: EbookListComponent},
  {path: 'ebooks/:status/:page', component: EbookListComponent},
  {path: 'ebooks/:status/:sort/:page', component: EbookListComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EbookRoutingModule { }

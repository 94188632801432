import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {EditionArticleListComponent} from './edition-article-list/edition-article-list.component';
import {EditionArticleCreateComponent} from './edition-article-create/edition-article-create.component';
import {EditionArticleEditComponent} from './edition-article-edit/edition-article-edit.component';

const routes: Routes = [
  {path: 'edition_articles/add/:edition', component: EditionArticleCreateComponent},
  {path: 'edition_articles/edit/:id', component: EditionArticleEditComponent},

  {path: 'edition_articles/:edition', component: EditionArticleListComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditionArticleRoutingModule { }

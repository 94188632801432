import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { take } from 'rxjs/operators';
import { Option } from 'src/app/base/interfaces';
import { PackageService } from 'src/app/package.service';
import { Subscription } from 'src/app/shared/intefaces/subscription';
import { User } from 'src/app/shared/intefaces/user';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { UserService } from 'src/app/user.service';
import { AngularFireAuth } from '@angular/fire/auth';


@Component({
  selector: 'app-subscription-edit',
  templateUrl: './subscription-edit.component.html',
  styleUrls: ['./subscription-edit.component.scss']
})
export class SubscriptionEditComponent implements OnInit {
  subscription: Subscription;
  expiration: any;
  user: any;
  subscriptionForm = new FormGroup({
    userEmail: new FormControl('', [
      Validators.required,
    ]),
    package: new FormControl(''),
    address: new FormControl(''),
    cep: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    complement: new FormControl(''),
    number: new FormControl(''),
    neighborhood: new FormControl(''),
    price: new FormControl(''),
    status: new FormControl(''),
    months: new FormControl(''),
  });
  rawPackages = [];
  packages: Option[];
  statuses: Option[] = [
    {
      title: 'Em análise',
      value: '2'
    },
    {
      title: 'Pago',
      value: '3'
    },
    {
      title: 'Disponível',
      value: '4'
    },
    {
      title: 'Em Disputa',
      value: '5'
    },
    {
      title: 'Devolvida ',
      value: '6'
    },
    {
      title: 'Cancelada',
      value: '7'
    },
  ];
  months: Option[] = [
    {
      title: '12',
      value: '12'
    },
    {
      title: '18',
      value: '18'
    },
    {
      title: '24',
      value: '24'
    },
    {
      title: '30',
      value: '30'
    },
    {
      title: '36',
      value: '36'
    },
    {
      title: '48',
      value: '48'
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private userService: UserService,
    private packageService: PackageService,
    private notifier: NotifierService,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.getPackages();
    this.afAuth.authState.pipe(take(1)).subscribe(
      response => {

        this.route.params.subscribe(params => {
          this.subscriptionService.get(params['id']).pipe(take(1)).subscribe(entry => {
            this.subscription = entry.data();
            const expirationDate = new Date(this.subscription.expirationDate.toMillis());
            this.expiration = `${expirationDate.getDate()}/${expirationDate.getMonth()+1}/${expirationDate.getFullYear()}`;


            this.subscriptionForm.patchValue({
              userEmail: this.subscription.email,
              package: this.subscription.package.toString(),
              address: this.subscription.address,
              cep: this.subscription.cep,
              city: this.subscription.city,
              state: this.subscription.state,
              complement: this.subscription.complement,
              number: this.subscription.number,
              neighborhood: this.subscription.neighborhood,
              price: this.subscription.price,
              status: this.subscription.status.toString(),
              months: this.subscription.months.toString(),
            });


            this.userService.getByEmail(this.subscription.email).pipe(take(1)).subscribe(entry => {
              if (entry.docs.length > 0) {
                this.user = entry.docs[0].data();
              }
            });


          }, error => {

            this.notifier.notify('error', 'Erro ao carregar a assinatura');
            this.router.navigateByUrl('subscriptions');
          });
        });
      }
    );


  }

  private getPackages() {
    this.packageService.listActive().pipe(take(1)).subscribe(packages => {
      this.rawPackages = [];

      this.packages = packages.docs.map(doc => {
        let data = doc.data();
        this.rawPackages = [].concat(this.rawPackages, data);

        return {
          title: data['id'] + ' - ' + data['title'],
          value: data['id'],
        };
      });

      this.subscriptionForm.patchValue({
        package: this.packages[0].value,
        months: this.months[0].value,
      })
    });
  }

  submit() {
    if (!this.subscriptionForm.valid) {
      this.notifier.notify('error', 'Preencha todos os campos obrigatórios');

      return;
    }

    const choosenPackage = this.rawPackages.find(entry => entry['id'] == this.subscriptionForm.value.package);

    let data = {
      address: this.subscriptionForm.value.address,
      cep: this.subscriptionForm.value.cep,
      city: this.subscriptionForm.value.city,
      complement: this.subscriptionForm.value.complement,
      number: this.subscriptionForm.value.number,
      neighborhood: this.subscriptionForm.value.neighborhood,
      status: this.subscriptionForm.value.status,
      state: this.subscriptionForm.value.state,
      email: this.subscriptionForm.value.userEmail,
      package: this.subscriptionForm.value.package,
      magazines: choosenPackage['magazines'],
    };

    this.subscriptionService.update(this.subscription.id, data).then(() => {
      this.notifier.notify('success', 'Assinatura atualizada com sucesso');
      this.router.navigateByUrl('subscriptions');
    }).catch(error => {
      this.notifier.notify('error', 'Erro ao atualizar a assinatura');
    });

  }

}

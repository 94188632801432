import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ArticleFormData} from '../../base/interfaces';
import {AuthService} from '../../auth.service';
import {Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {

  loginForm = new FormGroup({
    email: new FormControl('', [
        Validators.required
    ]),
    password: new FormControl('', [
        Validators.required
    ]),
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private notifier: NotifierService
  ) { }

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/news']);
    }
  }

  public submit() {
    this.authService.signIn(this.loginForm.value.email, this.loginForm.value.password).then(result => {
      this.router.navigate(['/news']);
    }).catch(error => {
      this.notifier.notify('error', error);
    });
  }

}

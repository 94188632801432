<app-static-content-header (addEvent)="selectAdd()" (activeEvent)="selectActive()" (disabledEvent)="selectDisabled()" (searchEvent)="search($event)" (sortEvent)="selectSort($event)"></app-static-content-header>

<ng-container *ngFor="let article of articles">
  <app-entry-item
    (deleteEvent)="delete($event)"
    [date]="article.date | date:'dd/MM/YYYY'"
    [id]="article.id"
    editRoute="articles"
    [title]="article.title">
      <app-publish-action (publishEvent)="togglePublish($event)" [id]="article.id" [published]="article.published"></app-publish-action>

      <app-item-action
        (click)="toggleHighlight(article)"
        [checkCondition]="article.highlight"
        disabledText="Destacar"
        enabledText="Tirar destaque" ></app-item-action>
        <app-item-action
        (click)="toggleNewsletter(article)"
        [checkCondition]="article.newsletter"
        disabledText="Enviar para Newsletter"
        enabledText="Tirar da Newsletter" ></app-item-action>
  </app-entry-item>
</ng-container>

<div class="pagination">
  <a class="pagination__link pagination__link--previous" (click)="prevPage()">Anterior</a>
  <a class="pagination__link pagination__link--next" (click)="nextPage()">Próximo</a>
</div>

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { finalize } from 'rxjs/operators';
import { UploadService } from '../../../upload.service';
import { v4 as uuidv4 } from 'uuid';
import { AnuarioService } from 'src/app/shared/services/anuario.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-anuario-create',
  templateUrl: './anuario-create.component.html',
  styleUrls: ['./anuario-create.component.scss']
})
export class AnuarioCreateComponent implements OnInit {
  anuarioForm = new FormGroup({
    brand: new FormControl('', [
      Validators.required
    ]),
    model: new FormControl('', [
      Validators.required
    ]),
    cv: new FormControl('', [
      Validators.required
    ]),
    engineModel: new FormControl(''),
    engineBrand: new FormControl(''),
    rpm: new FormControl(''),
    torque: new FormControl(''),
    reference: new FormControl(''),
    cylinders: new FormControl(''),
    volume: new FormControl(''),
    aspiration: new FormControl(''),
    injection: new FormControl(''),
    transmissionModel: new FormControl(''),
    transmissionType: new FormControl(''),
    frontRear: new FormControl(''),
    reverse: new FormControl(''),
    clutch: new FormControl(''),
    clutchTrigger: new FormControl(''),
    traction: new FormControl(''),
    differentialLock: new FormControl(''),
    tdpRpm: new FormControl(''),
    tdpTrigger: new FormControl(''),
    hydraulicFlow: new FormControl(''),
    hydraulicPressure: new FormControl(''),
    remoteControlValves: new FormControl(''),
    remoteControlFlow: new FormControl(''),
    liftCapacity: new FormControl(''),
    height: new FormControl(''),
    betweenAxles: new FormControl(''),
    freeSpace: new FormControl(''),
    turnRadius: new FormControl(''),
    weight: new FormControl(''),
    tankCapacity: new FormControl(''),
    maxSpeed: new FormControl(''),
    operationStep: new FormControl(''),
    optionals: new FormControl(''),
    slug: new FormControl(''),
  });
  image: string = '';

  constructor(
    private notifier: NotifierService,
    private uploadService: UploadService,
    private anuarioService: AnuarioService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  submit(): void {
    const id = uuidv4();

    let data = {
      ...this.anuarioForm.value,
      id,
      image: this.image,
      cv: Number(this.anuarioForm.value.cv),
      cylinders: Number(this.anuarioForm.value.cylinders),
      date: new Date(),
      published: true,
    }

    this.anuarioService.create(id, data).then(() => {
      this.notifier.notify('success', 'Trator criado com sucesso');

      this.router.navigateByUrl('/anuario/tractors');
    }).catch((error) => {
      console.log(error);

      this.notifier.notify('error', 'Não foi possível criar o trator');
    });

  }

  public deleteImage(): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    this.image = '';
  }

  public uploadImage(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService.uploadFirebase(file, filePath).snapshotChanges().pipe(
      finalize(() => {
        this.image = filePath;

        this.notifier.notify('success', 'Imagem enviada com sucesso');
      })
    ).subscribe();


  }


}

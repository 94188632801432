import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HighlightEvent} from '../../base/interfaces';

@Component({
  selector: 'app-news-actions',
  templateUrl: './news-actions.component.html',
  styleUrls: ['./news-actions.component.scss']
})
export class NewsActionsComponent implements OnInit {
  @Input() id: any;
  @Input() published: boolean;
  @Input() highlightHome: boolean;
  @Input() highlightNews: boolean;

  @Output() highlightHomeEvent = new EventEmitter<HighlightEvent>();
  @Output() highlightNewsEvent = new EventEmitter<HighlightEvent>();

  constructor() { }

  ngOnInit(): void {
  }

  public toggleHighlightNews(): void {
    this.highlightNewsEvent.emit({
      id: this.id,
      status: this.highlightNews,
      type: 'news'
    });
  }

  public toggleHighlightHome(): void {
    this.highlightHomeEvent.emit({
      id: this.id,
      status: this.highlightHome,
      type: 'home'
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { User } from '../user-list/user-list.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { UserService } from '../../user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserEditFormData, UserFormData } from '../../base/interfaces';
import { AngularFireAuth } from '@angular/fire/auth';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  user: any;
  permissions = [
    'Notícias', 'Agenda', 'Artigos', 'Banners', 'Pedidos', 'Pacotes', 'Períodos', 'Revistas',
    'Ebooks', 'Wallpapers'
  ];

  userForm = new FormGroup({
    name: new FormControl('', [
      Validators.required
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
  });

  private sub: any;

  constructor(
    private notifier: NotifierService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.afAuth.authState.pipe(take(1)).subscribe(
        user => {

          const id = params.id;

          this.userService.get(id).subscribe(
            response => {
              this.user = response.data()

              this.userForm.patchValue({
                name: this.user.name,
                email: this.user.email
              });

            },
            error => {
              this.notifier.notify('error', error.message);
            });
        }
      )
    });
  }

  public submit() {
    let userData: any = {
      name: this.userForm.value.name,
    };

    this.userService.update(this.user.id, userData).then(() => {
        this.notifier.notify('success', 'Usuário atualizado com sucesso');

        this.router.navigate(['/users']);
    }).catch(error => {
        this.notifier.notify('error', 'Erro na hora de atualizar o usuário');
    });


  }

}

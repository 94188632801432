<form (ngSubmit)="submit()" [formGroup]="anuarioForm" action="" class="form">
  <div class="form__left">
    <app-button [enabled]="anuarioForm.valid" text="Adicionar" type="submit"></app-button>
    <app-input [control]="anuarioForm.get('brand')" label="Marca"></app-input>
    <app-input [control]="anuarioForm.get('model')" label="Modelo"></app-input>


    <h2 class="form__separator">Motor</h2>
    <div class="form__container">
      <app-input [control]="anuarioForm.get('engineBrand')" label="Marca"></app-input>
      <app-input [control]="anuarioForm.get('engineModel')" label="Modelo"></app-input>
      <app-input [control]="anuarioForm.get('cv')" label="Potência (cv)"></app-input>
      <app-input [control]="anuarioForm.get('rpm')" label="Rotação (rpm)"></app-input>
      <app-input [control]="anuarioForm.get('torque')" label="Torque (N.m) - Rotação (rpm)"></app-input>
      <app-input [control]="anuarioForm.get('reference')" label="Norma de Referência"></app-input>
      <app-input [control]="anuarioForm.get('cylinders')" label="Cilindros (unidades)"></app-input>
      <app-input [control]="anuarioForm.get('volume')" label="Volume (cm³)"></app-input>
      <app-input [control]="anuarioForm.get('aspiration')" label="Aspiração"></app-input>
      <app-input [control]="anuarioForm.get('injection')" label="Injeção"></app-input>
    </div>


    <h2 class="form__separator">Transmissão</h2>
    <div class="form__container">
      <app-input [control]="anuarioForm.get('transmissionModel')" label="Modelo"></app-input>
      <app-input [control]="anuarioForm.get('transmissionType')" label="Tipo"></app-input>
      <app-input [control]="anuarioForm.get('frontRear')" label="Frente x Ré"></app-input>
      <app-input [control]="anuarioForm.get('reverse')" label="Reversor"></app-input>
      <app-input [control]="anuarioForm.get('clutch')" label="Embreagem tipo"></app-input>
      <app-input [control]="anuarioForm.get('clutchTrigger')" label="Acionamento embreagem"></app-input>
      <app-input [control]="anuarioForm.get('traction')" label="Tração"></app-input>
      <app-input [control]="anuarioForm.get('differentialLock')" label="Bloqueio do diferencial"></app-input>
    </div>

  </div>
  <div class="form__right">
    <app-image-upload
      (deleteEvent)="deleteImage()"
      (uploadEvent)="uploadImage($event)"
      [image]="image"
    ></app-image-upload>

    <h2 class="form__separator">Sistema Hidráulico</h2>
    <div class="form__container">
      <app-input [control]="anuarioForm.get('tdpRpm')" label="TDP (rpm)"></app-input>
      <app-input [control]="anuarioForm.get('tdpTrigger')" label="TDP (acionamento)"></app-input>
      <app-input [control]="anuarioForm.get('hydraulicFlow')" label="Vazão do hidráulico (L/min)"></app-input>
      <app-input [control]="anuarioForm.get('hydraulicPressure')" label="Pressão do hidráulico (bar)"></app-input>
      <app-input [control]="anuarioForm.get('remoteControlValves')" label="Válvulas controle remoto (Nº)"></app-input>
      <app-input [control]="anuarioForm.get('remoteControlFlow')" label="Vazão controle remoto (L/min)"></app-input>
      <app-input [control]="anuarioForm.get('liftCapacity')" label="Capacidade levante (kgf)"></app-input>
    </div>

    <h2 class="form__separator">Dimensões</h2>
    <div class="form__container">
      <app-input [control]="anuarioForm.get('height')" label="Altura (mm)"></app-input>
      <app-input [control]="anuarioForm.get('betweenAxles')" label="Entre eixos (mm)"></app-input>
      <app-input [control]="anuarioForm.get('freeSpace')" label="Vão livre (mm)"></app-input>
      <app-input [control]="anuarioForm.get('turnRadius')" label="Raio de giro (mm)"></app-input>
      <app-input [control]="anuarioForm.get('weight')" label="Peso (kg)"></app-input>
      <app-input [control]="anuarioForm.get('tankCapacity')" label="Tanque (L)"></app-input>
      <app-input [control]="anuarioForm.get('maxSpeed')" label="Vel. Máxima (km/h)"></app-input>
      <app-input [control]="anuarioForm.get('operationStep')" label="Posto de Operação"></app-input>
    </div>

    <app-input [control]="anuarioForm.get('optionals')" label="Opcionais" type="textarea"></app-input>

  </div>

</form>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CalendarListComponent} from './calendar-list/calendar-list.component';
import {CalendarCreateComponent} from './calendar-create/calendar-create.component';
import {CalendarEditComponent} from './calendar-edit/calendar-edit.component';

const routes: Routes = [
  {path: 'calendar', component: CalendarListComponent},
  {path: 'calendar/add', component: CalendarCreateComponent},
  {path: 'calendar/edit/:id', component: CalendarEditComponent},

  {path: 'calendar/:page', component: CalendarListComponent},
  {path: 'calendar/:status/:page', component: CalendarListComponent},
  {path: 'calendar/:status/:sort/:page', component: CalendarListComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalendarRoutingModule { }

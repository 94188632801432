<app-static-content-header (addEvent)="selectAdd()" (activeEvent)="selectActive()" (disabledEvent)="selectDisabled()" [showSort]="false"></app-static-content-header>

<ng-container *ngFor="let event of events">
  <app-entry-item
    (deleteEvent)="delete($event)"
    [date]="event.start_date"
    [id]="event.id"
    [title]="event.title"
    editRoute="calendar">
    <app-publish-action  (publishEvent)="togglePublish($event)" [id]="event.id" [published]="event.published"></app-publish-action>

  </app-entry-item>
</ng-container>

<div class="pagination">
  <a class="pagination__link pagination__link--previous" (click)="prevPage()">Anterior</a>
  <a class="pagination__link pagination__link--next" (click)="nextPage()">Próximo</a>
</div>

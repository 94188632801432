import { Component, Input, OnInit } from '@angular/core';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Image from '@editorjs/image';
import Hyperlink from 'editorjs-hyperlink';
import Delimiter from '@editorjs/delimiter';
import Embed from '@editorjs/embed';
import { UploadService } from 'src/app/upload.service';
import { v4 as uuidv4 } from 'uuid';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-editorjs',
  templateUrl: './editorjs.component.html',
  styleUrls: ['./editorjs.component.scss']
})
export class EditorjsComponent implements OnInit {
  @Input() data: any;
  @Input() identifier = 'editor-js';
  editor: any;

  constructor(private uploadService: UploadService) { }

  ngOnInit(): void {
    const blocks = (this.data === undefined) ? undefined : JSON.parse(this.data);
    const url = 'https://manager.quent.al/v1';
    const self = this;
    this.editor = new EditorJS({

      holder: this.identifier,
      inlineToolbar: ['hyperlink', 'bold', 'italic'],
      tools: {
        hyperlink: {
          class: Hyperlink,
          config: {
            shortcut: 'CMD+L',
            target: '_blank',
            rel: 'nofollow',
            availableTargets: ['_blank', '_self'],
            availableRels: ['author', 'noreferrer'],
            validate: false,
          }
        },
        heading: {
          class: Header,
          inlineToolbar: ['hyperlink']
        },
        list: {
          class: List,
          inlineToolbar: ['hyperlink', 'bold', 'italic']
        },
        embed: Embed,
        image: {
          class: Image,
          config: {
            uploader: {
              uploadByFile(file) {
                const extension = file.name.split('.').pop();
                const filePath = `${uuidv4()}.${extension}`;
                return new Promise((resolve, reject) => {

                  self.uploadService.uploadFirebase(file, filePath).snapshotChanges().toPromise().then(url => {

                    resolve({
                      success: 1,
                      file: {
                        url: `https://storage.googleapis.com/images-cultivar/${filePath}`
                      }
                    });
                  }).catch(error => {
                    reject(error);
                  });
                });
              }
            }
          }
        },
        delimiter: {
          class: Delimiter,
        }
      },
      data: blocks,
      placeholder: 'Escreva seu conteúdo aqui!',
    });
  }

  public save(): Promise<{}> {
    return this.editor.save();
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {BannerHomeSelectionComponent} from './banner-home-selection/banner-home-selection.component';
import {BannerInternalSelectionComponent} from './banner-internal-selection/banner-internal-selection.component';
import {BannerListComponent} from './banner-list/banner-list.component';
import {BannerCreateComponent} from './banner-create/banner-create.component';
import {BannerEditComponent} from './banner-edit/banner-edit.component';
import { AuthGuard } from '../auth.guard';

const routes: Routes = [
  {path: 'banners', component: BannerHomeSelectionComponent, canActivate: [AuthGuard]},
  {path: 'banners-anuario', component: BannerInternalSelectionComponent, canActivate: [AuthGuard]},

  {path: 'banners/add/:position', component: BannerCreateComponent, canActivate: [AuthGuard]},
  {path: 'banners/edit/:id', component: BannerEditComponent, canActivate: [AuthGuard]},

  {path: 'banners/:position', component: BannerListComponent, canActivate: [AuthGuard]},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BannerRoutingModule { }

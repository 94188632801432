import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CalendarService} from '../../calendar.service';
import {NotifierService} from 'angular-notifier';
import {DatePipe} from '@angular/common';
import {PublishEvent} from '../../base/interfaces';
import {EventCalendar} from '../../shared/intefaces/event';
import {take} from 'rxjs/operators';

export interface CalendarEvent {
  id: string;
  title: string;
  link: string;
  location: string;
  slug: string;
  description: string;
  start_date: any;
  end_date: any;
  start_time: string;
  end_time: string;
  highlight: boolean;
  published: boolean;
}

@Component({
  selector: 'app-calendar-list',
  templateUrl: './calendar-list.component.html',
  styleUrls: ['./calendar-list.component.scss']
})
export class CalendarListComponent implements OnInit {
  entry: string;
  status: boolean = true;
  direction: string = 'after';

  events: EventCalendar[];

  constructor(private route: ActivatedRoute, private router: Router, private calendarService: CalendarService, private notifier: NotifierService) {
  }

  ngOnInit(): void {
    this.getList();
  }

  public getList(): void {
    const datepipe: DatePipe = new DatePipe('en-US');

    if (this.entry) {
      this.calendarService.getEvent(this.entry).pipe(take(1)).subscribe(
        response => {
          this.calendarService.list(response, this.status, this.direction).pipe(take(1)).subscribe(
            response => {
              this.events = response.docs.map(doc => {
                let data = doc.data();

                data['start_date'] = datepipe.transform(data.start_date.toDate(), 'dd/MM/YYYY');
                return data;
              });

            },
            error => {

              this.notifier.notify('error', 'Não foi possível carregar a lista.');
            }
          );

        },
        error => {
          this.notifier.notify('error', 'Não foi possível carregar a lista.');
        }
      );
    } else {
      console.log(this.status);
      this.calendarService.list(null, this.status, this.direction).pipe(take(1)).subscribe(
        response => {
          this.events = response.docs.map(doc => {
            let data = doc.data();

            data['start_date'] = datepipe.transform(data.start_date.toDate(), 'dd/MM/YYYY');
            return data;
          });


        },
        error => {
          console.log(error);
          this.notifier.notify('error', 'Não foi possível carregar a lista.');
        }
      );

    }
  }

  public togglePublish(data: PublishEvent): void {
    this.calendarService.togglePublish(data.id, data.published).then(() => {
      const message = (data.published) ? 'Evento despublicado com sucesso!' : 'Evento publicado com sucesso!';

      this.notifier.notify('success', message);

      this.getList();
    }).catch(() => {
      this.notifier.notify('error', 'Não foi possível atualizar o evento');
    });
  }

  public delete(data: any) {
    const confirmDelete = confirm('Tem certeza que deseja deletar o evento?');

    if (confirmDelete) {
      this.calendarService.delete(data).then(() => {
        this.notifier.notify('success', 'Evento deletado com sucesso');

        this.getList();
      }).catch(() => {
        this.notifier.notify('error', 'Não foi possível deletar o evento');
      });
    }
  }

  public prevPage(): void {
    this.entry = this.events[0].id;
    this.direction = 'before';

    this.getList();
    window.scrollTo(0, 0);
  }

  public nextPage(): void {
    this.entry = this.events[this.events.length-1].id;
    this.direction = 'after';

    this.getList();
    window.scrollTo(0, 0);
  }


  public selectAdd() {
    this.router.navigateByUrl('/calendar/add');
  }

  public selectDisabled(): void {
    this.status = false;
    this.getList();
  }

  public selectActive(): void {
    this.status = true;
    this.getList();
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditorjsComponent} from './editorjs/editorjs.component';
import { InputComponent } from './input/input.component';
import {ReactiveFormsModule} from '@angular/forms';
import { TagInputComponent } from './tag-input/tag-input.component';
import {TagInputModule} from 'ngx-chips';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ButtonComponent } from './button/button.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ContentHeaderComponent } from './content-header/content-header.component';
import {RouterModule} from '@angular/router';
import { EntryItemComponent } from './entry-item/entry-item.component';
import { PublishActionComponent } from './publish-action/publish-action.component';
import { ItemActionComponent } from './item-action/item-action.component';
import {NgxCurrencyModule} from 'ngx-currency';
import { StaticContentHeaderComponent } from './static-content-header/static-content-header.component';
import { StaticPaginationComponent } from './static-pagination/static-pagination.component';


@NgModule({
  declarations: [
    EditorjsComponent,
    InputComponent,
    TagInputComponent,
    ImageUploadComponent,
    ButtonComponent,
    FileUploadComponent,
    ContentHeaderComponent,
    EntryItemComponent,
    PublishActionComponent,
    ItemActionComponent,
    StaticContentHeaderComponent,
    StaticPaginationComponent,
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TagInputModule,
        RouterModule,
        NgxCurrencyModule
    ],
    exports: [
        EditorjsComponent,
        InputComponent,
        TagInputComponent,
        ImageUploadComponent,
        FileUploadComponent,
        ButtonComponent,
        ContentHeaderComponent,
        EntryItemComponent,
        PublishActionComponent,
        ItemActionComponent,
        StaticContentHeaderComponent
    ]
})
export class BaseModule {
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { GroupService } from 'src/app/services/group.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-group-create',
  templateUrl: './group-create.component.html',
  styleUrls: ['./group-create.component.scss']
})
export class GroupCreateComponent implements OnInit {
  groupForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
    ]),
    description: new FormControl(''),
  });

  constructor(private groupService: GroupService,
    private notifier: NotifierService,
    private router: Router) { }

  ngOnInit(): void {
  }

  submit() {
    const id = uuidv4()

    const groupData = {
      ...this.groupForm.value,
      id,
      published: true,
      users: []
    };

    this.groupService.saveGroup(groupData).then(response => {
      this.notifier.notify('success', 'Grupo criado com sucesso!');

      this.router.navigateByUrl('/groups');
    }).catch(error => {
      this.notifier.notify('error', 'Erro ao criar grupo!');
    });
  }

}

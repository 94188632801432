import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsService } from '../../news.service';
import { DatePipe } from '@angular/common';
import { NotifierService } from 'angular-notifier';
import { HighlightEvent, PublishEvent } from '../../base/interfaces';
import { News } from '../../shared/intefaces/news';
import { take } from 'rxjs/operators';

import algoliasearch from 'algoliasearch';
import { ThrowStmt } from '@angular/compiler';

const APP_ID = 'YK4T9FI4H5';
const ADMIN_KEY = '7825c838f024805a18dde0793169e861';

const client = algoliasearch(APP_ID, ADMIN_KEY);
const index = client.initIndex('news');

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
})
export class NewsListComponent implements OnInit {
  entry: string;
  status: string = 'active';
  sort: string = 'date';
  direction: string = 'after';
  searchTerm: string = '';

  news: News[];

  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private newService: NewsService,
    private notifier: NotifierService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getList(this.entry, this.status, this.sort, this.direction);
  }

  public getList(
    entry: string,
    status: string,
    sort: string,
    direction: string
  ): void {
    if (entry != null) {
      this.newService
        .getById(entry)
        .pipe(take(1))
        .subscribe((news) => {
          this.newService
            .list(news, status === 'active', sort, direction)
            .subscribe((response) => {
              if (response.docs.length > 0) {
                this.news = response.docs.map((newsItem) => {
                  const data = newsItem.data();

                  return {
                    ...data,
                    date:
                      data.date == null
                        ? new Date()
                        : new Date(data.date.toMillis()),
                  };
                });
              }
            });
        });
    } else {
      this.newService
        .list(null, status === 'active', sort, direction)
        .pipe(take(1))
        .subscribe((response) => {
          if (response.docs.length > 0) {
            this.news = response.docs.map((newsItem) => {
              const data = newsItem.data();
              return {
                ...data,
                date:
                  data.date == null
                    ? new Date()
                    : new Date(data.date.toMillis()),
              };
            });
          }
        });
    }
  }

  public togglePublish(data: PublishEvent): void {
    this.newService
      .togglePublish(data.id.toString(), data.published)
      .then((response) => {
        const message = data.published
          ? 'Notícia despublicada com sucesso!'
          : 'Notícia publicada com sucesso!';

        this.notifier.notify('success', message);

        this.getList(this.entry, this.status, this.sort, this.direction);
      })
      .catch((error) => {
        this.notifier.notify(
          'error',
          'Não foi possível alterar o status da notícia'
        );
      });
  }

  public delete(data: any) {
    const confirmDelete = confirm('Tem certeza que deseja deletar a notícia?');

    if (confirmDelete) {
      this.newService
        .delete(data.toString())
        .then((response) => {
          this.notifier.notify('success', 'Notícia deletada com sucesso!');
          this.getList(null, this.status, this.sort, this.direction);
        })
        .catch((error) => {
          this.notifier.notify('error', 'Não foi possível deletar a notícia');
        });
    }
  }

  public toggleHighlight(data: HighlightEvent): void {
    this.newService
      .toggleHighlight(data.id.toString(), data.type, data.status)
      .then((response) => {
        this.news = this.news.map((newsItem) => {
          if (newsItem.id === data.id) {
            if (data.type === 'news') {
              newsItem.highlight_news = !data.status;
            }

            if (data.type === 'home') {
              newsItem.highlight_home = !data.status;
            }
          }

          return newsItem;
        });
      })
      .catch((error) => {
        console.log(error);
        this.notifier.notify(
          'error',
          'Não foi possível alterar o status da notícia'
        );
      });
  }

  public toggleNewsletter(data: News) {
    this.newService
      .toggleNewsletter(data.id.toString(), data.newsletter)
      .then((response) => {
        this.news = this.news.map((newsItem) => {
          if (newsItem.id === data.id) {
            newsItem.newsletter = !data.newsletter;
          }

          return newsItem;
        });
      });
  }

  public nextPage() {
    this.getList(
      this.news[this.news.length - 1].id.toString(),
      this.status,
      this.sort,
      'after'
    );
    window.scrollTo(0, 0);
  }

  public prevPage() {
    this.getList(this.news[0].id.toString(), this.status, this.sort, 'before');
    window.scrollTo(0, 0);
  }

  public selectActive() {
    this.status = 'active';

    if (this.searchTerm && this.searchTerm != '') {
      return this.search(this.searchTerm);
    }

    this.getList(null, this.status, this.sort, this.direction);
  }

  public selectDisabled() {
    this.status = 'disabled';

    if (this.searchTerm && this.searchTerm != '') {
      return this.search(this.searchTerm);
    }

    this.getList(null, this.status, this.sort, this.direction);
  }

  public selectSort(sort: string) {
    this.sort = sort;

    this.getList(null, this.status, this.sort, this.direction);
  }

  public selectAdd() {
    this.router.navigate(['/news/add']);
  }

  public search(term) {
    this.searchTerm = term;

    if (this.searchTerm == '') {
      this.getList(null, this.status, this.sort, this.direction);
    }

    index
      .search(term, { filters: `published:${this.status == 'active'}` })
      .then(({ hits }) => {
        this.news = hits.map((entry) => {
          const data = entry as any;

          return {
            title: data.title,
            published: data.published,
            highlight_home: data.highlight_home,
            highlight_news: data.highlight_news,
            newsletter: data.newsletter ? data.newsletter : false,
            id: data.objectID,
            date: new Date(data.date),
          } as any;
        });
      });
  }
}

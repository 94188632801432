import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { take } from 'rxjs/operators';
import { Option } from 'src/app/base/interfaces';
import { PackageService } from 'src/app/package.service';
import { Package } from 'src/app/shared/intefaces/package';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { UserService } from 'src/app/user.service';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';

@Component({
  selector: 'app-subscription-create',
  templateUrl: './subscription-create.component.html',
  styleUrls: ['./subscription-create.component.scss']
})
export class SubscriptionCreateComponent implements OnInit {
  subscriptionForm = new FormGroup({
    userEmail: new FormControl('', [
      Validators.required,
    ]),
    package: new FormControl(''),
    address: new FormControl(''),
    cep: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    complement: new FormControl(''),
    number: new FormControl(''),
    neighborhood: new FormControl(''),
    price: new FormControl(''),
    status: new FormControl(''),
    months: new FormControl(''),
  });
  rawPackages = [];
  packages: Option[];
  statuses: Option[] = [
    {
      title: 'Em análise',
      value: '2'
    },
    {
      title: 'Pago',
      value: '3'
    },
    {
      title: 'Disponível',
      value: '4'
    },
    {
      title: 'Em Disputa',
      value: '5'
    },
    {
      title: 'Devolvida ',
      value: '6'
    },
    {
      title: 'Cancelada',
      value: '7'
    },
  ];
  months: Option[] = [
    {
      title: '12',
      value: '12'
    },
    {
      title: '18',
      value: '18'
    },
    {
      title: '24',
      value: '24'
    },
    {
      title: '30',
      value: '30'
    },
    {
      title: '36',
      value: '36'
    },
    {
      title: '48',
      value: '48'
    }
  ];

  constructor(private packageService: PackageService,
    private notifier: NotifierService,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private router: Router) { }

  ngOnInit(): void {
    this.getPackages();
    this.subscriptionForm.patchValue({
      status: '2'
    });
  }

  private getPackages() {
    this.packageService.listActive().pipe(take(1)).subscribe(packages => {
      this.rawPackages = [];

      this.packages = packages.docs.map(doc => {
        let data = doc.data();
        this.rawPackages = [].concat(this.rawPackages, data);

        return {
          title: data['id'] + ' - ' + data['title'],
          value: data['id'],
        };
      });

      this.subscriptionForm.patchValue({
        package: this.packages[0].value,
        months: this.months[0].value,
      })
    });
  }

  submit() {
    if (!this.subscriptionForm.valid) {
      this.notifier.notify('error', 'Preencha todos os campos obrigatórios');

      return;
    }



    const id = uuidv4();
    const today = new Date();
    const choosenPackage = this.rawPackages.find(entry => entry['id'] == this.subscriptionForm.value.package);
    let data = {
      ...this.subscriptionForm.value,
      id,
      email: this.subscriptionForm.value.userEmail,
      magazines: choosenPackage['magazines'],
      date: today,
      payDate: today,
      expirationDate: moment().add(this.subscriptionForm.value.months, 'months').toDate(),
      pagseguro: false
    };




    this.subscriptionService.save(id, data).then(() => {
      this.notifier.notify('success', 'Assinatura criada com sucesso');
      this.router.navigateByUrl('/subscriptions');
    }).catch(() => {
      this.notifier.notify('error', 'Erro ao criar assinatura');
    });

  }

}

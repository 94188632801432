import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GroupRoutingModule } from './group-routing.module';
import { GroupListComponent } from './group-list/group-list.component';
import { BaseModule } from '../base/base.module';
import { GroupCreateComponent } from './group-create/group-create.component';
import { ReactiveFormsModule } from '@angular/forms';
import { GroupEditComponent } from './group-edit/group-edit.component';
import { UserListComponent } from './user-list/user-list.component';


@NgModule({
  declarations: [GroupListComponent, GroupCreateComponent, GroupEditComponent, UserListComponent],
  imports: [
    CommonModule,
    GroupRoutingModule,
    BaseModule,
    ReactiveFormsModule
  ]
})
export class GroupModule { }

<form (ngSubmit)="submit()" [formGroup]="newsForm" action="" class="news-form">
  <div class="news-form__left">
    <app-button [enabled]="newsForm.valid" text="Atualizar" type="submit"></app-button>
    <app-input label="Título" [control]="newsForm.get('title')"></app-input>
    <app-input label="Slug" [control]="newsForm.get('slug')"></app-input>
    <app-input [control]="newsForm.get('author')" label="Autor"></app-input>
    <app-input label="Chamada" type="textarea" [control]="newsForm.get('call')"></app-input>

    <app-tag-input [control]="newsForm.get('tags')"></app-tag-input>


  </div>

  <div class="news-form__right">

    <app-image-upload
      (deleteEvent)="deleteImage()"
      (uploadEvent)="uploadImage($event)"
      [image]="image"
    ></app-image-upload>

    <app-file-upload
      (deleteEvent)="deleteFile()"
      (uploadEvent)="uploadFile($event)"
      [filePdf]="file"
    ></app-file-upload>

  </div>

</form>
<div class="news-form__content-title">Conteúdo</div>
<app-editorjs *ngIf="description == ''"></app-editorjs>
<app-editorjs *ngIf="description != ''" [data]="description"></app-editorjs>


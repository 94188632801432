import { Component, OnInit } from '@angular/core';
import {Wallpaper} from '../wallpaper-list/wallpaper-list.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {EbookService} from '../../ebook.service';
import {UploadService} from '../../upload.service';
import {Router} from '@angular/router';
import {WallpaperFormData} from '../../base/interfaces';
import {WallpaperService} from '../../wallpaper.service';
import { v4 as uuidv4 } from 'uuid';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-wallpaper-create',
  templateUrl: './wallpaper-create.component.html',
  styleUrls: ['./wallpaper-create.component.scss']
})
export class WallpaperCreateComponent implements OnInit {
  wallpaper: any;
  smallImage = '';
  mediumImage = '';
  largeImage = '';

  wallpaperForm = new FormGroup({
    title: new FormControl('', [
      Validators.required
    ]),
    date: new FormControl(''),
  });

  constructor(
    private notifier: NotifierService,
    private wallpaperService: WallpaperService,
    private uploadService: UploadService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public deleteImage(type: string): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    switch (type) {
      case 'small':
        this.smallImage = '';
        break;
      case 'medium':
        this.mediumImage = '';
        break;
      case 'large':
        this.largeImage = '';
        break;
    }
  }

  public uploadImage(type: string, file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;


    this.uploadService.uploadFirebase(file, filePath).snapshotChanges().pipe(
      finalize(() => {
        switch (type) {
          case 'small':
            this.smallImage = filePath;
            break;
          case 'medium':
            this.mediumImage = filePath;
            break;
          case 'large':
            this.largeImage = filePath;
            break;
        }


        this.notifier.notify('success', 'Imagem enviada com sucesso');
     })
    ).subscribe();
  }

  public submit() {
    const id = uuidv4();

    let wallpaperData: WallpaperFormData = {
      ...this.wallpaperForm.value,
      id,
      imageSmall: this.smallImage,
      imageMedium: this.mediumImage,
      imageLarge: this.largeImage,
      date: moment(this.wallpaperForm.value.date).toDate(),
      published: false
    };


    this.wallpaperService.create(id, wallpaperData).then(() => {
        this.notifier.notify('success', 'Wallpaper criado com sucesso');

        this.router.navigate(['/wallpapers']);
    }).catch(error => {
        this.notifier.notify('error', 'Erro na hora de criar os dados');
    });

  }
}

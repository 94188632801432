<app-static-content-header (addEvent)="selectAdd()" (activeEvent)="selectActive()" (disabledEvent)="selectDisabled()" [showSort]="false"></app-static-content-header>

<ng-container *ngFor="let article of editionArticles">
  <app-entry-item
    (deleteEvent)="delete($event)"
    [date]="article.index"
    [id]="article.id"
    [title]="article.title"
    editRoute="edition_articles">
    <app-publish-action (publishEvent)="togglePublish($event)" [id]="article.id" [published]="article.published"></app-publish-action>

  </app-entry-item>
</ng-container>

<div class="pagination">
  <a class="pagination__link pagination__link--previous" (click)="prevPage()">Anterior</a>
  <a class="pagination__link pagination__link--next" (click)="nextPage()">Próximo</a>
</div>

import {Component, OnInit} from '@angular/core';
import {Option, PublishEvent} from '../../base/interfaces';
import {ActivatedRoute, Router} from '@angular/router';
import {EditionService} from '../../edition.service';
import {NotifierService} from 'angular-notifier';
import {take} from 'rxjs/operators';
import {Edition} from '../../shared/intefaces/edition';

@Component({
  selector: 'app-edition-list',
  templateUrl: './edition-list.component.html',
  styleUrls: ['./edition-list.component.scss']
})
export class EditionListComponent implements OnInit {
  entry: Edition;
  magazine: string = 'grandes-culturas';
  status: boolean = true;
  sort: string = 'number';
  direction: string = 'after';
  sortOptions: Option[] = [
    {
      value: 'title', title: 'Título'
    },
    {
      value: 'number', title: 'Número'
    }
  ];

  editions: Edition[];

  constructor(
    private route: ActivatedRoute,
    private editionService: EditionService,
    private notifier: NotifierService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.getList();
  }

  public selectAdd(): void {
    this.router.navigateByUrl(`/editions/add/${this.magazine}`);
  }

  public getList(): void {
    if (this.entry) {

    } else {
      this.editionService.list(null, this.magazine, this.status, this.sort, this.direction).pipe(take(1)).subscribe(
        docs => {
          this.editions = docs.docs.map(doc => doc.data());
        },
        error => {
          this.notifier.notify('error', 'Não foi possível carregar as edições');
        }
      );
    }
  }

  public selectActive(): void {
    this.status = true;
    this.getList();
  }

  public selectDisabled(): void {
    this.status = false;
    this.getList();
  }

  public selectMagazine(magazine: string): void {
    this.magazine = magazine;
    this.getList();
  }

  public delete(data: any) {
    const confirmDelete = confirm('Tem certeza que deseja deletar a edição?');

    if (confirmDelete) {
      this.editionService.delete(data).then(
        () => {
          this.notifier.notify('success', 'Edição deletada com sucesso');

          this.getList();
        },
        () => {
          this.notifier.notify('error', 'Não foi possível deletar a edição');
        }
      );
    }
  }

  public togglePublish(data: PublishEvent): void {
    this.editionService.togglePublish(data.id, data.published).then(
      () => {
        const message = (data.published) ? 'Edição despublicada com sucesso!' : 'Edição publicada com sucesso!';
        this.notifier.notify('success', message);

        this.getList();
      },
      () => {
        this.notifier.notify('error', 'Não foi possível atualizar a edição');
      }
    );
  }

  public toggleHighlight(edition: Edition) {
    this.editionService.toggleHighlight(edition.id, edition.highlight).then(
      () => {
        this.notifier.notify('success', 'Edição destacado com sucesso');

        this.editions = this.editions.map(item => {
          if (item.id === edition.id) {
            item.highlight = !edition.highlight;
          }

          return item;
        });
      },
      (error) => {
        this.notifier.notify('error', 'Ocorreu um erro ao destacar a edição');
      }
    );

  }

}



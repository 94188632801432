import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL, ArticleFormData, EbookFormData} from './base/interfaces';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class EbookService {
  url = `${API_URL}ebook`;

  constructor(private http: HttpClient, private auth: AuthService) { }

  get options(): any {
    return {
      'headers': {
        'Authorization': `Bearer${this.auth.token}`
      }
    };
  }

  public list(page: number, status: string, sort: string) {
    return this.http.get(`${this.url}/paginate/${status}/${sort}/${page}`, this.options);
  }

  public getEbook(id: string) {
    return this.http.get(`${this.url}/get/${id}`, this.options);
  }

  public create(data: EbookFormData) {
    return this.http.post(`${this.url}/create`, data, this.options);
  }

  public update(id:string, data: EbookFormData) {
    return this.http.post(`${this.url}/edit/${id}`, data, this.options);
  }

  public delete(id: string) {
    return this.http.post(`${this.url}/delete/${id}`, {}, this.options);
  }

  public togglePublish(id: string, published: boolean) {
    const status = (published) ? 'disable' : 'active';

    return this.http.get(`${this.url}/publish/${status}/${id}`, this.options);
  }

}

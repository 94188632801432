import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewsRoutingModule } from './news-routing.module';
import { NewsFormComponent } from './news-form/news-form.component';
import {BaseModule} from '../base/base.module';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsEditComponent } from './news-edit/news-edit.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TagInputModule} from 'ngx-chips';
import {RouterModule} from '@angular/router';
import { NewsActionsComponent } from './news-actions/news-actions.component';


@NgModule({
  declarations: [NewsFormComponent, NewsListComponent, NewsEditComponent, NewsActionsComponent],
  imports: [
    CommonModule,
    NewsRoutingModule,
    NewsRoutingModule,
    BaseModule,
    ReactiveFormsModule,
    TagInputModule,
    FormsModule,
    RouterModule
  ],
  exports: [
    NewsFormComponent
  ]
})
export class NewsModule { }

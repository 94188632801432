import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NewsFormComponent} from './news-form/news-form.component';
import {NewsListComponent} from './news-list/news-list.component';
import {NewsEditComponent} from './news-edit/news-edit.component';
import {AuthGuard} from '../auth.guard';

const routes: Routes = [
  {path: 'news', component: NewsListComponent, canActivate: [AuthGuard]},
  {path: 'news/add', component: NewsFormComponent, canActivate: [AuthGuard]},
  {path: 'news/edit/:id', component: NewsEditComponent, canActivate: [AuthGuard]},


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewsRoutingModule { }

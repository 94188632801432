<form (ngSubmit)="submit()" [formGroup]="articleForm" action="" class="form">
  <div class="form__left">
    <app-button [enabled]="articleForm.valid" text="Adicionar" type="submit"></app-button>
    <app-input [control]="articleForm.get('title')" label="Título"></app-input>
    <app-input [control]="articleForm.get('slug')" label="Slug"></app-input>
    <app-input [control]="articleForm.get('issuu')" label="ISSUU"></app-input>
    <app-input [control]="articleForm.get('call')" label="Chamada" type="textarea"></app-input>

    <app-tag-input [control]="articleForm.get('tags')"></app-tag-input>

    <h2 class="form__separator">Fonte</h2>
    <div class="form__container">
      <app-input [control]="articleForm.get('sourceName')" label="Nome"></app-input>
      <app-input [control]="articleForm.get('sourceEmail')" label="E-mail"></app-input>
      <app-input [control]="articleForm.get('sourcePhone')" label="Telefone"></app-input>
      <app-input [control]="articleForm.get('sourceCompany')" label="Empresa"></app-input>
      <app-input [control]="articleForm.get('sourceComment')" label="Comentários"></app-input>
    </div>

  </div>

  <div class="form__right">
    <app-image-upload
      (deleteEvent)="deleteImage()"
      (uploadEvent)="uploadImage($event)"
      [image]="image"
    ></app-image-upload>

    <app-file-upload
      (deleteEvent)="deleteFile()"
      (uploadEvent)="uploadFile($event)"
      [filePdf]="file"
    ></app-file-upload>

  </div>

</form>
<div class="form__content-title">Conteúdo</div>
<app-editorjs></app-editorjs>

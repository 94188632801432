import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL, EditionArticleFormData, EditionFormData} from './base/interfaces';
import {AuthService} from './auth.service';
import {AngularFirestore, CollectionReference, Query} from '@angular/fire/firestore';
import {EditionArticle} from './shared/intefaces/edition-article';

@Injectable({
  providedIn: 'root'
})
export class EditionArticleService {
  url = `${API_URL}edition_article`;

  constructor(private http: HttpClient, private auth: AuthService, private firestore: AngularFirestore) { }

  get options(): any {
    return {
      'headers': {
        // 'Authorization': `Bearer${this.auth.token}`
      }
    };
  }

  public list(entry: any, edition: string, status: boolean, direction: string) {
    return this.firestore.collection<EditionArticle>('edition_articles', ref => {
      let query: CollectionReference | Query = ref;
      query = query.where('published', '==', status)
        .where('edition', '==', edition)
        .orderBy('index', 'desc');

      if (entry) {
        if (direction === 'after') {
          query = query.startAfter(entry).limit(10);
        } else {
          query = query.endBefore(entry).limitToLast(10).limit(10);
        }
      } else {
        query = query.limit(10);
      }

      return query;
    }).get();
  }

  public getEditionArticle(id: string) {
    return this.firestore.collection<EditionArticle>('edition_articles').doc(id).get();
  }

  public create(id: string, data: any) {
    return this.firestore.collection('edition_articles').doc(id).set(data);
  }

  public update(id:string, data: EditionArticleFormData) {
    return this.firestore.collection('edition_articles').doc(id).update(data);
  }

  public delete(id: string) {
    return this.firestore.collection('edition_articles').doc(id).delete();
  }

  public togglePublish(id: string, published: boolean) {
    const data = {
      published: !published
    };

    return this.firestore.collection('edition_articles').doc(id).update(data);
  }

  public toggleHighlight(id: string, currentStatus: boolean) {
    const status = (currentStatus) ? 'disable' : 'active';

    return this.http.get(`${this.url}/highlight/${status}/${id}`, this.options);
  }
}

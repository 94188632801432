import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NewsService } from '../../news.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UploadService } from '../../upload.service';
import { EditorjsComponent } from '../../base/editorjs/editorjs.component';
import { News } from 'src/app/shared/intefaces/news';
import { finalize, take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import slugify from 'slugify';

export interface NewsEditFormData {
  title: string;
  author: string;
  tags: string[] | string;
  slug: string;
  image: string;
  pdf: string;
  description: string;
}

@Component({
  selector: 'app-news-edit',
  templateUrl: './news-edit.component.html',
  styleUrls: ['./news-edit.component.scss'],
})
export class NewsEditComponent implements OnInit {
  private sub: any;

  news: any;
  image = '';
  file = '';
  description = '';

  @ViewChild(EditorjsComponent) editor: EditorjsComponent;

  newsForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    slug: new FormControl('', [Validators.required]),
    author: new FormControl(''),
    call: new FormControl('', [Validators.required]),
    tags: new FormControl(''),
  });

  constructor(
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private router: Router,
    private newsService: NewsService,
    private uploadService: UploadService
  ) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      if (params.id === undefined) {
        this.notifier.notify('error', 'Notícia não encontrada');

        this.router.navigate(['/news']);
      }

      const id = params.id;

      this.newsService
        .getById(id)
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.news = response.data();

            this.image = this.news.image;
            this.file = this.news.pdf;
            this.description = this.news.description;

            this.newsForm.patchValue({
              title: this.news.title,
              slug: this.news.slug,
              author: this.news.author,
              call: this.news.call,
              tags: this.news.tags.map((tag) => {
                return tag.title;
              }),
            });

            this.newsForm.get('title').valueChanges.subscribe((value) => {
              this.newsForm
                .get('slug')
                .setValue(slugify(value, { lower: true, strict: true }));
            });
          },
          (error) => {
            this.notifier.notify('error', error.message);
          }
        );
    });
  }

  public submit(): void {
    this.editor.save().then((data) => {
      let rawTags = this.newsForm.value.tags;

      if (rawTags == undefined || rawTags == '') {
        rawTags = [];
      }

      let tags = rawTags.map((tag) => {
        return {
          title: tag,
          slug: slugify(tag, { lower: true, strict: true }),
        };
      });

      let tagSlugs = rawTags.map((tag) => {
        return slugify(tag, { lower: true, strict: true });
      });

      let newsData: any = {
        ...this.newsForm.value,
        image: this.image,
        has_image: this.image != '',
        pdf: this.file,
        description: JSON.stringify(data),
        tags: tags,
        tag_slugs: tagSlugs,
      };

      if (newsData.tags === '') {
        newsData.tags = null;
      }

      this.newsService
        .update(this.news.id.toString(), newsData)
        .then((response) => {
          this.notifier.notify('success', 'Notícia atualizada com sucesso');

          this.router.navigate(['/news']);
        })
        .catch((error) => {
          this.notifier.notify('error', 'Erro na hora de atualizar os dados');
        });
    });
  }

  public deleteImage(): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    this.news.image = '';
    this.image = '';
  }

  public deleteFile(): void {
    this.notifier.notify('success', 'Arquivo removido com sucesso.');

    this.news.pdf = '';
    this.file = '';
  }

  public uploadImage(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService
      .uploadFirebase(file, filePath)
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.news.image = filePath;
          this.image = filePath;

          this.notifier.notify('success', 'Imagem enviada com sucesso');
        })
      )
      .subscribe();
  }

  public uploadFile(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService
      .uploadFirebase(file, filePath)
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.news.pdf = filePath;
          this.file = filePath;

          this.notifier.notify('success', 'Arquivo enviado com sucesso');
        })
      )
      .subscribe();
  }
}

<app-static-content-header (addEvent)="selectAdd()" (disabledEvent)="selectDisabled()" (activeEvent)="selectActive()" [showSort]="false">
  <a [ngClass]="{'content__header-status-filter--active': magazine == 'grandes-culturas'}"
     (click)="selectMagazine('grandes-culturas')"
     class="content__header-status-filter">Grandes
    Culturas</a>
  <a [ngClass]="{'content__header-status-filter--active': magazine == 'hortalicas-e-frutas'}"
     (click)="selectMagazine('hortalicas-e-frutas')"
     class="content__header-status-filter">Hortaliças
    e Frutas</a>
  <a [ngClass]="{'content__header-status-filter--active': magazine == 'maquinas'}"
     (click)="selectMagazine('maquinas')"
     class="content__header-status-filter">Máquinas</a>
</app-static-content-header>
<ng-container *ngFor="let edition of editions">
  <app-entry-item
    (deleteEvent)="delete($event)"
    [date]="edition.number"
    [id]="edition.id"
    [title]="edition.title"
    editRoute="editions">
    <app-publish-action (publishEvent)="togglePublish($event)" [id]="edition.id"
                        [published]="edition.published"></app-publish-action>

    <app-item-action
      (click)="toggleHighlight(edition)"
      [checkCondition]="edition.highlight"
      disabledText="Destacar"
      enabledText="Tirar destaque"></app-item-action>

    <a [routerLink]="['/edition_articles', edition.id]"
       class="list-item__action list-item__action--secondary">Matérias</a>

  </app-entry-item>
</ng-container>

import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {BannerService} from '../../banner.service';
import {UploadService} from '../../upload.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BannerFormData, EbookFormData} from '../../base/interfaces';
import { finalize } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-banner-create',
  templateUrl: './banner-create.component.html',
  styleUrls: ['./banner-create.component.scss']
})
export class BannerCreateComponent implements OnInit {
  image = '';
  type: string;
  position: string;

  bannerForm = new FormGroup({
    title: new FormControl('', [
        Validators.required
    ]),
    url: new FormControl('', [
        Validators.required
    ]),
  });

  private sub: any;

  constructor(
    private notifier: NotifierService,
    private bannerService: BannerService,
    private uploadService: UploadService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.position = params.position;
    });
  }

  public uploadImage(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService.uploadFirebase(file, filePath).snapshotChanges().pipe(
      finalize(() => {
        this.image = filePath;

        this.notifier.notify('success', 'Imagem enviada com sucesso');
      })
    ).subscribe();


  }

  public deleteImage(): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    this.image = '';
  }

  public submit() {
    const id = uuidv4();

    let bannerData: any = {
      ...this.bannerForm.value,
      image: this.image,
      position: this.position,
      id: id,
      published: true
    };

    this.bannerService.create(id, bannerData).then(response => {
        this.notifier.notify('success', 'Banner criado com sucesso');

        this.router.navigate([`/banners//${this.position}`]);
    }).catch(error => {
      this.notifier.notify('error', 'Erro ao criar banner');
    });
  }

}

<form (ngSubmit)="submit()" [formGroup]="userForm" action="" class="form">
  <div class="form__left">
    <app-button [enabled]="userForm.valid" text="Adicionar" type="submit"></app-button>
      <app-input [control]="userForm.get('email')" label="E-Mail"></app-input>
  </div>
</form>
<ng-container *ngFor="let user of users">
  <app-entry-item
    (deleteEvent)="delete($event)"
    [id]="user.email"
    editRoute="users"
    [showEdit]="false"
    [title]="user.email">

  </app-entry-item>
</ng-container>


import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { BannerService } from '../../banner.service';
import { UploadService } from '../../upload.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BannerFormData } from '../../base/interfaces';
import { finalize, take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-banner-edit',
  templateUrl: './banner-edit.component.html',
  styleUrls: ['./banner-edit.component.scss']
})
export class BannerEditComponent implements OnInit {
  banner: any;
  position: string;
  image = '';

  bannerForm = new FormGroup({
    title: new FormControl('', [
      Validators.required
    ]),
    url: new FormControl('', [
      Validators.required
    ]),
  });

  private sub: any;

  constructor(
    private notifier: NotifierService,
    private bannerService: BannerService,
    private uploadService: UploadService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      if (params.id === undefined) {
        this.notifier.notify('error', 'Banner não encontrado');

        this.router.navigate(['/banners']);
      }

      const id = params.id;

      this.bannerService.getById(id).pipe(take(1)).subscribe(
        response => {
          this.banner = response.data();

          this.bannerForm.patchValue({
            title: this.banner.title,
            url: this.banner.url,
          });

          this.image = this.banner.image;
          this.position = this.banner.position;

        },
        error => {
          console.log(error);
          this.notifier.notify('error', 'Não foi possível encontrar o banner.');
        });
    });
  }

  public uploadImage(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService.uploadFirebase(file, filePath).snapshotChanges().pipe(
      finalize(() => {
        this.image = filePath;

        this.notifier.notify('success', 'Imagem enviada com sucesso');
      })
    ).subscribe();


  }

  public deleteImage(): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    this.image = '';
  }

  public submit() {
    let bannerData: BannerFormData = {
      ...this.bannerForm.value,
      image: this.image,
    };


    this.bannerService.update(this.banner.id.toString(), bannerData).then(response => {
      this.notifier.notify('success', 'Banner atualizado com sucesso');

      this.router.navigate([`/banners/${this.position}`]);
    }).catch(error => {
      this.notifier.notify('error', 'Erro na hora de atualizar os dados');
    });

  }

}

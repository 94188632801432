import { Component, OnInit, ViewChild } from '@angular/core';
import { EditorjsComponent } from '../../base/editorjs/editorjs.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { UploadService } from '../../upload.service';
import { ArticleFormData } from '../../base/interfaces';
import { ArticleService } from '../../article.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Article } from 'src/app/shared/intefaces/article';
import { finalize, take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import slugify from 'slugify';

@Component({
  selector: 'app-article-edit',
  templateUrl: './article-edit.component.html',
  styleUrls: ['./article-edit.component.scss'],
})
export class ArticleEditComponent implements OnInit {
  article: any;

  @ViewChild(EditorjsComponent) editor: EditorjsComponent;
  articleForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    slug: new FormControl('', [Validators.required]),
    issuu: new FormControl(''),
    call: new FormControl('', [Validators.required]),
    sourceName: new FormControl(''),
    sourceComment: new FormControl(''),
    sourceEmail: new FormControl(''),
    sourcePhone: new FormControl(''),
    sourceCompany: new FormControl(''),
    tags: new FormControl(''),
  });

  image = '';
  file = '';
  description = '';

  private sub: any;

  constructor(
    private notifier: NotifierService,
    private uploadService: UploadService,
    private articleService: ArticleService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      if (params.id === undefined) {
        this.notifier.notify('error', 'Artigo não encontrado');

        this.router.navigate(['/articles']);
      }

      const id = params.id;

      this.articleService
        .getById(id)
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.article = response.data();

            this.articleForm.patchValue({
              title: this.article.title,
              slug: this.article.slug,
              call: this.article.call,
              issuu: this.article.issuu,
              tags: this.article.tags.map((tag) => {
                return tag.title;
              }),
              sourceName: this.article.sourceName,
              sourceEmail: this.article.sourceEmail,
              sourcePhone: this.article.sourcePhone,
              sourceCompany: this.article.sourceCompany,
              sourceComment: this.article.sourceComment,
            });

            this.image = this.article.image;
            this.file = this.article.pdf;
            this.description = this.article.description;

            this.articleForm.get('title').valueChanges.subscribe((value) => {
              this.articleForm.get('slug').setValue(
                slugify(value, {
                  lower: true,
                  strict: true,
                })
              );
            });
          },
          (error) => {
            this.notifier.notify('error', 'Erro ao buscar artigo');
            this.router.navigate(['/articles']);
          }
        );
    });
  }

  public uploadImage(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService
      .uploadFirebase(file, filePath)
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.article.image = filePath;
          this.image = filePath;

          this.notifier.notify('success', 'Imagem enviada com sucesso');
        })
      )
      .subscribe();
  }

  public uploadFile(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService
      .uploadFirebase(file, filePath)
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.article.pdf = filePath;
          this.file = filePath;

          this.notifier.notify('success', 'Arquivo enviado com sucesso');
        })
      )
      .subscribe();
  }

  public deleteImage(): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    this.image = '';
  }

  public deleteFile(): void {
    this.notifier.notify('success', 'Arquivo removido com sucesso.');

    this.file = '';
  }

  public submit() {
    this.editor.save().then((data) => {
      let rawTags = this.articleForm.value.tags;

      if (rawTags == undefined || rawTags == '') {
        rawTags = [];
      }

      let tags = rawTags.map((tag) => {
        return {
          title: tag,
          slug: slugify(tag, { lower: true, strict: true }),
        };
      });

      let tagSlugs = rawTags.map((tag) => {
        return slugify(tag, { lower: true, strict: true });
      });

      let articleData: any = {
        ...this.articleForm.value,
        image: this.image,
        has_image: this.image != '',
        pdf: this.file,
        description: JSON.stringify(data),
        tags: tags,
        tag_slugs: tagSlugs,
      };

      if (articleData.tags === '') {
        articleData.tags = null;
      }

      this.articleService
        .update(this.article.id.toString(), articleData)
        .then((response) => {
          this.notifier.notify('success', 'Artigo atualizado com sucesso');

          this.router.navigate(['/articles']);
        })
        .catch((error) => {
          this.notifier.notify('error', 'Erro ao atualizar o artigo');
        });
    });
  }
}

<form (ngSubmit)="submit()" [formGroup]="editionArticleForm" action="" class="form">
  <div class="form__left">
    <app-button [enabled]="editionArticleForm.valid" text="Adicionar" type="submit"></app-button>
    <app-input [control]="editionArticleForm.get('title')" label="Título"></app-input>
    <app-input [control]="editionArticleForm.get('slug')" label="Slug"></app-input>
    <app-input [control]="editionArticleForm.get('author')" label="Autor"></app-input>
    <app-input [control]="editionArticleForm.get('index')" label="Índice"></app-input>
    <app-input [control]="editionArticleForm.get('date')" label="Data" type="date"></app-input>

    <div class="form__content-title">Prévia</div>
    <app-editorjs #preview identifier="editor-preview"></app-editorjs>

  </div>
  <div class="form__right">
    <app-image-upload
      (deleteEvent)="deleteImage()"
      (uploadEvent)="uploadImage($event)"
      [image]="image"
    ></app-image-upload>

  </div>

</form>
<div class="form__content-title">Conteúdo</div>
<app-editorjs #content identifier="editor-content"></app-editorjs>

<form (ngSubmit)="submit()" [formGroup]="wallpaperForm" action="" class="form">
  <div class="form__left">
    <app-button [enabled]="wallpaperForm.valid" text="Atualizar" type="submit"></app-button>
    <app-input [control]="wallpaperForm.get('title')" label="Título"></app-input>
    <app-input [control]="wallpaperForm.get('date')" label="Data" type="date"></app-input>
  </div>

  <div class="form__right">
    <app-image-upload
      (deleteEvent)="deleteImage('small')"
      (uploadEvent)="uploadImage('small', $event)"
      [image]="smallImage"
      text="Adicionar Imagem - 1280x720"
    ></app-image-upload>

    <app-image-upload
      (deleteEvent)="deleteImage('medium')"
      (uploadEvent)="uploadImage('medium', $event)"
      [image]="mediumImage"
      text="Adicionar Imagem - 1680x1050"
    ></app-image-upload>

    <app-image-upload
      (deleteEvent)="deleteImage('large')"
      (uploadEvent)="uploadImage('large', $event)"
      [image]="largeImage"
      text="Adicionar Imagem - 1920x1080"
    ></app-image-upload>
  </div>

</form>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {EditionListComponent} from './edition-list/edition-list.component';
import {EditionCreateComponent} from './edition-create/edition-create.component';
import {EditionEditComponent} from './edition-edit/edition-edit.component';

const routes: Routes = [
  {path: 'editions', component: EditionListComponent},
  {path: 'editions/add/:magazine', component: EditionCreateComponent},
  {path: 'editions/edit/:id', component: EditionEditComponent},

  {path: 'editions/:magazine', component: EditionListComponent},
  {path: 'editions/:magazine/:page', component: EditionListComponent},
  {path: 'editions/:magazine/:status/:page', component: EditionListComponent},
  {path: 'editions/:magazine/:status/:sort/:page', component: EditionListComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditionRoutingModule { }

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-publish-action',
  templateUrl: './publish-action.component.html',
  styleUrls: ['./publish-action.component.scss']
})
export class PublishActionComponent implements OnInit {
  @Input() id: any;
  @Input() published: boolean;

  @Output() publishEvent = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  public publish(): void {
    this.publishEvent.emit({
      id: this.id,
      published: this.published
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EbookRoutingModule } from './ebook-routing.module';
import { EbookListComponent } from './ebook-list/ebook-list.component';
import { EbookCreateComponent } from './ebook-create/ebook-create.component';
import {ReactiveFormsModule} from '@angular/forms';
import {BaseModule} from '../base/base.module';
import { EbookEditComponent } from './ebook-edit/ebook-edit.component';


@NgModule({
  declarations: [EbookListComponent, EbookCreateComponent, EbookEditComponent],
  imports: [
    CommonModule,
    EbookRoutingModule,
    ReactiveFormsModule,
    BaseModule
  ]
})
export class EbookModule { }

import { Component, OnInit, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { UploadService } from '../../upload.service';
import { ArticleService } from '../../article.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ArticleFormData } from '../../base/interfaces';
import { EditorjsComponent } from '../../base/editorjs/editorjs.component';
import { Article } from 'src/app/shared/intefaces/article';
import { v4 as uuidv4 } from 'uuid';
import slugify from 'slugify';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'app-article-create',
  templateUrl: './article-create.component.html',
  styleUrls: ['./article-create.component.scss'],
})
export class ArticleCreateComponent implements OnInit {
  image: string = '';
  file: string = '';

  @ViewChild(EditorjsComponent) editor: EditorjsComponent;

  articleForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    slug: new FormControl('', [Validators.required]),
    issuu: new FormControl(''),
    call: new FormControl('', [Validators.required]),
    sourceName: new FormControl(''),
    sourceComment: new FormControl(''),
    sourceEmail: new FormControl(''),
    sourcePhone: new FormControl(''),
    sourceCompany: new FormControl(''),
    tags: new FormControl(''),
  });

  constructor(
    private notifier: NotifierService,
    private uploadService: UploadService,
    private articleService: ArticleService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.articleForm.get('title').valueChanges.subscribe((value) => {
      this.articleForm.get('slug').setValue(
        slugify(value, {
          lower: true,
          strict: true,
        })
      );
    });
  }

  public deleteImage(): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    this.image = '';
  }

  public deleteFile(): void {
    this.notifier.notify('success', 'Arquivo removido com sucesso.');

    this.file = '';
  }

  public uploadImage(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService
      .uploadFirebase(file, filePath)
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.image = filePath;

          this.notifier.notify('success', 'Imagem enviada com sucesso');
        })
      )
      .subscribe();
  }

  public uploadFile(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService
      .uploadFirebase(file, filePath)
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.file = filePath;

          this.notifier.notify('success', 'Arquivo enviado com sucesso');
        })
      )
      .subscribe();
  }

  public submit() {
    this.editor.save().then((data) => {
      this.articleService
        .getBySlug(this.articleForm.value.slug)
        .pipe(take(1))
        .subscribe((response) => {
          let entries = response.docs.map((doc) => doc.data());

          if (entries.length > 0) {
            this.notifier.notify('error', 'Slug já existe');
          } else {
            let rawTags = this.articleForm.value.tags;

            if (rawTags == undefined || rawTags == '') {
              rawTags = [];
            }

            let tags = rawTags.map((tag) => {
              return {
                title: tag,
                slug: slugify(tag, { lower: true, strict: true }),
              };
            });

            let tagSlugs = rawTags.map((tag) => {
              return slugify(tag, { lower: true, strict: true });
            });

            const id = uuidv4();

            let articleData: any = {
              ...this.articleForm.value,
              image: this.image,
              pdf: this.file,
              description: JSON.stringify(data),
              tags: tags,
              tag_slugs: tagSlugs,
              published: true,
              highlight: false,
              has_image: this.image != '',
              date: new Date(),
              id: id,
            };

            if (articleData.tags === '') {
              articleData.tags = null;
            }

            this.articleService
              .create(id, articleData)
              .then(() => {
                this.notifier.notify('success', 'Artigo criado com sucesso.');

                this.router.navigate(['/articles']);
              })
              .catch(() => {
                this.notifier.notify('error', 'Erro ao criar artigo.');
              });
          }
        });
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { CalendarEvent } from '../../calendar/calendar-list/calendar-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { WallpaperService } from '../../wallpaper.service';
import { NotifierService } from 'angular-notifier';
import { DatePipe } from '@angular/common';
import { PublishEvent } from '../../base/interfaces';
import { AngularFireAuth } from '@angular/fire/auth';
import { take } from 'rxjs/operators';
import { error } from 'console';

export interface Wallpaper {
  id: string;
  title: string;
  date: any;
  image_small: string;
  image_medium: string;
  image_large: string;
  published: boolean;
}

@Component({
  selector: 'app-wallpaper-list',
  templateUrl: './wallpaper-list.component.html',
  styleUrls: ['./wallpaper-list.component.scss']
})
export class WallpaperListComponent implements OnInit {
  entry: any;
  status: string = 'active';
  direction: string = 'after';

  wallpapers: any[];

  constructor(
    private wallpaperService: WallpaperService,
    private notifier: NotifierService,
    private afAuth: AngularFireAuth,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.pipe(take(1)).subscribe(
      user => {
        this.getList();
      }
    );
  }

  public getList(): void {
    this.wallpaperService.list(this.entry, (this.status == 'active'), this.direction).pipe(take(1)).subscribe(
      response => {
        this.wallpapers = response.docs.map(
          doc => {
            const data: any = doc.data();

            return {
              ...data,
              date: new Date(data.date.toMillis())
            }
          }
        );
      },
      error => {
        this.notifier.notify('error', 'Não foi possível carregar os wallpapers');
      }
    )
  }

  public delete(data: any) {
    const confirmDelete = confirm("Tem certeza que deseja excluir o wallpaper?");

    if (confirmDelete) {
      this.wallpaperService.delete(data).then(() => {
          this.notifier.notify('success', 'Wallpaper excluído com sucesso');

          this.getList();
      }).catch(error => {
          this.notifier.notify('error', 'Não foi possível excluir o wallpaper');
      });

    }
  }

  public togglePublish(data: PublishEvent): void {
    this.wallpaperService.togglePublish(data.id, data.published).then(() => {
        const message = (data.published) ? 'Wallpaper despublicado com sucesso!' : 'Wallpaper publicado com sucesso!';

        this.notifier.notify('success', message);

        this.getList();
    });
  }

  public selectActive() {
    this.status = 'active';

    this.getList();
  }

  public selectDisabled() {
    this.status = 'disabled';

    this.getList();
  }

  public selectAdd() {
    this.router.navigate(['/wallpapers/add']);
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth.guard';
import { SubscriptionCreateComponent } from './subscription-create/subscription-create.component';
import { SubscriptionEditComponent } from './subscription-edit/subscription-edit.component';
import { SubscriptionListComponent } from './subscription-list/subscription-list.component';

const routes: Routes = [
  {path: 'subscriptions', component: SubscriptionListComponent, canActivate: [AuthGuard]},
  {path: 'subscriptions/add', component: SubscriptionCreateComponent, canActivate: [AuthGuard]},
  {path: 'subscriptions/edit/:id', component: SubscriptionEditComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubscriptionRoutingModule { }

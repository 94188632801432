import {Component, OnInit, ViewChild} from '@angular/core';
import {EditorjsComponent} from '../../base/editorjs/editorjs.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {UploadService} from '../../upload.service';
import {EditionService} from '../../edition.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EditionFormData, MagazineFormData} from '../../base/interfaces';
import {finalize, take} from 'rxjs/operators';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'app-edition-edit',
  templateUrl: './edition-edit.component.html',
  styleUrls: ['./edition-edit.component.scss']
})
export class EditionEditComponent implements OnInit {
  edition: any;
  magazine = '';
  image = '';
  file = '';

  @ViewChild(EditorjsComponent) editor: EditorjsComponent;
  editionForm = new FormGroup({
    title: new FormControl('', [
      Validators.required
    ]),
    number: new FormControl('', [
      Validators.required
    ]),
    month: new FormControl('', [
      Validators.required
    ]),
    year: new FormControl(''),
    issuu: new FormControl(''),
    restricted: new FormControl(''),
  });


  private sub: any;

  constructor(
    private notifier: NotifierService,
    private uploadService: UploadService,
    private editionService: EditionService,
    private router: Router,
    private route: ActivatedRoute
  ) { }


  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.id === undefined) {
        this.notifier.notify('error', 'Edição não encontrado');

        this.router.navigate(['/editions']);
      }

      const id = params.id;

      this.editionService.getEdition(id).pipe(take(1)).subscribe(
        entry => {
          this.edition = entry.data();
          this.image = this.edition.image;
          this.file = this.edition.pdf;
          const datepipe: DatePipe = new DatePipe('en-US');

          this.editionForm.patchValue({
                    title: this.edition.title,
                    month: datepipe.transform(this.edition.month.toDate(), 'yyyy-MM'),
                    year: this.edition.year,
                    number: this.edition.number,
                    issuu: this.edition.issuu,
                    restricted: this.edition.restricted
                  });
        },
        error => {
          this.notifier.notify('error', 'Edição não encontrada.');
          this.router.navigateByUrl('/editions');
        }
      );
    });
  }

  public deleteImage(): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    this.image = '';
  }

  public uploadImage(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService.uploadFirebase(file, filePath).snapshotChanges().pipe(
      finalize(() => {
        this.image = filePath;

        this.notifier.notify('success', 'Imagem enviada com sucesso');
      })
    ).subscribe();


  }

  public deleteFile(): void {
    this.notifier.notify('success', 'Arquivo removido com sucesso.');

    this.file = '';
  }

  public uploadFile(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService.uploadFirebase(file, filePath).snapshotChanges().pipe(
      finalize(() => {
        this.file = filePath;

        this.notifier.notify('success', 'Arquivo enviado com sucesso');
      })
    ).subscribe();
  }

  public submit() {
    this.editor.save().then(data => {
      const editionData: any = {
        ...this.editionForm.value,
        month: moment(this.editionForm.value.month).toDate(),
        number: Number(this.editionForm.value.number),
        image: this.image,
        pdf: this.file,
        description: JSON.stringify(data),
      };

      this.editionService.update(this.edition.id, editionData).then(
        () => {
          this.notifier.notify('success', 'Edição atualizada com sucesso.');

          this.router.navigateByUrl('/editions');
        },
        error => {
         this.notifier.notify('error', 'Erro ao atualizar a edição.');
        }
      );
    });
  }

}

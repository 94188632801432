import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-static-content-header',
  templateUrl: './static-content-header.component.html',
  styleUrls: ['./static-content-header.component.scss']
})
export class StaticContentHeaderComponent implements OnInit {
  @Output() addEvent = new EventEmitter();
  @Output() activeEvent = new EventEmitter();
  @Output() disabledEvent = new EventEmitter();
  @Output() sortEvent = new EventEmitter<string>();
  @Output() searchEvent = new EventEmitter<string>();
  @Input() showSort = true;
  @Input() showActive = true;
  @Input() showSearch = true;
  sort = 'date';
  active = true;

  searchForm = new FormGroup({
    search: new FormControl('')
  });

  constructor() { }

  ngOnInit(): void {
  }

  selectAdd() {
    this.addEvent.emit();
  }

  selectActive() {
    this.active = true;
    this.activeEvent.emit(this.active);
  }

  selectDisabled() {
    this.active = false;
    this.disabledEvent.emit(this.active);
  }

  public selectSort(sort: string) {
    this.sort = sort;
    this.sortEvent.emit(this.sort);
  }

  submit() {
    this.searchEvent.emit(this.searchForm.value.search);
  }

}

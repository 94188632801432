<form (ngSubmit)="submit()" [formGroup]="packageForm" action="" class="form">
  <div class="form__left">
    <app-button [enabled]="packageForm.valid" text="Adicionar" type="submit"></app-button>
    <app-input [control]="packageForm.get('title')" label="Título"></app-input>
    <app-input [control]="packageForm.get('slug')" label="Slug"></app-input>
    <app-input (optionEvent)="changeVersion($event)"
               [control]="packageForm.get('version')"
               [options]="options"
               label="Versão"
               type="select"></app-input>

    <app-tag-input
      [autocomplete]="true"
      [control]="packageForm.get('magazines')"
      [items]="magazineTitles"
      addText="+ Revista"
      label="Revistas"
      placeholder="Escolha as revistas"></app-tag-input>
  </div>

  <div class="form__right">
    <app-image-upload
      (deleteEvent)="deleteImage()"
      (uploadEvent)="uploadImage($event)"
      [image]="package.image"
    ></app-image-upload>

  </div>

</form>



import {Component, OnInit} from '@angular/core';
import {AuthService} from './auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'cultivar-manager';
  isLoggedIn = false;
  constructor(
    public authService: AuthService
  ) {
  }

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn;
  }
}

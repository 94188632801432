<form (ngSubmit)="submit()" [formGroup]="subscriptionForm" action="" class="form">
  <div class="form__left">
    <app-button [enabled]="subscriptionForm.valid" text="Adicionar" type="submit"></app-button>
    <app-input [control]="subscriptionForm.get('userEmail')" label="Email do cliente"></app-input>
    <app-input [control]="subscriptionForm.get('package')" [options]="packages" label="Pacote" type="select"></app-input>
    <app-input [control]="subscriptionForm.get('price')" label="Preço"></app-input>
    <app-input [control]="subscriptionForm.get('status')" [options]="statuses" label="Status" type="select"></app-input>
    <app-input [control]="subscriptionForm.get('months')" [options]="months" label="Meses" type="select"></app-input>



  </div>

  <div class="form__right">
    <app-input [control]="subscriptionForm.get('address')" label="Endereço"></app-input>
    <app-input [control]="subscriptionForm.get('number')" label="Número"></app-input>
    <app-input [control]="subscriptionForm.get('complement')" label="Complemento"></app-input>
    <app-input [control]="subscriptionForm.get('cep')" label="CEP"></app-input>
    <app-input [control]="subscriptionForm.get('neighborhood')" label="Bairro"></app-input>
    <app-input [control]="subscriptionForm.get('city')" label="Cidade"></app-input>
    <app-input [control]="subscriptionForm.get('state')" label="Estado"></app-input>
  </div>

</form>

import { Component, OnInit } from '@angular/core';
import {Wallpaper} from '../wallpaper-list/wallpaper-list.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {UploadService} from '../../upload.service';
import {ActivatedRoute, Router} from '@angular/router';
import {WallpaperService} from '../../wallpaper.service';
import {DatePipe} from '@angular/common';
import {WallpaperFormData} from '../../base/interfaces';
import { finalize, take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';

@Component({
  selector: 'app-wallpaper-edit',
  templateUrl: './wallpaper-edit.component.html',
  styleUrls: ['./wallpaper-edit.component.scss']
})
export class WallpaperEditComponent implements OnInit {
  wallpaper: any;
  smallImage = '';
  mediumImage = '';
  largeImage = '';

  wallpaperForm = new FormGroup({
    title: new FormControl('', [
      Validators.required
    ]),
    date: new FormControl(''),
  });


  private sub: any;

  constructor(
    private notifier: NotifierService,
    private wallpaperService: WallpaperService,
    private uploadService: UploadService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      if (params.id === undefined) {
        this.notifier.notify('error', 'Wallpaper não encontrado');

        this.router.navigate(['/wallpapers']);
      }

      const id = params.id;

      this.wallpaperService.getWallpaper(id).pipe(take(1)).subscribe(
        response => {
          const datepipe: DatePipe = new DatePipe('en-US');

          this.wallpaper = response.data();
          this.wallpaper.date = new Date(this.wallpaper.date.toMillis());

          this.wallpaperForm.patchValue({
            title: this.wallpaper.title,
            date: datepipe.transform(this.wallpaper.date, 'yyyy-MM-dd'),
          });

          this.smallImage = this.wallpaper.imageSmall;
          this.mediumImage = this.wallpaper.imageMedium;
          this.largeImage = this.wallpaper.imageLarge;

        },
        error => {
          this.notifier.notify('error', 'Não foi possível encontrar o wallpaper');
          this.router.navigate(['/wallpapers']);
        });

    });
  }

  public deleteImage(type: string): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    switch (type) {
      case 'small':
        this.smallImage = '';
        break;
      case 'medium':
        this.mediumImage = '';
        break;
      case 'large':
        this.largeImage = '';
        break;
    }
  }

  public uploadImage(type: string, file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;


    this.uploadService.uploadFirebase(file, filePath).snapshotChanges().pipe(
      finalize(() => {
        switch (type) {
          case 'small':
            this.smallImage = filePath;
            break;
          case 'medium':
            this.mediumImage = filePath;
            break;
          case 'large':
            this.largeImage = filePath;
            break;
        }


        this.notifier.notify('success', 'Imagem enviada com sucesso');
     })
    ).subscribe();
  }

  public submit() {
    let wallpaperData: any = {
      ...this.wallpaperForm.value,
      imageSmall: (this.smallImage) ? this.smallImage : '',
      imageMedium: (this.mediumImage) ? this.mediumImage : '',
      imageLarge: (this.largeImage) ? this.largeImage : '',
      date: moment(this.wallpaperForm.value.date).toDate(),
    };


    this.wallpaperService.update(this.wallpaper.id, wallpaperData).then(() => {
        this.notifier.notify('success', 'Wallpaper criado com sucesso');

        this.router.navigate(['/wallpapers']);
    }).catch(error => {
      console.log(error);
        this.notifier.notify('error', 'Erro na hora de criar os dados');
    });
  }


}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PackageCreateComponent } from './package-create/package-create.component';
import { PackageListComponent } from './package-list/package-list.component';
import {PackageEditComponent} from './package-edit/package-edit.component';

const routes: Routes = [
  {path: 'packages', component: PackageListComponent},
  {path: 'packages/add', component: PackageCreateComponent},
  {path: 'packages/edit/:id', component: PackageEditComponent},

  {path: 'packages/:page', component: PackageListComponent},
  {path: 'packages/:status/:page', component: PackageListComponent},
  {path: 'packages/:status/:sort/:page', component: PackageListComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PackageRoutingModule { }

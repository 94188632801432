import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {MagazineListComponent} from './magazine-list/magazine-list.component';
import {MagazineCreateComponent} from './magazine-create/magazine-create.component';
import {MagazineEditComponent} from './magazine-edit/magazine-edit.component';

const routes: Routes = [
  {path: 'magazines', component: MagazineListComponent},
  {path: 'magazines/add', component: MagazineCreateComponent},
  {path: 'magazines/edit/:id', component: MagazineEditComponent},

  {path: 'magazines/:page', component: MagazineListComponent},
  {path: 'magazines/:status/:page', component: MagazineListComponent},
  {path: 'magazines/:status/:sort/:page', component: MagazineListComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MagazineRoutingModule { }

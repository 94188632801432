<form (ngSubmit)="submit()" [formGroup]="ebookForm" action="" class="form">
  <div class="form__left">
    <app-button [enabled]="ebookForm.valid" text="Adicionar" type="submit"></app-button>
    <app-input [control]="ebookForm.get('title')" label="Título"></app-input>
    <app-input [control]="ebookForm.get('call')" label="Chamada" type="textarea"></app-input>
  </div>

  <div class="form__right">
    <app-image-upload
      (deleteEvent)="deleteImage()"
      (uploadEvent)="uploadImage($event)"
      [image]="image"
    ></app-image-upload>

    <app-file-upload
      (deleteEvent)="deleteFile()"
      (uploadEvent)="uploadFile($event)"
      [filePdf]="file"
    ></app-file-upload>

  </div>

</form>

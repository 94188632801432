import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {
  @Input() image: string;
  @Input() text = 'Adicionar Imagem';

  @Output() deleteEvent = new EventEmitter<boolean>();
  @Output() uploadEvent = new EventEmitter<File>();


  url = 'https://storage.googleapis.com/images-cultivar/';

  constructor() { }

  ngOnInit(): void {
  }

  public deleteImage() {
    this.deleteEvent.emit();
  }

  public handleFileUpload(files: FileList) {
    const file = files.item(0);

    this.uploadEvent.emit(file);
  }

}

<app-static-content-header (addEvent)="selectAdd()" [showActive]="false" (searchEvent)="search($event)" [showSort]="false"></app-static-content-header>

<ng-container *ngFor="let user of users">
  <app-entry-item
    (deleteEvent)="delete($event)"
    [date]="user.email"
    [id]="user.id"
    [title]="user.name"
    editRoute="users">


    <a *ngIf="user.hasFirebaseUser" (click)="changePassword(user)" class="list-item__action list-item__action--secondary">Enviar email de trocar senha</a>
    <a *ngIf="user.admin" (click)="updateAdminAccess(user)" class="list-item__action list-item__action--secondary">Remover acesso de admin</a>
    <a *ngIf="!user.admin" (click)="updateAdminAccess(user)" class="list-item__action list-item__action--secondary">Dar acesso de admin</a>
  </app-entry-item>
</ng-container>

<div class="pagination">
  <a class="pagination__link pagination__link--previous" (click)="prevPage()">Anterior</a>
  <a class="pagination__link pagination__link--next" (click)="nextPage()">Próximo</a>
</div>

export interface HighlightEvent {
  id: string;
  type: string;
  status: boolean;
}

export interface PublishEvent {
  id: string;
  published: boolean;
}

export interface ArticleFormData {
  title: string;
  tags: string[] | string;
  slug: string;
  image: string;
  pdf: string;
  description: string;
  issuu: string;
  sourceName: string;
  sourceComment: string;
  sourceEmail: string;
  sourcePhone: string;
  sourceCompany: string;
}

export interface MagazineFormData {
  title: string;
  slug: string;
  image: string;
  description: string;
}

export interface PackageFormData {
  title: string;
  slug: string;
  image: string;
  magazines: string[];
  version: number;
}

export interface PeriodFormData {
  title: string;
  months: number;
  price: number;
  package: string;
}

export interface EditionFormData {
  title: string;
  month: any;
  year: string;
  number: number;
  issuu: string;
  restricted: boolean;
  image: string;
  description: string;
}

export interface EbookFormData {
  title: string;
  call: string;
  image: string;
  file: string;
}

export interface WallpaperFormData {
  title: string;
  date: any;
  image_small: string;
  image_medium: string;
  image_large: string;
}

export interface UserFormData {
  name: string;
  email: string;
  password: string;
  permissions: string[];
}

export interface UserEditFormData {
  name: string;
  email: string;
  permissions: string[];
}

export interface UserPasswordFormData {
  password: string;
}

export interface BannerFormData {
  title: string;
  url: string;
  image: string;
  type: string;
  position: string;
}


export interface EditionArticleFormData {
  title: string;
  slug: string;
  edition: string;
  author: string;
  preview: string;
  description: string;
  image: string;
  index: number;
  date: any;
}

export interface Option {
  value: any;
  title: string;
}

export interface CalendarEventFormData {
  title: string;
  slug: string;
  link: string;
  description: string;
  location: string;
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
}

export const API_URL = 'https://manager.quent.al/v1/';

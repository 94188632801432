import {Component, OnInit} from '@angular/core';
import {Option, PublishEvent} from '../../base/interfaces';
import {ActivatedRoute, Router} from '@angular/router';
import {EditionArticleService} from '../../edition-article.service';
import {NotifierService} from 'angular-notifier';
import {DatePipe} from '@angular/common';
import {EditionArticle} from '../../shared/intefaces/edition-article';
import {take} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  selector: 'app-edition-article-list',
  templateUrl: './edition-article-list.component.html',
  styleUrls: ['./edition-article-list.component.scss']
})
export class EditionArticleListComponent implements OnInit {
  entry: any;
  edition: string;
  status: boolean = true;
  direction: string = 'after';

  editionArticles: EditionArticle[];

  constructor(
    private route: ActivatedRoute,
    private editionArticleService: EditionArticleService,
    private notifier: NotifierService,
    private afAuth: AngularFireAuth,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.edition = params.edition;

      this.afAuth.authState.pipe(take(1)).subscribe(() => {
        this.getList();
      });
    });
  }

  public getList(): void {
    const datepipe: DatePipe = new DatePipe('en-US');

    if (this.entry) {
      this.editionArticleService.getEditionArticle(this.entry).pipe(take(1)).subscribe(
        response => {
          this.editionArticleService.list(response, this.edition, this.status, this.direction).pipe(take(1)).subscribe(
            response => {
              this.editionArticles = response.docs.map(doc => {
                let data = doc.data();

                data['date'] = datepipe.transform(data.date.toDate(), 'dd/MM/yyyy');

                return data;
              });
            },
            error => {
              console.log(error);
              this.notifier.notify('error', 'Não foi possível carregar as matérias');
            }
          );
        },
        error => {
          console.log(error);
          this.notifier.notify('error', 'Não foi possível carregar as matérias');
        }
      );

    } else {
      this.editionArticleService.list(null, this.edition, this.status, this.direction).pipe(take(1)).subscribe(
        response => {
          this.editionArticles = response.docs.map(doc => {
            let data = doc.data();

            data['date'] = datepipe.transform(data.date.toDate(), 'dd/MM/yyyy');

            return data;
          });
        },
        error => {
          this.notifier.notify('error', 'Não foi possível carregar as matérias');
        }
      );
    }
  }

  public delete(data: any) {
    const confirmDelete = confirm('Tem certeza que deseja excluir a matéria?');

    if (confirmDelete) {
      this.editionArticleService.delete(data).then(() => {
        this.notifier.notify('success', 'Matéria excluída com sucesso');
        this.getList();
      }).catch(() => {
        this.notifier.notify('error', 'Não foi possível excluir a matéria');
      });
    }
  }

  public togglePublish(data: PublishEvent): void {
    this.editionArticleService.togglePublish(data.id, data.published).then(() => {
      const message = (data.published) ? 'Matéria despublicada com sucesso!' : 'Matéria publicada com sucesso!';

      this.notifier.notify('success', message);

      this.getList();
    }).catch(() => {
      this.notifier.notify('error', 'Não foi possível atualizar a matéria');
    });
  }

  public toggleHighlight(article: EditionArticle) {
    this.editionArticleService.toggleHighlight(article.id, article.highlight).subscribe(
      response => {
        this.notifier.notify('success', 'Matéria destacada com sucesso');

        this.editionArticles = this.editionArticles.map(item => {
          if (item.id === article.id) {
            item.highlight = !article.highlight;
          }

          return item;
        });
      },
      error => {
        this.notifier.notify('error', 'Ocorreu um erro ao destacar a matéria');
      }
    );
  }

  public prevPage(): void {
    this.entry = this.editionArticles[0].id.toString();
    this.direction = 'before';

    this.getList();
  }

  public nextPage(): void {
    this.entry = this.editionArticles[this.editionArticles.length-1].id.toString();
    this.direction = 'after';

    console.log(this.entry);

    this.getList();
  }

  public selectActive(): void {
    this.status = true;

    this.getList();
  }

  public selectDisabled(): void {
    this.status = false;

    this.getList();
  }

  public selectAdd(): void {
    this.router.navigateByUrl(`/edition_articles/add/${this.edition}`);
  }
}

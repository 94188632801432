import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {WallpaperListComponent} from './wallpaper-list/wallpaper-list.component';
import {WallpaperCreateComponent} from './wallpaper-create/wallpaper-create.component';
import {WallpaperEditComponent} from './wallpaper-edit/wallpaper-edit.component';

const routes: Routes = [
  {path: 'wallpapers', component: WallpaperListComponent},
  {path: 'wallpapers/add', component: WallpaperCreateComponent},
  {path: 'wallpapers/edit/:id', component: WallpaperEditComponent},

  {path: 'wallpapers/:page', component: WallpaperListComponent},
  {path: 'wallpapers/:status/:page', component: WallpaperListComponent},
  {path: 'wallpapers/:status/:sort/:page', component: WallpaperListComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WallpaperRoutingModule { }

<header class="header">
  <img src="./assets/logo.png" alt="logo"/>
</header>
<main  [ngClass]="{'main': authService.isLoggedIn, 'main-login': !authService.isLoggedIn}">
  <nav *ngIf="authService.isLoggedIn" class="menu">
    <h2 class="menu__heading">Publicações</h2>
    <a href="" class="menu__item" routerLink="/subscriptions" routerLinkActive="menu__item--active">Pedidos</a>
    <a href="" class="menu__item" routerLink="/packages" routerLinkActive="menu__item--active">Pacotes</a>
    <a class="menu__item" href="" routerLink="/periods" routerLinkActive="menu__item--active">Períodos</a>
    <a class="menu__item" href="" routerLink="/editions" routerLinkActive="menu__item--active">Edições</a>
    <a class="menu__item" href="" routerLink="/magazines" routerLinkActive="menu__item--active">Revistas</a>
    <a class="menu__item" href="" routerLink="/ebooks" routerLinkActive="menu__item--active">Ebooks</a>

    <h2 class="menu__heading">Mídia</h2>
    <a class="menu__item" href="" routerLink="/banners" routerLinkActive="menu__item--active">Banners</a>
    <a class="menu__item" href="" routerLink="/banners-anuario" routerLinkActive="menu__item--active">Banners Anuário</a>
    <a class="menu__item" href="" routerLink="/wallpapers" routerLinkActive="menu__item--active">Wallpapers</a>

    <h2 class="menu__heading">Conteúdos</h2>
    <a class="menu__item" href="" routerLink="/articles" routerLinkActive="menu__item--active">Artigos</a>
    <a href="" class="menu__item" routerLink="/news" routerLinkActive="menu__item--active">Notícias</a>
    <a class="menu__item" href="" routerLink="/calendar" routerLinkActive="menu__item--active">Agenda</a>

    <h2 class="menu__heading">Newsletter</h2>
    <a class="menu__item" href="" routerLink="/groups" routerLinkActive="menu__item--active">Grupos</a>
    <a class="menu__item" href="" routerLink="/newsletter" routerLinkActive="menu__item--active">Envios</a>
    <a class="menu__item" href="" routerLink="/newsletter/custom" routerLinkActive="menu__item--active">Criar envio customizado</a>

    <h2 class="menu__heading">Anuário</h2>
    <a class="menu__item" href="" routerLink="/anuario/tractors" routerLinkActive="menu__item--active">Tratores</a>

    <h2 class="menu__heading">Administração</h2>
    <a class="menu__item" href="" routerLink="/users" routerLinkActive="menu__item--active">Usuários</a>
    <a (click)="authService.SignOut()" class="menu__item" href="">Sair</a>
  </nav>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</main>

<notifier-container></notifier-container>
<ngx-ui-loader></ngx-ui-loader>

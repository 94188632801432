<div class="container">
  <div class="banner banner--top" routerLink="/banners/rotation">Rotativo</div>
  <div class="banner banner--half" routerLink="/banners/half1">Half 1 / Side 1</div>
  <div class="banner banner--half" routerLink="/banners/half2">Half 2 / Side 2</div>
  <div class="banner banner--half" routerLink="/banners/half3">Half 3 / Side 3</div>
  <div class="banner banner--middle" routerLink="/banners/middle">Middle / Internal Top</div>
  <div class="banner banner--half" routerLink="/banners/half4">Half 4</div>
  <div class="banner banner--half" routerLink="/banners/half5">Half 5</div>
  <div class="banner banner--half" routerLink="/banners/half6">Half 6</div>
  <div class="banner banner--bottom" routerLink="/banners/bottom">Bottom</div>
  <div class="banner banner--bottom" routerLink="/banners/newsletter">Newsletter</div>

</div>

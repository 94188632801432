import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-entry-item',
  templateUrl: './entry-item.component.html',
  styleUrls: ['./entry-item.component.scss']
})
export class EntryItemComponent implements OnInit {
  @Input() title: string;
  @Input() date: string;
  @Input() id: any;
  @Input() editRoute: string;
  @Input() showEdit: boolean = true;
  @Input() showDelete: boolean = true;

  @Output() deleteEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public delete() {
    this.deleteEvent.emit(this.id);
  }

}

<div class="form__image-upload" *ngIf="image != '' && image != null">
  <div class="form__delete" (click)="deleteImage()">
    <i class="form__delete-icon icon ion-ios-close"></i>
  </div>
  <img [src]="url + image" class="form__image" />
</div>

<div class="form__image-upload" *ngIf="image == null || image == ''">
  <input #input (change)="handleFileUpload($event.target.files)" class="form__file-input" type="file">
  <app-button (click)="input.click()" [text]="text"></app-button>
</div>

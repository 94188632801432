<div class="content__header">
  <div class="content__header-left">
    <button class="button" (click)="selectAdd()">
      Adicionar
    </button>

    <div class="content__header-status" *ngIf="showActive">
      <a class="content__header-status-filter" (click)="selectActive()" [ngClass]="{'content__header-status-filter--active': active}">Ativas</a>
      <a class="content__header-status-filter" (click)="selectDisabled()" [ngClass]="{'content__header-status-filter--active': !active}">Inativas</a>
      <ng-content></ng-content>
    </div>
  </div>

  <div class="content__header-right">
    <div class="content__header-sort" *ngIf="showSort">
      <div class="content__header-sort-wrapper">
        <span class="content__header-sort-title">Ordenar: </span>
        <span class="content__header-sort-choice" *ngIf="sort == 'title'">Título</span>
        <span class="content__header-sort-choice" *ngIf="sort == 'date'">Data</span>
      </div>
      <span class="content__header-sort-option content__header-sort-option--first" (click)="selectSort('title')">Título</span>
      <span  class="content__header-sort-option" (click)="selectSort('date')">Data</span>
    </div>

    <form (ngSubmit)="submit()" [formGroup]="searchForm" class="content__header-search-form" *ngIf="showSearch">
      <label class="content__header-search">
        <i class="content__header-search-icon icon ion-ios-search"></i>
        <input type="text" class="content__header-search-input" placeholder="Pesquisar" [formControl]="searchForm.get('search')"/>
      </label>
    </form>
  </div>

</div>

import {Component, OnInit} from '@angular/core';
import {Tractor} from '../../../shared/intefaces/tractor';
import {AnuarioService} from '../../../shared/services/anuario.service';
import {take} from 'rxjs/operators';
import {NotifierService} from 'angular-notifier';
import {PublishEvent} from '../../../base/interfaces';
import {Router} from '@angular/router';

@Component({
  selector: 'app-anuario-list',
  templateUrl: './anuario-list.component.html',
  styleUrls: ['./anuario-list.component.scss']
})
export class AnuarioListComponent implements OnInit {
  tractors: Tractor[];
  private entry: any;
  direction: string;
  status: boolean = true;
  searchTerm: string;

  constructor(private anuarioService: AnuarioService,
              private notifier: NotifierService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    if (this.searchTerm) {
      this.anuarioService.search(this.searchTerm, this.status).pipe(take(1)).subscribe(
        response => {
          this.tractors = response.docs.map(doc => doc.data());

        }, error => {
          console.log(error);
          this.notifier.notify('error', 'Erro ao carregar a pesquisa');
        }
      );

      return;
    }

    if (this.entry) {
      this.anuarioService.getById(this.entry).pipe(take(1)).subscribe(
        response => {
          this.anuarioService.list(response, this.status, this.direction).pipe(take(1)).subscribe(
            response => {
              this.tractors = response.docs.map(doc => doc.data());

            }, error => {
              this.notifier.notify('error', 'Erro ao carregar os tratores');
            }
          );
        }, error => {
          this.notifier.notify('error', 'Erro ao carregar os tratores');
        }
      );
    } else {
      this.anuarioService.list(null, this.status, this.direction).pipe(take(1)).subscribe(
        response => {
          this.tractors = response.docs.map(doc => doc.data());

        }, error => {
          this.notifier.notify('error', 'Erro ao carregar os tratores');
        }
      );
    }
  }

  public togglePublish(data: PublishEvent): void {
    this.anuarioService.togglePublish(data.id.toString(), data.published).then(response => {
      const message = (data.published) ? 'Trator despublicado com sucesso!' : 'Trator publicado com sucesso!';

      this.notifier.notify('success', message);

      this.getList();
    }).catch(error => {
      this.notifier.notify('error', 'Não foi possível alterar o status do trator.');
    });
  }

  public delete(data: any) {
    const confirmDelete = confirm("Tem certeza que deseja excluir o trator?");

    if (confirmDelete) {
      this.anuarioService.delete(data.toString()).then(response => {
        this.notifier.notify('success', 'Trator excluído com sucesso!');
        this.getList();
      }).catch(error => {
        this.notifier.notify('error', 'Não foi possível excluir o Trator.');
      });
    }
  }

  selectActive(): void {
    this.status = true;
    this.getList();
  }

  selectDisabled(): void {
    this.status = false;
    this.getList();
  }

  prevPage(): void {
    this.entry = this.tractors[0].id;
    this.direction = 'before';
    this.getList();
  }

  nextPage(): void {
    this.entry = this.tractors[this.tractors.length - 1].id;
    this.direction = 'after';
    this.getList();
  }

  selectAdd(): void {
    this.router.navigateByUrl('/anuario/tractors/add');
  }

  public search(term) {
    this.searchTerm = term;
    this.getList();
  }
}

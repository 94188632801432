import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss']
})
export class TagInputComponent implements OnInit {
  @Input() label = 'Tags';
  @Input() placeholder = 'Insira uma tag';
  @Input() addText = '+ Tag';
  @Input() control: FormControl;
  @Input() autocomplete = false;
  @Input() items: string[];

  constructor() { }

  ngOnInit(): void {

  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BannerRoutingModule } from './banner-routing.module';
import { BannerHomeSelectionComponent } from './banner-home-selection/banner-home-selection.component';
import { BannerInternalSelectionComponent } from './banner-internal-selection/banner-internal-selection.component';
import { BannerListComponent } from './banner-list/banner-list.component';
import {BaseModule} from '../base/base.module';
import { BannerCreateComponent } from './banner-create/banner-create.component';
import {ReactiveFormsModule} from '@angular/forms';
import { BannerEditComponent } from './banner-edit/banner-edit.component';


@NgModule({
  declarations: [BannerHomeSelectionComponent, BannerInternalSelectionComponent, BannerListComponent, BannerCreateComponent, BannerEditComponent],
  imports: [
    CommonModule,
    BannerRoutingModule,
    BaseModule,
    ReactiveFormsModule
  ]
})
export class BannerModule { }

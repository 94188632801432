import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-item-action',
  templateUrl: './item-action.component.html',
  styleUrls: ['./item-action.component.scss']
})
export class ItemActionComponent implements OnInit {
  @Input() checkCondition: boolean;
  @Input() enabledText: string;
  @Input() disabledText: string;

  constructor() { }

  ngOnInit(): void {
  }

}

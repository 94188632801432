import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import {Option, PackageFormData} from 'src/app/base/interfaces';
import { MagazineService } from 'src/app/magazine.service';
import { Magazine } from 'src/app/magazine/magazine-list/magazine-list.component';
import { PackageService } from 'src/app/package.service';
import { UploadService } from 'src/app/upload.service';
import { Package } from '../package-list/package-list.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-package-create',
  templateUrl: './package-create.component.html',
  styleUrls: ['./package-create.component.scss']
})
export class PackageCreateComponent implements OnInit {
  package: Package;
  magazines: Magazine[];
  magazineTitles = [];
  options = [
    {value: 0, title: "Digital + Impressa"},
    {value: 1, title: "Digital"},
  ];

  packageForm = new FormGroup({
    title: new FormControl('', [
      Validators.required
    ]),
    slug: new FormControl('', [
      Validators.required
    ]),
    version: new FormControl(''),
    magazines: new FormControl('')
  });

  constructor(
    private notifier: NotifierService,
    private uploadService: UploadService,
    private packageService: PackageService,
    private magazineService: MagazineService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.package = {
      id: '',
      title: '',
      slug: '',
      image: '',
      version: 0,
      magazines: [],
      published: false
    };

    this.magazineService.list(1, 'active', 'title').subscribe(
      response => {
        this.magazines = response['data']

        this.magazines.forEach(entry => {
          this.magazineTitles.push(entry.title);
        });
      }
    );

    this.packageForm.patchValue({
      version: 0
    });
  }

  public submit() {
    let magazineList = [];


    this.magazines.forEach(magazine => {
      if (this.packageForm.value.magazines.includes(magazine.title)) {
        magazineList.push(magazine.id);
      }
    });
    const id = uuidv4();

    let packageData: any = {
      ...this.packageForm.value,
      id,
      magazines: magazineList,
      image: this.package.image
    };


    this.packageService.create(id, packageData).then(() => {
      this.notifier.notify('success', 'Pacote criado com sucesso');

      this.router.navigate(['/packages']);
    }).catch(error => {
      this.notifier.notify('error', 'Erro na hora de criar os dados');
    });
  }

  public deleteImage(): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    this.package.image = '';
  }

  public uploadImage(file: File): void {
    this.uploadService.uploadImage(file).subscribe(
      response => {
        const fileUrl = response['file'].url;
        const splitFileUrl = fileUrl.split('/');
        const fileName = splitFileUrl[splitFileUrl.length - 1];

        this.package.image = fileName;

        this.notifier.notify('success', 'Imagem enviada com sucesso');
        // TODO implement upload to firestore
      },
      error => {
        this.notifier.notify('error', 'Erro no upload da imagem');
      }
    );
  }

  public changeVersion(entry: Option) {
    this.package.version = entry.value;

    this.packageForm.patchValue({
      version: entry.value
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { groupEnd } from 'console';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { generate } from 'rxjs';
import { take } from 'rxjs/operators';
import { ArticleService } from 'src/app/article.service';
import { NewsService } from 'src/app/news.service';
import { GroupService } from 'src/app/services/group.service';
import {BannerService} from 'src/app/banner.service';
import { Article } from 'src/app/shared/intefaces/article';
import { Group } from 'src/app/shared/intefaces/group';
import { News } from 'src/app/shared/intefaces/news';
import { NewsletterService } from 'src/app/shared/services/newsletter.service';
import { v4 as uuidv4 } from 'uuid'

@Component({
  selector: 'app-newsletter-create',
  templateUrl: './newsletter-create.component.html',
  styleUrls: ['./newsletter-create.component.scss']
})
export class NewsletterCreateComponent implements OnInit {
  newsletterForm = new FormGroup({
    subject: new FormControl('', [
      Validators.required,
    ]),
  });

  groups: Group[];
  news: News[];
  banner: any;
  articles: Article[];

  selectedGroups: Group[] = [];
  selectedHighlight = '';

  constructor(private articleService: ArticleService,
    private newsService: NewsService,
    private groupService: GroupService,
    private afAuth: AngularFireAuth,
    private notifier: NotifierService,
    private newsletterService: NewsletterService,
    private router: Router,
    private bannerService: BannerService,
    private loader: NgxUiLoaderService) { }

  ngOnInit(): void {
    let date = new Date();
    this.loader.start('articles');
    this.loader.start('news');
    this.loader.start('groups');
    this.loader.start('banners');

    this.newsletterForm.patchValue({
      subject: 'Newsletter ' + date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    });

    this.afAuth.authState.pipe(take(1)).subscribe(user => {
      this.articleService.getNewsletter().pipe(take(1)).subscribe(newsletter => {
        this.articles = newsletter.docs.map(doc => doc.data());

        this.loader.stop('articles');
      });

      this.newsService.getNewsletter().pipe(take(1)).subscribe(newsletter => {
        this.news = newsletter.docs.map(n => n.data());

        this.loader.stop('news');
      });

      this.bannerService.list(null, 'newsletter', true, 'after').pipe(take(1)).subscribe(response => {
        this.banner = response.docs.slice(0, 1).map(doc => doc.data())[0];

        this.loader.stop('banners');
      });

      this.groupService.getActiveGroups().pipe(take(1)).subscribe(groups => {
        this.groups = groups.docs.map(g => {
          let data = g.data();

          // this.selectGroup(data);

          return data;
          g.data()
        });
        this.loader.stop('groups');
      });
    });
  }

  selectGroup(group) {
    if (this.selectedGroups.find(g => g.id == group.id)) {
      this.selectedGroups = this.selectedGroups.filter(g => g.id !== group.id);
    } else {
      this.selectedGroups.push(group);
    }
  }

  groupIsSelected(group) {
    return this.selectedGroups.find(g => g.id == group.id);
  }

  selectHighlight(itemId) {
    if (this.selectedHighlight == itemId) {
      this.selectedHighlight = '';
    } else {
      this.selectedHighlight = itemId;
    }
  }



  async submit() {

    const id = uuidv4();

    let data = {
      id,
      subject: this.newsletterForm.value.subject,
      date: new Date(),
      articles: this.articles.map(article => {
        return {
          id: article.id,
          title: article.title,
          image: article.image,
          slug: article.slug,
          call: article.call,
        }
      }),
      news: this.news.map(news => {
        return {
          id: news.id,
          title: news.title,
          image: news.image,
          slug: news.slug,
          call: news.call,
        }
      }),
      highlight: this.selectedHighlight,
      groups: this.selectedGroups.map(group => group.id),
      lastSent: 0,
      newsletterUrl: this.banner.url,
      newsletterImage: this.banner.image,
      template: false,
      completed: false,
    }

    this.newsletterService.save(id, data).then(() => {

      this.notifier.notify('success', 'Newsletter criada com sucesso!');
      this.delay(50).then(() => {
        this.router.navigateByUrl('/newsletter');
      });
    }).catch(err => {
      this.notifier.notify('error', 'Um erro aconteceu na hora de salvar.');
    });

  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}


<app-static-content-header (addEvent)="selectAdd()" (activeEvent)="selectActive()" (disabledEvent)="selectDisabled()" [showSort]="false" [showSearch]="false"></app-static-content-header>
<ng-container *ngFor="let wallpaper of wallpapers">
  <app-entry-item
    (deleteEvent)="delete($event)"
    [date]="wallpaper.date | date:'dd/MM/YYYY'"
    [id]="wallpaper.id"
    [title]="wallpaper.title"
    editRoute="wallpapers">
    <app-publish-action (publishEvent)="togglePublish($event)" [id]="wallpaper.id" [published]="wallpaper.published"></app-publish-action>

  </app-entry-item>
</ng-container>

import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Option} from '../interfaces';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  @Input() control: FormControl;
  @Input() label: string;
  @Input() type = 'input';
  @Input() options: Option[];
  @Input() disabled = false;

  @Output() optionEvent = new EventEmitter<Option>();

  constructor() { }

  ngOnInit(): void {
  }

  public showErrors() {
    if (!this.control) {
      return false;
    }

    const {dirty, touched, errors} = this.control;

    return dirty && touched && errors;
  }

  public changeOption(event) {
    const selectedIndex = event.target.selectedIndex;
    const value = this.control.value;
    const title = event.target[selectedIndex].text;

    this.optionEvent.emit({value, title});
  }

}

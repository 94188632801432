<div class="content__header">
  <div class="content__header-left">
    <button *ngIf="additionalLink && !additionalLink2" [routerLink]="['/', baseRoute, 'add', additionalLink]" class="button">
      Adicionar
    </button>

    <button *ngIf="additionalLink && additionalLink2" [routerLink]="['/', baseRoute, 'add', additionalLink, additionalLink2]" class="button">
      Adicionar
    </button>

    <button *ngIf="!additionalLink" [routerLink]="['/', baseRoute, 'add']" class="button">
      Adicionar
    </button>

    <div  *ngIf="headerOptions && !additionalLink" class="content__header-status">
      <a [ngClass]="{'content__header-status-filter--active': status == 'active'}"
         [routerLink]="['/', baseRoute, 'active', sort, 1]" class="content__header-status-filter">Ativas</a>
      <a [ngClass]="{'content__header-status-filter--active': status == 'disabled'}"
         [routerLink]="['/', baseRoute, 'disabled', sort, 1]" class="content__header-status-filter">Inativas</a>
    </div>

    <div  *ngIf="headerOptions && additionalLink && !additionalLink2" class="content__header-status">
      <a [ngClass]="{'content__header-status-filter--active': status == 'active'}"
         [routerLink]="['/', baseRoute, additionalLink, 'active', sort, 1]"
         class="content__header-status-filter">Ativas</a>
      <a [ngClass]="{'content__header-status-filter--active': status == 'disabled'}"
         [routerLink]="['/', baseRoute, additionalLink, 'disabled', sort, 1]"
         class="content__header-status-filter">Inativas</a>

      <ng-content></ng-content>
    </div>

    <div  *ngIf="headerOptions && additionalLink && additionalLink2" class="content__header-status">
      <a [ngClass]="{'content__header-status-filter--active': status == 'active'}"
         [routerLink]="['/', baseRoute, additionalLink, additionalLink2, 'active', sort, 1]"
         class="content__header-status-filter">Ativas</a>
      <a [ngClass]="{'content__header-status-filter--active': status == 'disabled'}"
         [routerLink]="['/', baseRoute, additionalLink, additionalLink2, 'disabled', sort, 1]"
         class="content__header-status-filter">Inativas</a>

      <ng-content></ng-content>
    </div>
  </div>

  <div class="content__header-right">
    <div  *ngIf="headerOptions && !sortOptions" class="content__header-sort">
      <div class="content__header-sort-wrapper">
        <span class="content__header-sort-title">Ordenar: </span>
        <span class="content__header-sort-choice" *ngIf="sort == 'title'">Título</span>
        <span class="content__header-sort-choice" *ngIf="sort == 'date'">Data</span>
      </div>
      <span [routerLink]="['/', baseRoute, status, 'title', 1]"
            class="content__header-sort-option content__header-sort-option--first">Título</span>
      <span [routerLink]="['/', baseRoute, status, 'date', 1]" class="content__header-sort-option">Data</span>
    </div>

    <div  *ngIf="headerOptions && sortOptions" class="content__header-sort">
      <div class="content__header-sort-wrapper">
        <span class="content__header-sort-title">Ordenar: </span>
        <ng-container *ngFor="let option of sortOptions">
          <span *ngIf="sort == option.value" class="content__header-sort-choice">{{option.title}}</span>
        </ng-container>

      </div>
      <ng-container *ngFor="let option of sortOptions; let i = index">
        <span *ngIf="additionalLink && !additionalLink2" [ngClass]="{'content__header-sort-option--first': i == 0}"
              [routerLink]="['/', baseRoute, additionalLink, status, option.value, 1]"
              class="content__header-sort-option" >{{option.title}}</span>

        <span *ngIf="additionalLink && additionalLink2" [ngClass]="{'content__header-sort-option--first': i == 0}"
              [routerLink]="['/', baseRoute, additionalLink, additionalLink2, status, option.value, 1]"
              class="content__header-sort-option" >{{option.title}}</span>

        <span *ngIf="!additionalLink" [ngClass]="{'content__header-sort-option--first': i == 0}"
              [routerLink]="['/', baseRoute, status, option.value, 1]"
              class="content__header-sort-option" >{{option.title}}</span>
      </ng-container>

    </div>

    <form *ngIf="headerOptions" class="content__header-search-form">
    <label class="content__header-search">
        <i class="content__header-search-icon icon ion-ios-search"></i>
        <input type="text" class="content__header-search-input" placeholder="Pesquisar" />
      </label>
    </form>
  </div>

</div>

<app-static-content-header (addEvent)="selectAdd()" (activeEvent)="selectActive()" (disabledEvent)="selectDisabled()" [showSort]="false"></app-static-content-header>
<ng-container *ngFor="let package of packages">
  <app-entry-item
    (deleteEvent)="delete($event)"
    [date]=""
    [id]="package.id"
    [title]="package.title"
    editRoute="packages">
    <app-publish-action (publishEvent)="togglePublish($event)" [id]="package.id" [published]="package.published"></app-publish-action>


  </app-entry-item>
</ng-container>
<div class="pagination">
  <a class="pagination__link pagination__link--previous" (click)="prevPage()">Anterior</a>
  <a class="pagination__link pagination__link--next" (click)="nextPage()">Próximo</a>
</div>

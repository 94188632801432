import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PeriodService} from '../../period.service';
import {NotifierService} from 'angular-notifier';
import {PublishEvent} from '../../base/interfaces';

export interface Period {
  id: string;
  title: string;
  months: number;
  price: number;
  package: string;
}


@Component({
  selector: 'app-period-list',
  templateUrl: './period-list.component.html',
  styleUrls: ['./period-list.component.scss']
})
export class PeriodListComponent implements OnInit {
  page: number;
  status: string = 'active';
  sort: string = 'date';

  periods: Period[];

  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private periodService: PeriodService,
    private notifier: NotifierService
  ) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {

      this.page = (params['page'] === undefined) ? 1 : +params['page'];

      if (params['status'] !== undefined) {
        this.status = params['status'];
      }

      if (params['sort'] !== undefined) {
        this.sort = params['sort'];
      }


      this.getList(this.page, this.status, this.sort);
    });
  }

  public getList(page: number, status: string, sort: string): void {
    this.periodService.list(page, status, sort).subscribe(response => {


      this.periods = response['data'];
    });
  }

  public delete(data: any) {
    const confirmDelete = confirm("Tem certeza que deseja deletar o período?");

    if (confirmDelete) {
      this.periodService.delete(data).subscribe(
        response => {
          this.notifier.notify('success', 'Período deletado com sucesso');

          this.getList(this.page, this.status, this.sort);
        },
        error => {
          this.notifier.notify('error', 'Não foi possível deletar o período');
        }
      );
    }
  }

  public togglePublish(data: PublishEvent): void {
    this.periodService.togglePublish(data.id, data.published).subscribe(
      response => {
        const message = (data.published) ? 'Período despublicado com sucesso!' : 'Período publicado com sucesso!';

        this.notifier.notify('success', message);

        this.getList(this.page, this.status, this.sort);
      }
    );
  }

}

<form (ngSubmit)="submit()" [formGroup]="bannerForm" action="" class="form">
  <div class="form__left">
    <app-button [enabled]="bannerForm.valid" text="Adicionar" type="submit"></app-button>
    <app-input [control]="bannerForm.get('title')" label="Título"></app-input>
    <app-input [control]="bannerForm.get('url')" label="URL"></app-input>
  </div>

  <div class="form__right">
    <app-image-upload
      (deleteEvent)="deleteImage()"
      (uploadEvent)="uploadImage($event)"
      [image]="image"
    ></app-image-upload>

  </div>

</form>

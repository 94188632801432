<form (ngSubmit)="submit()" [formGroup]="periodForm" action="" class="form">
  <div class="form__left">
    <app-button [enabled]="periodForm.valid" text="Adicionar" type="submit"></app-button>
    <app-input (optionEvent)="changePackage($event)"
               [control]="periodForm.get('package')"
               [options]="packageOptions"
               label="Pacote"
               type="select"></app-input>
    <app-input (optionEvent)="changePeriod($event)"
               [control]="periodForm.get('months')"
               [options]="periodOptions"
               label="Período"
               type="select"></app-input>
  </div>

  <div class="form__right">
    <app-input [control]="periodForm.get('price')" label="Preço" type="currency"></app-input>

  </div>

</form>


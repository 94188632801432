import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth.guard';
import { NewsletterCustomCreateComponent } from './newsletter-custom-create/newsletter-custom-create.component';
import { NewsletterCreateComponent } from './pages/newsletter-create/newsletter-create.component';
import { NewsletterListComponent } from './pages/newsletter-list/newsletter-list.component';

const routes: Routes = [
  {path: 'newsletter', component: NewsletterListComponent, canActivate: [AuthGuard]},
  {path: 'newsletter/add', component: NewsletterCreateComponent, canActivate: [AuthGuard]},
  {path: 'newsletter/custom', component: NewsletterCustomCreateComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewsletterRoutingModule { }

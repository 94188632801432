import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/firestore';
import { Newsletter } from '../intefaces/newsletter';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(private firestore: AngularFirestore) { }

  list(entry: any, direction: string) {
    return this.firestore.collection<Newsletter>('newsletter', ref => {
      let query: CollectionReference | Query = ref;

      query = query.orderBy('date', 'desc');

      if (entry) {
        if (direction == 'after') {
          query = query.startAfter(entry).limit(20);
        } else {
          query = query.endBefore(entry).limitToLast(20).limit(20);
        }
      } else {
        query = query.limit(20);
      }

      return query;
    }).get();
  }


  get(id: string) {
    return this.firestore.collection<Newsletter>('newsletter').doc(id).get();
  }

  save(id: string, data: any) {
    return this.firestore.collection<Newsletter>('newsletter').doc(id).set(data);
  }

}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ArticleListComponent} from './article-list/article-list.component';
import {ArticleCreateComponent} from './article-create/article-create.component';
import {ArticleEditComponent} from './article-edit/article-edit.component';
import { AuthGuard } from '../auth.guard';

const routes: Routes = [
  {path: 'articles', component: ArticleListComponent, canActivate: [AuthGuard] },
  {path: 'articles/add', component: ArticleCreateComponent, canActivate: [AuthGuard] },
  {path: 'articles/edit/:id', component: ArticleEditComponent, canActivate: [AuthGuard] },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArticlesRoutingModule {
}

<form (ngSubmit)="submit()" [formGroup]="newsletterForm" action="" class="form">
  <div class="form__left">
    <app-button [enabled]="newsletterForm.valid" text="Adicionar" type="submit"></app-button>
    <app-input [control]="newsletterForm.get('subject')" label="Assunto"></app-input>
  </div>

</form>
<div class="form">
  <div>
    <div class="form__content-title">Conteúdo</div>
      <email-editor minHeight="800px" [options]="options" (loaded)="editorLoaded()"></email-editor>
  </div>

</div>
<div class="form">
  <div class="form__left">

    <div class="form__content-title">Grupos</div>
    <div *ngFor="let item of groups" (click)="selectGroup(item)" class="item-selector" [ngClass]="{'item-selector--selected': groupIsSelected(item)}">{{item.name}}</div>
  </div>


</div>

import {Component, Input, OnInit} from '@angular/core';
import {Option} from '../interfaces';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss']
})
export class ContentHeaderComponent implements OnInit {
  @Input() status: string;
  @Input() sort: string;
  @Input() baseRoute: string;
  @Input() additionalLink: string;
  @Input() additionalLink2: string;
  @Input() sortOptions: Option[];
  @Input() headerOptions = true;

  constructor() { }

  ngOnInit(): void {
  }

}

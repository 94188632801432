import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL, ArticleFormData, WallpaperFormData } from './base/interfaces';
import { AuthService } from './auth.service';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class WallpaperService {
  url = `${API_URL}wallpaper`;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private firestore: AngularFirestore) { }

  get options(): any {
    return {
      'headers': {
        'Authorization': `Bearer${this.auth.token}`
      }
    };
  }

  public list(entry: any, status: boolean, direction: string) {
    return this.firestore.collection('wallpapers', ref => {
      let query: CollectionReference | Query = ref;
      query = query.where('published', '==', status);

      if (entry) {
        if (direction == 'after') {
          query = query.startAfter(entry);
        } else {
          query = query.endBefore(entry).limitToLast(20);
        }
      }

      query = query.limit(20);
      return query;
    }).get();
  }



  public getWallpaper(id: string) {
    return this.firestore.collection('wallpapers').doc(id).get();
  }

  public create(id: string, data: any) {
    return this.firestore.collection('wallpapers').doc(id).set(data);
  }

  public update(id: string, data: any) {
    return this.firestore.collection('wallpapers').doc(id).update(data);
  }

  public delete(id: string) {
    return this.firestore.collection('wallpapers').doc(id).delete();
  }

  public togglePublish(id: string, published: boolean) {
    const data = {
      published: !published
    }

    return this.firestore.collection('wallpapers').doc(id).update(data);
  }
}

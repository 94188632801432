<app-static-content-header (addEvent)="selectAdd()" (activeEvent)="selectActive()" (disabledEvent)="selectDisabled()" [showSort]="false"></app-static-content-header>

<ng-container *ngFor="let group of groups">
  <app-entry-item
    (deleteEvent)="delete($event)"
    date="{{group.users.length}} Membros"
    [id]="group.id"
    editRoute="groups"
    [title]="group.name">

    <app-item-action
    (click)="editUsers(group.id)"
    [checkCondition]="true"
    disabledText="Editar usuários"
    enabledText="Editar usuários" ></app-item-action>
      <app-publish-action (publishEvent)="togglePublish($event)" [id]="group.id" [published]="group.published"></app-publish-action>

  </app-entry-item>
</ng-container>

<div class="pagination">
  <a class="pagination__link pagination__link--previous" (click)="prevPage()">Anterior</a>
  <a class="pagination__link pagination__link--next" (click)="nextPage()" *ngIf="groups.length == 10">Próximo</a>
</div>


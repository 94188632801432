import { Component, OnInit } from '@angular/core';
import {Option, PeriodFormData} from '../../base/interfaces';
import {Period} from '../period-list/period-list.component';
import {Package} from '../../package/package-list/package-list.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {PeriodService} from '../../period.service';
import {PackageService} from '../../package.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-period-edit',
  templateUrl: './period-edit.component.html',
  styleUrls: ['./period-edit.component.scss']
})
export class PeriodEditComponent implements OnInit {
  period: Period;
  packages: Package[];

  periodForm = new FormGroup({
    title: new FormControl(''),
    months: new FormControl('', [
      Validators.required
    ]),
    package: new FormControl('', [
      Validators.required
    ]),
    price: new FormControl('', [
      Validators.required
    ]),
  });

  periodOptions = [
    {value: 12, title: '1 ano'},
    {value: 24, title: '2 anos'},
    {value: 36, title: '3 anos'}
  ];

  packageOptions: Option[];

  private sub: any;

  constructor(
    private notifier: NotifierService,
    private periodService: PeriodService,
    private packageService: PackageService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.packageService.list(null, true, 'date', 'after').subscribe(
        response => {
          this.packages = response['data'];

          this.packageOptions = this.packages.map(item => {
            return {value: item.id, title: item.title};
          });

          if (params.id === undefined) {
            this.notifier.notify('error', 'Pacote não encontrado');

            this.router.navigate(['/packages']);
          }

          const id = params.id;

          this.periodService.getPeriod(id).subscribe(
            response => {
              this.period = response['data'];

              this.periodForm.patchValue({
                months: this.period.months,
                package: this.period.package,
                price: this.period.price
              });
            }
          );


        });
    });
  }

  public submit(): void {
    let title = this.periodForm.value.title;

    if (title === '') {
      this.packages.forEach(entry => {
        if (entry.id === this.periodForm.value.package) {
          title = entry.title;

          return;
        }
      });
    }
    let periodData: PeriodFormData = {
      ...this.periodForm.value,
      title
    };

    this.periodService.update(this.period.id, periodData).subscribe(
      response => {
        this.notifier.notify('success', 'Período atualizado com sucesso');

        this.router.navigate(['/periods']);
      },
      error => {
        this.notifier.notify('error', 'Erro na hora de criar os dados');
      }
    );
  }

  public changePeriod(entry: Option) {
    console.log(entry);
    this.periodForm.patchValue({
      months: entry.value
    });
  }

  public changePackage(entry: Option) {
    this.periodForm.patchValue({
      package: entry.value,
      title: entry.title
    });

  }

}

import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() filePdf: string;

  @Output() deleteEvent = new EventEmitter<boolean>();
  @Output() uploadEvent = new EventEmitter<File>();

  constructor() { }

  ngOnInit(): void {

  }

  public handleFileUpload(files: FileList) {
    console.log(this.filePdf);
    const file = files.item(0);

    this.uploadEvent.emit(file);
  }

  public deleteFile() {
    this.deleteEvent.emit();
  }
}

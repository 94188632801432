import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from '../auth.guard';
import {AnuarioListComponent} from './components/anuario-list/anuario-list.component';
import {AnuarioCreateComponent} from './components/anuario-create/anuario-create.component';
import { AnuarioEditComponent } from './components/anuario-edit/anuario-edit.component';

const routes: Routes = [
  {path: 'anuario/tractors', component: AnuarioListComponent, canActivate: [AuthGuard] },
  {path: 'anuario/tractors/add', component: AnuarioCreateComponent, canActivate: [AuthGuard] },
  {path: 'tractors/edit/:id', component: AnuarioEditComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnuarioRoutingModule { }

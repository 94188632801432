<app-content-header [sort]="sort" [status]="status" baseRoute="ebooks"></app-content-header>
<ng-container *ngFor="let ebook of ebooks">
  <app-entry-item
    (deleteEvent)="delete($event)"
    [date]=""
    [id]="ebook.id"
    [title]="ebook.title"
    editRoute="ebooks">
    <app-publish-action (publishEvent)="togglePublish($event)" [id]="ebook.id" [published]="ebook.published"></app-publish-action>


  </app-entry-item>
</ng-container>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubscriptionRoutingModule } from './subscription-routing.module';
import { SubscriptionListComponent } from './subscription-list/subscription-list.component';
import { BaseModule } from '../base/base.module';
import { SubscriptionCreateComponent } from './subscription-create/subscription-create.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SubscriptionEditComponent } from './subscription-edit/subscription-edit.component';


@NgModule({
  declarations: [SubscriptionListComponent, SubscriptionCreateComponent, SubscriptionEditComponent],
  imports: [
    CommonModule,
    SubscriptionRoutingModule,
    BaseModule,
    ReactiveFormsModule
  ]
})
export class SubscriptionModule { }

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NewsModule } from './news/news.module';
import { HttpClientModule } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';

import { NotifierModule } from 'angular-notifier';
import { ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { ArticlesModule } from './articles/articles.module';
import { CalendarModule } from './calendar/calendar.module';
import { MagazineModule } from './magazine/magazine.module';
import { PackageModule } from './package/package.module';
import { PeriodModule } from './period/period.module';
import { NgxCurrencyModule } from 'ngx-currency';
import { EditionModule } from './edition/edition.module';
import { EditionArticleModule } from './edition-article/edition-article.module';
import { EbookModule } from './ebook/ebook.module';
import { WallpaperModule } from './wallpaper/wallpaper.module';
import { BannerModule } from './banner/banner.module';
import { UserModule } from './user/user.module';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GroupModule } from './group/group.module';

import { NgxUiLoaderModule } from "ngx-ui-loader";
import { NewsletterModule } from './newsletter/newsletter.module';
import { SubscriptionModule } from './subscription/subscription.module';
import {AnuarioModule} from './anuario/anuario.module';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    BrowserModule,
    NotifierModule.withConfig(
      {
        position: {
          horizontal: {
            position: 'right'
          },
          vertical: {
            position: 'top'
          }
        },
        behaviour: {
          autoHide: 5000
        },
        theme: 'material'
      }
    ),
    NewsModule,
    ArticlesModule,
    MagazineModule,
    CalendarModule,
    PackageModule,
    PeriodModule,
    EditionModule,
    EditionArticleModule,
    EbookModule,
    BannerModule,
    WallpaperModule,
    UserModule,
    GroupModule,
    SubscriptionModule,
    NewsletterModule,
    AnuarioModule,
    AppRoutingModule,
    HttpClientModule,
    NgxCurrencyModule,
    ReactiveFormsModule,
    TagInputModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule,
  ],
  providers: [
    { provide: BUCKET, useValue: 'images-cultivar' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }



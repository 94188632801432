import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PeriodRoutingModule } from './period-routing.module';
import { PeriodListComponent } from './period-list/period-list.component';
import {BaseModule} from '../base/base.module';
import { PeriodCreateComponent } from './period-create/period-create.component';
import {ReactiveFormsModule} from '@angular/forms';
import { PeriodEditComponent } from './period-edit/period-edit.component';


@NgModule({
  declarations: [PeriodListComponent, PeriodCreateComponent, PeriodEditComponent],
  imports: [
    CommonModule,
    PeriodRoutingModule,
    BaseModule,
    ReactiveFormsModule
  ]
})
export class PeriodModule { }

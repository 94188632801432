import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {UserLoginComponent} from './user-login/user-login.component';
import {UserListComponent} from './user-list/user-list.component';
import {UserCreateComponent} from './user-create/user-create.component';
import {UserEditComponent} from './user-edit/user-edit.component';

const routes: Routes = [
  {path: 'login', component: UserLoginComponent},
  {path: 'users', component: UserListComponent},

  {path: 'users/add', component: UserCreateComponent},
  {path: 'users/edit/:id', component: UserEditComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }

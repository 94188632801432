<app-static-content-header (addEvent)="selectAdd()" [showActive]="false" (searchEvent)="search($event)" [showSort]="false"></app-static-content-header>

<ng-container *ngFor="let subscription of subscriptions">
  <app-entry-item
    (deleteEvent)="delete($event)"
    [date]="subscription.date | date:'dd/MM/YYYY'"
    [id]="subscription.id"
    editRoute="subscriptions"
    title="{{(subscription.email) ? subscription.email : subscription.user + ' ' + subscription.transaction  }}">

    <a (click)="duplicate(subscription.id)" class="list-item__action list-item__action--secondary">Recriar pedido</a>
    <p class="list-item__action list-item__action--secondary"><strong>Status:</strong> {{statuses[subscription.status]}}</p>
  </app-entry-item>
</ng-container>

<div class="pagination">
  <a class="pagination__link pagination__link--previous" (click)="prevPage()">Anterior</a>
  <a class="pagination__link pagination__link--next" (click)="nextPage()">Próximo</a>
</div>

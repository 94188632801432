import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-static-pagination',
  templateUrl: './static-pagination.component.html',
  styleUrls: ['./static-pagination.component.scss']
})
export class StaticPaginationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

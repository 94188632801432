import { Component, OnInit, ViewChild } from '@angular/core';
import { EditorjsComponent } from '../../base/editorjs/editorjs.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { UploadService } from '../../upload.service';
import { EditionService } from '../../edition.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EditionArticleService } from '../../edition-article.service';
import { EditionArticleFormData, EditionFormData } from '../../base/interfaces';
import { EditionArticle } from '../../shared/intefaces/edition-article';
import { finalize } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import slugify from 'slugify';

@Component({
  selector: 'app-edition-article-create',
  templateUrl: './edition-article-create.component.html',
  styleUrls: ['./edition-article-create.component.scss'],
})
export class EditionArticleCreateComponent implements OnInit {
  editionArticle: EditionArticle;
  edition = '';
  image = '';

  @ViewChild('preview') previewEditor: EditorjsComponent;
  @ViewChild('content') contentEditor: EditorjsComponent;

  editionArticleForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    slug: new FormControl('', [Validators.required]),
    author: new FormControl(''),
    index: new FormControl(''),
    date: new FormControl('', [Validators.required]),
  });

  constructor(
    private notifier: NotifierService,
    private uploadService: UploadService,
    private editionArticleService: EditionArticleService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.edition = params.edition;
    });

    this.editionArticleForm.get('title').valueChanges.subscribe((value) => {
      this.editionArticleForm.get('slug').setValue(
        slugify(value, {
          lower: true,
          strict: true,
        })
      );
    });
  }

  public deleteImage(): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    this.image = '';
  }

  public uploadImage(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService
      .uploadFirebase(file, filePath)
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.image = filePath;

          this.notifier.notify('success', 'Imagem enviada com sucesso');
        })
      )
      .subscribe();
  }

  public submit() {
    this.contentEditor.save().then((contentData) => {
      this.previewEditor.save().then((previewData) => {
        const id = uuidv4();

        const editionArticleData: any = {
          ...this.editionArticleForm.value,
          id,
          index: Number(this.editionArticleForm.value.index),
          date: moment(this.editionArticleForm.value.date).toDate(),
          image: this.image,
          preview: JSON.stringify(previewData),
          description: JSON.stringify(contentData),
          published: true,
          edition: this.edition,
        };

        this.editionArticleService
          .create(id, editionArticleData)
          .then(() => {
            this.notifier.notify('success', 'Matéria criada com sucesso');

            this.router.navigate(['/edition_articles', this.edition]);
          })
          .catch(() => {
            this.notifier.notify('error', 'Erro na hora de criar os dados');
          });
      });
    });
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AnuarioRoutingModule} from './anuario-routing.module';
import {AnuarioListComponent} from './components/anuario-list/anuario-list.component';
import {AnuarioCreateComponent} from './components/anuario-create/anuario-create.component';
import {AnuarioEditComponent} from './components/anuario-edit/anuario-edit.component';
import {BaseModule} from '../base/base.module';
import {ReactiveFormsModule} from '@angular/forms';


@NgModule({
  declarations: [AnuarioListComponent, AnuarioCreateComponent, AnuarioEditComponent],
  imports: [
    CommonModule,
    AnuarioRoutingModule,
    BaseModule,
    ReactiveFormsModule
  ]
})
export class AnuarioModule {
}

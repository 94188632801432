import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {Tractor} from '../intefaces/tractor';
import firebase from 'firebase';
import CollectionReference = firebase.firestore.CollectionReference;
import Query = firebase.firestore.Query;

@Injectable({
  providedIn: 'root'
})
export class AnuarioService {

  constructor(private firestore: AngularFirestore) { }

  public list(entry: any, status: boolean, direction: string) {
    return this.firestore.collection<Tractor>('tractors', ref => {
      let query: CollectionReference | Query = ref;

      query = query.where('published', '==', status)
        .orderBy('date', 'desc')
        .limit(10);

      if (entry) {
        if (direction === 'after') {
          query = query.startAfter(entry);
        } else {
          query = query.endBefore(entry).limitToLast(10);
        }
      }

      return query;
    }).get();
  }

  public search(term, status) {
    return this.firestore.collection<Tractor>('tractors', ref => {
      let query: CollectionReference | Query = ref;

      query = query.where('published', '==', status)
        .where('model', '==', term)
        .orderBy('date', 'desc')
        .limit(50);


      return query;
    }).get();
  }

  getById(id: string) {
    return this.firestore.collection('tractors').doc(id).get();
  }

  delete(id: string) {
    return this.firestore.collection('tractors').doc(id).delete();
  }

  create(id: string, data: any) {
    return this.firestore.collection('tractors').doc(id).set(data);
  }

  update(id: string, data: any) {
    return this.firestore.collection('tractors').doc(id).update(data);
  }

  public togglePublish(id: string, published: boolean) {
    let data = {
      published: !published
    };

    return this.firestore.collection('tractors').doc(id).update(data);
  }

}

<label for="" class="form__label">
  <span class="form__title">{{label}}</span>
  <div class="form__input-wrapper">
    <tag-input *ngIf="!autocomplete" [formControl]="control" [modelAsStrings]="true" [placeholder]="addText" [secondaryPlaceholder]="placeholder" theme="minimal"></tag-input>

    <tag-input *ngIf="autocomplete" [formControl]="control" [modelAsStrings]="true" [onlyFromAutocomplete]="true" [placeholder]="addText" [secondaryPlaceholder]="placeholder" secondaryPlaceholder="Insira uma Tag" theme="minimal">
      <tag-input-dropdown [autocompleteItems]="items"
        [focusFirstElement]="true"
        [showDropdownIfEmpty]="true">
      </tag-input-dropdown>
    </tag-input>
  </div>

</label>

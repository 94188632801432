import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArticlesRoutingModule } from './articles-routing.module';
import { ArticleCreateComponent } from './article-create/article-create.component';
import { ArticleListComponent } from './article-list/article-list.component';
import {BaseModule} from '../base/base.module';
import {ReactiveFormsModule} from '@angular/forms';
import { ArticleEditComponent } from './article-edit/article-edit.component';


@NgModule({
  declarations: [ArticleCreateComponent, ArticleListComponent, ArticleEditComponent],
    imports: [
        CommonModule,
        ArticlesRoutingModule,
        BaseModule,
        ReactiveFormsModule
    ]
})
export class ArticlesModule { }

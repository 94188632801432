import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL, ArticleFormData } from './base/interfaces';
import { AuthService } from './auth.service';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/firestore';
import { Article } from './shared/intefaces/article';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  url = `${API_URL}article`;

  constructor(private http: HttpClient, private auth: AuthService, private firestore: AngularFirestore) { }

  get options(): any {
    return {
      'headers': {
        'Authorization': `Bearer${this.auth.token}`
      }
    };
  }

  public list(entry: any, status: boolean, sort: string, direction: string) {
    return this.firestore.collection<Article>('articles', ref => {
      let query: CollectionReference | Query = ref;

      query = query.where('published', '==', status)
        .orderBy(sort, 'desc');

      if (entry) {
        if (direction === 'before') {
          query = query.endBefore(entry).limitToLast(20);
        } else {
          query = query.startAfter(entry).limit(20);
        }
      } else {
        query = query.limit(20);
      }

      return query;

    }).get();
  }

  public getBySlug(slug: string) {
    return this.firestore.collection<Article>('articles', ref => {
      let query: CollectionReference | Query = ref;

      query = query.where('slug', '==', slug).limit(1);

      return query;
    }).get();
  }

  public getNewsletter() {
    return this.firestore.collection<Article>('articles', ref => {
      return ref.where('newsletter', '==', true)
        .where('published', '==', true)
        .orderBy('date', 'desc');
    }).get();
  }

  public getById(id: string) {
    return this.firestore.collection('articles').doc(id).get();
  }

  public getArticle(id: string) {
    return this.http.get(`${this.url}/get/${id}`, this.options);
  }

  public create(id: string, data: any) {
    return this.firestore.collection('articles').doc(id).set(data);
  }

  public update(id: string, data: any) {
    return this.firestore.collection('articles').doc(id).update(data);
  }

  public delete(id: string) {
    return this.firestore.collection('articles').doc(id).delete();
  }

  public togglePublish(id: string, published: boolean) {
    let data = {
      published: !published
    };

    return this.firestore.collection('articles').doc(id).update(data);
  }

  public toggleHighlight(id: string, currentStatus: boolean) {
    let data = {
      highlight: !currentStatus
    };

    return this.firestore.collection('articles').doc(id).update(data);
  }

  public toggleNewsletter(id: string, newsletter: boolean) {
    let data = {
      newsletter: !newsletter
    };

    return this.firestore.collection('articles').doc(id).update(data);
  }
}

import { Component, OnInit } from '@angular/core';
import { Option, PublishEvent } from '../../base/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { BannerService } from '../../banner.service';
import { NotifierService } from 'angular-notifier';
import { DatePipe } from '@angular/common';
import { Banner } from 'src/app/shared/intefaces/banner';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.scss']
})
export class BannerListComponent implements OnInit {
  entry: string;
  position: string = 'rotation';
  status: string = 'active';

  banners: Banner[];

  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private bannerService: BannerService,
    private notifier: NotifierService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      if (params['position'] !== undefined) {
        this.position = params['position'];
      } else {
        this.notifier.notify('error', 'Não foi possível carregar a lista de banners');
        this.router.navigateByUrl('/banners');
      }


      this.getList(null, this.position, this.status, 'after');
    });
  }

  public getList(entry: string, position: string, status: string, direction: string): void {
    if (entry) {
      this.bannerService.getById(entry).pipe(take(1)).subscribe(
        response => {

          this.bannerService.list(response, position, (status === 'active'), direction).subscribe(
            response => {
              this.banners = response.docs.map(doc => doc.data());
            },
            error => {
              console.log(error);
              this.notifier.notify('error', 'Não foi possível carregar a lista de banners');
            }
          );

        }
      );
    } else {
      this.bannerService.list(null, position, (status === 'active'), direction).subscribe(
        response => {
          this.banners = response.docs.map(doc => doc.data());
        },
        error => {
          console.log(error);
          this.notifier.notify('error', 'Não foi possível carregar a lista de banners');
        }
      );
    }
  }

  public delete(data: any) {
    const confirmDelete = confirm("Tem certeza que deseja excluir o banner?");

    if (confirmDelete) {
      this.bannerService.delete(data.toString()).then(
        response => {
          this.notifier.notify('success', 'Banner excluído com sucesso!');
          this.getList(null, this.position, this.status, 'after');
        }
      ).catch(
        error => {
          this.notifier.notify('error', 'Não foi possível excluir o banner!');
        }
      );
    }
  }

  public togglePublish(data: PublishEvent): void {
    this.bannerService.togglePublish(data.id.toString(), data.published).then(
      response => {
        const message = (data.published) ? 'Banner despublicado com sucesso!' : 'Banner publicado com sucesso!';

        this.notifier.notify('success', message);

        this.getList(null, this.position, this.status, 'after');
      }
    );
  }

  public selectActive() {
    this.status = 'active';
    this.getList(null, this.position, this.status, 'after');
  }

  public selectDisabled() {
    this.status = 'disabled';
    this.getList(null, this.position, this.status, 'after');
  }

  public selectAdd() {
    this.router.navigate(['/banners/add', this.position]);
  }

  public nextPage() {
    this.getList(this.banners[this.banners.length - 1].id.toString(), this.position, this.status, 'after');
    window.scrollTo(0, 0);
  }

  public prevPage() {
    this.getList(this.banners[0].id.toString(), this.position, this.status, 'before');
    window.scrollTo(0, 0);
  }


}

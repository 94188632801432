import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {API_URL} from './base/interfaces';
import { AngularFireStorage } from '@angular/fire/storage';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  url = `${API_URL}upload`;

  constructor(private http: HttpClient, private storage: AngularFireStorage) { }

  get options(): any {
    return {
      'headers': {
        // 'Authorization': `Bearer${this.auth.token}`
      }
    };
  }

  public uploadImage(file: File) {
    console.log(file);
     const formData: FormData = new FormData();

    return this.http.post(`${this.url}/image/file`, formData, this.options);
  }

  public uploadFirebase(file: File, filePath: string) {
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    return task;
  }

  public uploadFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(`${this.url}/file`, formData, this.options);
  }
}

import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import { PublishEvent } from 'src/app/base/interfaces';
import { GroupService } from 'src/app/services/group.service';
import { Group } from 'src/app/shared/intefaces/group';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements OnInit {
  entry: string;
  direction: string = 'after';
  status: boolean = true;
  groups: Group[];

  constructor(private groupService: GroupService,
    private afAuth: AngularFireAuth,
    private notifier: NotifierService,
    private router: Router) { }

  ngOnInit(): void {
    this.afAuth.authState.pipe(take(2)).subscribe( user => {
      if (user) {
        this.getList(null, this.status, 'after');
      }
    });
  }

  private getList(entry: string, status: boolean, direction: string): void {
    if (entry) {
      this.groupService.getGroup(entry).pipe(take(1)).subscribe(
        response => {
          this.groupService.getGroups(response, status, direction).pipe(take(1)).subscribe(
            reponse => {
              this.groups = reponse.docs.map(doc => doc.data());
            },
            error => {
              this.notifier.notify('error',  'Não foi possível carregar os grupos');
            }
          );
        },
        error => {
          this.notifier.notify('error',  'Não foi possível carregar os grupos');
        }
      );
    } else {
      this.groupService.getGroups(null, status, direction).pipe(take(1)).subscribe(
        response => {
          this.groups = response.docs.map(doc => doc.data());
        },
        error => {
          this.notifier.notify('error',  'Não foi possível carregar os grupos');
          console.log(error);
        }
      );
    }
  }

  public nextPage() {
    this.entry = this.groups[this.groups.length - 1].id;
    this.direction = 'after';

    this.getList(this.groups[this.groups.length - 1].id, this.status, 'after');
    window.scrollTo(0, 0);
  }

  public prevPage() {
    this.entry = this.groups[0].id;
    this.direction = 'before';

    this.getList(this.groups[0].id, this.status, 'before');
    window.scrollTo(0, 0);
  }

  public selectAdd() {
    this.router.navigateByUrl('/groups/add');
  }

  public selectActive() {
    this.status = true;
    this.getList(null, this.status, 'after');
  }

  public selectDisabled() {
    this.status = false;
    this.getList(null, this.status, 'after');
  }

  public togglePublish(data: PublishEvent): void {
    this.groupService.togglePublish(data.id.toString(), data.published).then(response => {
      const message = (data.published) ? 'Grupo despublicado com sucesso!' : 'Grupo publicado com sucesso!';

      this.notifier.notify('success', message);

      this.getList(this.entry, this.status, this.direction);
    }).catch(error => {
      this.notifier.notify('error', 'Não foi possível alterar o status do artigo.');
    });
  }

  public delete(data: any) {
    const confirmDelete = confirm("Tem certeza que deseja excluir o grupo?");

    if (confirmDelete) {
      this.groupService.delete(data.toString()).then(response => {
        this.notifier.notify('success', 'Grupo excluído com sucesso!');
        this.getList(this.entry, this.status, this.direction);
      }).catch(error => {
        this.notifier.notify('error', 'Não foi possível excluir o grupo.');
      });
    }
  }

  public editUsers(groupId: string) {
    this.router.navigateByUrl('/groups/' + groupId + '/users');
  }

}

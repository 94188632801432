import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalendarRoutingModule } from './calendar-routing.module';
import { CalendarListComponent } from './calendar-list/calendar-list.component';
import {BaseModule} from '../base/base.module';
import { CalendarCreateComponent } from './calendar-create/calendar-create.component';
import {ReactiveFormsModule} from '@angular/forms';
import { CalendarEditComponent } from './calendar-edit/calendar-edit.component';


@NgModule({
  declarations: [CalendarListComponent, CalendarCreateComponent, CalendarEditComponent],
  imports: [
    CommonModule,
    CalendarRoutingModule,
    BaseModule,
    ReactiveFormsModule,
  ]
})
export class CalendarModule { }

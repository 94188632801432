import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PeriodListComponent} from './period-list/period-list.component';
import {PeriodCreateComponent} from './period-create/period-create.component';
import {PeriodEditComponent} from './period-edit/period-edit.component';

const routes: Routes = [
  {path: 'periods', component: PeriodListComponent},
  {path: 'periods/add', component: PeriodCreateComponent},
  {path: 'periods/edit/:id', component: PeriodEditComponent},

  {path: 'periods/:page', component: PeriodListComponent},
  {path: 'periods/:status/:page', component: PeriodListComponent},
  {path: 'periods/:status/:sort/:page', component: PeriodListComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PeriodRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ArticleService } from '../../article.service';
import { NotifierService } from 'angular-notifier';
import { PublishEvent } from '../../base/interfaces';
import { Article } from 'src/app/shared/intefaces/article';
import { take } from 'rxjs/operators';

import algoliasearch from "algoliasearch";
import { ThrowStmt } from '@angular/compiler';

const APP_ID = "YK4T9FI4H5";
const ADMIN_KEY = "7825c838f024805a18dde0793169e861";

const client = algoliasearch(APP_ID, ADMIN_KEY);
const index = client.initIndex("articles");

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss']
})
export class ArticleListComponent implements OnInit {
  entry: string;
  status: string = 'active';
  sort: string = 'date';
  direction: string = 'after';
  searchTerm: string = "";

  articles: Article[];

  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private articleService: ArticleService,
    private notifier: NotifierService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.getList(this.entry, this.status, this.sort, this.direction);
  }

  public getList(entry: string, status: string, sort: string, direction: string): void {
    if (entry != null) {
      this.articleService.getById(entry).pipe(take(1)).subscribe(
        response => {
          this.articleService.list(response, (status === 'active'), sort, direction).subscribe(
            response => {
              this.articles = response.docs.map(item => {
                const data = item.data();
                return {
                  ...data,
                  date: new Date(data.date.toMillis())
                }
              });
            },
            error => {
              this.notifier.notify('error', 'Ocorreu um erro ao carregar a lista de artigos');
            }
          );

        }
      );
    } else {
      this.articleService.list(null, (status === 'active'), sort, direction).subscribe(
        response => {
          this.articles = response.docs.map(item => {
            const data = item.data();
            return {
              ...data,
              date: new Date(data.date.toMillis())
            }
          });
        },
        error => {
          this.notifier.notify('error', 'Ocorreu um erro ao carregar a lista de artigos');
        }
      );
    }

  }

  public delete(data: any) {
    const confirmDelete = confirm("Tem certeza que deseja excluir o artigo?");

    if (confirmDelete) {
      this.articleService.delete(data.toString()).then(response => {
        this.notifier.notify('success', 'Artigo excluído com sucesso!');
        this.getList(null, this.status, this.sort, this.direction);
      }).catch(error => {
        this.notifier.notify('error', 'Não foi possível excluir o artigo.');
      });
    }
  }

  public nextPage() {
    this.getList(this.articles[this.articles.length - 1].id.toString(), this.status, this.sort, 'after');
    window.scrollTo(0, 0);
  }

  public prevPage() {
    this.getList(this.articles[0].id.toString(), this.status, this.sort, 'before');
    window.scrollTo(0, 0);
  }

  public selectActive() {
    this.status = 'active';

    if (this.searchTerm && this.searchTerm != "") {
      return this.search(this.searchTerm);
    }

    this.getList(null, this.status, this.sort, this.direction);
  }

  public selectDisabled() {
    this.status = 'disabled';

    if (this.searchTerm && this.searchTerm != "") {
      return this.search(this.searchTerm);
    }

    this.getList(null, this.status, this.sort, this.direction);
  }

  public selectSort(sort: string) {
    this.sort = sort;
    this.getList(null, this.status, this.sort, this.direction);
  }

  public selectAdd() {
    this.router.navigate(['/articles/add']);
  }

  public togglePublish(data: PublishEvent): void {
    this.articleService.togglePublish(data.id.toString(), data.published).then(response => {
      const message = (data.published) ? 'Artigo despublicado com sucesso!' : 'Artigo publicado com sucesso!';

      this.notifier.notify('success', message);

      this.getList(null, this.status, this.sort, this.direction);
    }).catch(error => {
      this.notifier.notify('error', 'Não foi possível alterar o status do artigo.');
    });
  }

  public toggleHighlight(article: Article) {
    this.articleService.toggleHighlight(article.id.toString(), article.highlight).then(response => {
      const message = (!article.highlight) ? 'Artigo destacado com sucesso!' : 'Artigo removido da destaque com sucesso!';

      this.notifier.notify('success', message);

      this.articles = this.articles.map(item => {
        if (item.id === article.id) {
          item.highlight = !article.highlight;
        }

        return item;
      });
    }).catch(error => {
      this.notifier.notify('error', 'Não foi possível alterar o status do artigo.');
    });

  }

  public toggleNewsletter(data: Article) {
    this.articleService.toggleNewsletter(data.id.toString(), data.newsletter).then(response => {
      this.articles = this.articles.map(newsItem => {
        if (newsItem.id === data.id) {
          newsItem.newsletter = !data.newsletter;
        }

        return newsItem;
      });
    });
  }

  public search(term) {
    this.searchTerm = term;

    if (this.searchTerm == "") {
      this.getList(null, this.status, this.sort, this.direction);
    }


    index.search(term, { filters: `published:${(this.status == 'active')}` }).then(({ hits }) => {
      this.articles = hits.map(entry => {
        const data = entry as any;

        return {
          title: data.title,
          published: data.published,
          highlight: data.highlight_home,
          id: data.objectID,
          date: new Date(data.date)
        } as any;
      })
    });;
  }

}

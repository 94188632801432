import {Component, OnInit, ViewChild} from '@angular/core';
import {EditorjsComponent} from '../../base/editorjs/editorjs.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {UploadService} from '../../upload.service';
import {EditionService} from '../../edition.service';
import {EditionFormData, MagazineFormData} from '../../base/interfaces';
import {ActivatedRoute, Router} from '@angular/router';
import {Edition} from '../../shared/intefaces/edition';
import {v4 as uuidv4} from 'uuid';
import {error} from 'protractor';
import {finalize} from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-edition-create',
  templateUrl: './edition-create.component.html',
  styleUrls: ['./edition-create.component.scss']
})
export class EditionCreateComponent implements OnInit {
  edition: Edition;
  magazine = '';
  image = '';
  file = '';

  @ViewChild(EditorjsComponent) editor: EditorjsComponent;

  editionForm = new FormGroup({
    title: new FormControl('', [
      Validators.required
    ]),
    number: new FormControl('', [
      Validators.required
    ]),
    month: new FormControl('', [
      Validators.required
    ]),
    year: new FormControl(''),
    issuu: new FormControl(''),
    restricted: new FormControl(''),
  });


  constructor(
    private notifier: NotifierService,
    private uploadService: UploadService,
    private editionService: EditionService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.editionForm.patchValue({
      restricted: false
    });

    this.route.params.subscribe(params => {
      this.magazine = params.magazine;
    });
  }

  public deleteImage(): void {
    this.notifier.notify('success', 'Imagem removida com sucesso.');

    this.image = '';
  }

  public uploadImage(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService.uploadFirebase(file, filePath).snapshotChanges().pipe(
      finalize(() => {
        this.image = filePath;

        this.notifier.notify('success', 'Imagem enviada com sucesso');
      })
    ).subscribe();


  }

  public deleteFile(): void {
    this.notifier.notify('success', 'Arquivo removido com sucesso.');

    this.file = '';
  }

  public uploadFile(file: File): void {
    const extension = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${extension}`;

    this.uploadService.uploadFirebase(file, filePath).snapshotChanges().pipe(
      finalize(() => {
        this.file = filePath;

        this.notifier.notify('success', 'Arquivo enviado com sucesso');
      })
    ).subscribe();
  }

  public submit() {
    this.editor.save().then(data => {
      const id = uuidv4();

      const editionData: any = {
        ...this.editionForm.value,
        id,
        month: moment(this.editionForm.value.month).toDate(),
        number: Number(this.editionForm.value.number),
        image: this.image,
        pdf: this.file,
        description: JSON.stringify(data),
        magazine: this.magazine,
        published: true
      };

      this.editionService.create(id, editionData).then(
        () => {
          this.notifier.notify('success', 'Edição salva com sucesso.');

          this.router.navigateByUrl('/editions');
        },
        error => {
          this.notifier.notify('error', 'Erro ao salvar uma edição.');
        }
      );
    });
  }

}
